/* eslint-disable */
import * as React from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { filter } from 'lodash';
import { Icon } from '@iconify/react';
import { useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../actions/quotation.action';
import * as PlantAction from '../actions/plant.action';
import * as PantryAction from '../actions/pantry.action';
import {
  Box,
  Table,
  Grid,
  Card,
  Paper,
  Button,
  Checkbox,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Toolbar,
  TextField,
  MenuItem,
  Stack
} from '@mui/material';
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import { UserListHead, UserMoreMenu } from '../components/_dashboard/user';
// import QuotationProductList from '../_mocks_/quotationproduct';
import { QuotationProductListHead } from '../components/_dashboard/quotationproduct';

import Swal from 'sweetalert2';
import { isArray, isObject } from 'lodash';
import { server } from '../constants';
import { useQuotation } from '../hooks/useQuotation';
import { fCurrency } from '../utils/formatNumber';
import { Label } from '@mui/icons-material';
import { textAlign } from '@mui/system';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'product_name', label: 'รายการสินค้า', alignRight: false },
  { id: 'product_unit', label: 'หน่วย', alignRight: false },
  { id: 'product_price', label: 'ราคา/หน่วย', alignRight: false },
  { id: 'qty', label: 'จำนวน', alignRight: false },
  { id: 'product_total', label: 'รวม', alignRight: false }
  // { id: '' }
];
const TABLE_HEAD_NO_PRICE = [
  { id: 'product_name', label: 'รายการสินค้า', alignRight: false },
  { id: 'product_unit', label: 'หน่วย', alignRight: false },
  { id: 'qty', label: 'จำนวน', alignRight: false }
  // { id: '' }
];

// ----------------------------------------------------------------------

// function descendingComparator(a, b, orderBy) {
//   if (b[orderBy] < a[orderBy]) {
//     return -1;
//   }
//   if (b[orderBy] > a[orderBy]) {
//     return 1;
//   }
//   return 0;
// }

// function getComparator(order, orderBy) {
//   return order === 'desc'
//     ? (a, b) => descendingComparator(a, b, orderBy)
//     : (a, b) => -descendingComparator(a, b, orderBy);
// }

// function applySortFilter(array, comparator, query) {
//   const stabilizedThis = dataFilter.map((el, index) => [el, index]);
//   stabilizedThis.sort((a, b) => {
//     const order = comparator(a[0], b[0]);
//     if (order !== 0) return order;
//     return a[1] - b[1];
//   });
//   if (query) {
//     dataFilter = filter(dataFilter, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
//   }
//   return stabilizedThis.map((el) => el[0]);
// }

export function QuotationProduct(props) {
  const navigate = useNavigate();
  const pageDatas = useQuotation();
  const [plantList, setPlantList] = useState(null);
  const [pantryList, setPantryList] = useState(null);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(
    parseInt(localStorage.getItem(server.ROWS_PER_PAGE))
  );

  const [totalAmt, setTotalAmt] = useState(0);
  const showPlant = localStorage.getItem(server.LOGIN_PLANT);
  const showPantry = localStorage.getItem(server.LOGIN_PANTRY);

  useEffect(() => {
    props.getPlants();
  }, []);

  useEffect(() => {
    props.getPantryByPlantNo(pageDatas.plantNo);
  }, [pageDatas.plantNo]);

  useEffect(() => {
    props.getPantrys();
  }, []);

  useEffect(() => {
    const { result } = props.plantReducer;
    // console.log(result);
    if (result) setPlantList(result);
  }, [props.plantReducer]);

  useEffect(() => {
    const { result } = props.pantryReducer;
    // console.log(result);
    if (result) setPantryList(result);
  }, [props.pantryReducer]);

  const { id } = useParams();

  useEffect(() => {
    if (id && pageDatas.isState == 'E' && pageDatas.isPerState == '') {
      const { result } = props.quotationReducer;
      if (result) {
        pageDatas.setPlantNo(result.plant_no);
        pageDatas.setPantryNo(result.pantry_no);
        pageDatas.setQuotationNumber(result.quotation_number);
        pageDatas.setQuotationDate(result.quotation_date);
        pageDatas.setQuotationPurchase(result.quotation_purchase);
        pageDatas.setStatus(result.status);
        pageDatas.setQuotationPrice(result.quotation_price);
      }
    }
  }, [props.quotationReducer]);

  useEffect(() => {
    if (id && pageDatas.isState == 'E' && pageDatas.isPerState == '') {
      // console.log('E');
      props.getQuotationById(id);
    } else if (pageDatas.isState == 'A' && pageDatas.isPerState == '') {
      // console.log('A');
      pageDatas.emptyData();
    }
  }, [pageDatas.isState]);

  useEffect(() => {
    if (id && pageDatas.isState == 'E' && pageDatas.isPerState == '') {
      console.log('E');
      props.getQuotationDTById(id);
    }
  }, [pageDatas.isState]);

  useEffect(() => {
    if (id && pageDatas.isState == 'E' && pageDatas.isPerState == '') {
      const { result } = props.quotationdtReducer;

      if (result && isArray(result)) {
        pageDatas.setQuotationDT(result);
      }
    }
  }, [props.quotationdtReducer]);

  pageDatas.setIsPerState('');

  const handleChangePlantNo = (event) => {
    pageDatas.setPlantNo(event.target.value);
    pageDatas.setPantryNo('');
  };

  const handleChangePantryNo = (event) => {
    pageDatas.setPantryNo(event.target.value);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    localStorage.setItem(server.ROWS_PER_PAGE, event.target.value);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - pageDatas.quotationDT.length) : 0;

  return (
    <Page title="Quotation | JConnect">
      <Container maxWidth="false">
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            เพิ่มใบเสนอราคา
          </Typography>
          <Button
            variant="contained"
            // component={RouterLink}
            // to="/dashboard/orderpopup"
            startIcon={<Icon icon={plusFill} />}
            onClick={() => {
              if (pageDatas.plantNo != '' && pageDatas.pantryNo != '') {
                pageDatas.setIsPerState(id);
                // pageDatas.setIsState('P');
                navigate('/dashboard/quotationpopup', { replace: true });
              }
            }}
          >
            เพิ่มและแก้ไขสินค้า
          </Button>
        </Stack>

        <Grid container spacing={2} sx={{ mt: 3 }}>
          <Grid item lg={4} md={6} xs={12} container spacing={2}>
            <Grid item lg={12} md={12} xs={12}>
              <Box
                sx={{
                  borderRadius: 2,
                  border: 1,
                  borderColor: 'text.primary',
                  p: 3
                }}
              >
                {(pageDatas.plantNo || pageDatas.isState == 'A') && (
                  <TextField
                    id="outlined-select-currency"
                    label="พื้นที่"
                    select
                    sx={{ width: '100%', mt: 2, mb: 2 }}
                    value={pageDatas.plantNo}
                    onChange={handleChangePlantNo}
                    error={pageDatas.plantNo == ''}
                    helperText={pageDatas.plantNo == '' ? 'กรุณาเลือก Plant' : ''}
                  >
                    {plantList &&
                      plantList.map((option) => {
                        if (showPlant.indexOf(option.plant_no) >= 0 || showPlant.length === 0) {
                          return (
                            <MenuItem key={option.id} value={option.plant_no}>
                              {option.plant_no}
                            </MenuItem>
                          );
                        }
                      })}
                  </TextField>
                )}
                {(pageDatas.pantryNo || pageDatas.isState == 'A') && (
                  <TextField
                    id="outlined-select-currency"
                    label="ตึก"
                    select
                    value={pageDatas.pantryNo}
                    onChange={handleChangePantryNo}
                    sx={{ width: '100%', mt: 2, mb: 2 }}
                    error={pageDatas.pantryNo == ''}
                    helperText={pageDatas.pantryNo == '' ? 'กรุณาเลือก Pantry' : ''}
                  >
                    {pantryList &&
                      isArray(pantryList) &&
                      pantryList.map((option) => {
                        if (showPantry.length === 0 || showPantry.indexOf(option.pantry_no) >= 0)
                          return (
                            <MenuItem key={option.id} value={option.pantry_no}>
                              {option.pantry_no}
                            </MenuItem>
                          );
                      })}
                  </TextField>
                )}
              </Box>

              <Box
                sx={{
                  borderRadius: 2,
                  border: 1,
                  borderColor: 'text.primary',
                  p: 3,
                  mt: 2
                }}
              >
                <TextField
                  id="outlined-select-currency"
                  label="เลขที่เอกสาร"
                  sx={{ width: '100%', mt: 2, mb: 2 }}
                  value={pageDatas.quotationNumber}
                  // onChange={handleChangeOrderNumber}
                  InputProps={{ readOnly: true }}
                ></TextField>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Stack>
                    <DatePicker
                      label="วันที่เอกสาร"
                      inputFormat="DD/MM/YYYY"
                      value={pageDatas.quotationDate}
                      // onChange={(handleDateDocumentChange, handleChangeOrderDate)}
                      renderInput={(params) => (
                        <TextField {...params} InputProps={{ readOnly: true }} />
                      )}
                      readOnly={true}
                    />
                  </Stack>
                </LocalizationProvider>
              </Box>
            </Grid>
          </Grid>

          <Grid item lg={8} md={6} xs={12}>
            <Box
              sx={{
                border: 1,
                borderRadius: 2,
                borderColor: 'text.primary',
                p: 3
              }}
            >
              {localStorage.getItem(server.LOGIN_SHOW_PRICE) === 'A' && (
                <Grid container spacing={2}>
                  <Grid item lg={6} md={6} xs={6}>
                    <TextField
                      type="text"
                      label="ยอดรวมรายการ :"
                      value={pageDatas.quotationDT.length}
                      variant="outlined"
                      sx={{ width: '100%', mb: 2 }}
                      inputProps={{ readOnly: true }}
                    />
                  </Grid>

                  <Grid item lg={6} md={6} xs={6}>
                    <TextField
                      type="text"
                      label="ยอดรวมทั้งหมด :"
                      value={fCurrency(
                        isArray(pageDatas.quotationDT) &&
                          pageDatas.quotationDT.reduce(function (prev, current) {
                            return prev + +(current.product_price * current.qty);
                          }, 0)
                      )}
                      variant="outlined"
                      sx={{ width: '100%', mb: 2 }}
                      inputProps={{ readOnly: true }}
                    />
                  </Grid>
                </Grid>
              )}
              {pageDatas.quotationDT && isArray(pageDatas.quotationDT) && (
                <>
                  <Scrollbar>
                    <TableContainer sx={{ minWidth: 350 }}>
                      <Table>
                        <QuotationProductListHead
                          order={order}
                          orderBy={orderBy}
                          headLabel={
                            localStorage.getItem(server.LOGIN_SHOW_PRICE) === 'A'
                              ? TABLE_HEAD
                              : TABLE_HEAD_NO_PRICE
                          }
                          onRequestSort={handleRequestSort}
                        />
                        <TableBody>
                          {pageDatas.quotationDT
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row) => {
                              const { id, product_name, product_unit, qty, product_price } = row;
                              const isItemSelected = selected.indexOf(id) !== -1;

                              return (
                                <TableRow
                                  hover
                                  key={id}
                                  tabIndex={-1}
                                  role="checkbox"
                                  selected={isItemSelected}
                                  aria-checked={isItemSelected}
                                >
                                  <TableCell align="left">{product_name}</TableCell>
                                  <TableCell align="left">{product_unit}</TableCell>
                                  {localStorage.getItem(server.LOGIN_SHOW_PRICE) === 'A' && (
                                    <TableCell align="left">{fCurrency(product_price)}</TableCell>
                                  )}
                                  <TableCell align="left">{qty}</TableCell>
                                  {localStorage.getItem(server.LOGIN_SHOW_PRICE) === 'A' && (
                                    <TableCell align="left">
                                      {fCurrency(product_price * qty)}
                                    </TableCell>
                                  )}

                                  {/* <TableCell align="right">
                                    <UserMoreMenu />
                                  </TableCell> */}
                                </TableRow>
                              );
                            })}
                          {emptyRows > 0 && (
                            <TableRow style={{ height: 53 * emptyRows }}>
                              <TableCell colSpan={6} />
                            </TableRow>
                          )}
                        </TableBody>
                        {/* {isUserNotFound && (
                          <TableBody>
                            <TableRow>
                              <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                                <SearchNotFound searchQuery={filterName} />
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        )} */}
                      </Table>
                    </TableContainer>
                  </Scrollbar>

                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={pageDatas.quotationDT.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </>
              )}

              <Stack spacing={2} direction="row" justifyContent="space-between">
                {pageDatas.quotationDT && isArray(pageDatas.quotationDT) && (
                  <Stack direction="row" alignItems="center">
                    {/* <Typography sx={{ pr: 2 }}>รวม</Typography>
                    <TextField
                      inputProps={{ style: { textAlign: 'right' } }}
                      value={fCurrency(
                        pageDatas.quotationDT.reduce(function (prev, current) {
                          return prev + +(current.product_price * current.qty);
                        }, 0)
                      )}
                    ></TextField> */}
                  </Stack>
                )}
                <Stack direction="row" alignItems="center">
                  <Button
                    variant="contained"
                    sx={{ mr: 2 }}
                    onClick={() => {
                      if (pageDatas.plantNo != '' && pageDatas.pantryNo != '') {
                        let data = new FormData();
                        data.append('plant_no', pageDatas.plantNo);
                        data.append('pantry_no', pageDatas.pantryNo);
                        data.append('quotation_number', pageDatas.quotationNumber);
                        data.append('quotation_date', pageDatas.quotationDate);
                        data.append('quotation_purchase', pageDatas.quotationPurchase);
                        data.append('status', pageDatas.status);
                        data.append('quotation_price', pageDatas.quotationPrice);

                        if (id) {
                          data.append('id', id);
                          props.putQuotations(navigate, data, pageDatas.quotationDT);
                        } else {
                          data.append(
                            'quotation_number',
                            'OD-' + new Date().toJSON().slice(0, 10).split`-`.join`` + '001'
                          );
                          data.append('quotation_date', new Date().toJSON());
                          props.postQuotations(navigate, data, pageDatas.quotationDT);
                        }

                        // const postplant = props.postPlants(navigate, data);
                        // postplant(dispatch);

                        Swal.fire({
                          position: 'center',
                          icon: 'success',
                          title: 'Save',
                          showConfirmButton: false,
                          timer: 1500
                        });
                      }
                    }}
                  >
                    บันทึก
                  </Button>
                  <Button
                    variant="contained"
                    component={RouterLink}
                    to="/dashboard/quotation"
                    sx={{ mr: 1 }}
                  >
                    ยกเลิก
                  </Button>
                </Stack>
              </Stack>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}

const mapStateToProps = ({
  quotationReducer,
  quotationdtReducer,
  pantryReducer,
  plantReducer
}) => ({
  quotationReducer,
  quotationdtReducer,
  pantryReducer,
  plantReducer
});

const mapDispatchToProps = {
  ...actions,
  ...PlantAction,
  ...PantryAction
};

export default connect(mapStateToProps, mapDispatchToProps)(QuotationProduct);
