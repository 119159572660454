/* eslint-disable */
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import searchFill from '@iconify/icons-eva/search-fill';
import trash2Fill from '@iconify/icons-eva/trash-2-fill';
import roundFilterList from '@iconify/icons-ic/round-filter-list';
import { server } from '../../../constants';
// material
import { styled } from '@mui/material/styles';
import {
  Box,
  Toolbar,
  Tooltip,
  IconButton,
  Typography,
  OutlinedInput,
  InputAdornment,
  TextField,
  Grid,
  MenuItem
} from '@mui/material';
import { isArray } from 'lodash';

// ----------------------------------------------------------------------

const RootStyle = styled(Toolbar)(({ theme }) => ({
  // height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(3, 1, 0, 3)
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`
  }
}));

// ----------------------------------------------------------------------

StockListToolbar.propTypes = {
  numSelected: PropTypes.number,
  filterName: PropTypes.string,
  onFilterName: PropTypes.func,
  plantfilterName: PropTypes.string,
  plantonFilterName: PropTypes.func,
  pantryfilterName: PropTypes.string,
  pantryonFilterName: PropTypes.func,
  plant: PropTypes.array,
  pantry: PropTypes.array
};

export default function StockListToolbar({
  numSelected,
  filterName,
  onFilterName,
  plant,
  pantry,
  plantfilterName,
  plantonFilterName,
  pantryfilterName,
  pantryonFilterName
}) {
  const showPlant = localStorage.getItem(server.LOGIN_PLANT);
  const showPantry = localStorage.getItem(server.LOGIN_PANTRY);
  console.log(showPlant);
  console.log(showPantry);

  // const hookLogin = useLogin();
  // console.log(hookLogin.plant);
  // console.log(hookLogin.pantry);

  return (
    <RootStyle
      sx={{
        ...(numSelected > 0 && {
          color: 'primary.main',
          bgcolor: 'primary.lighter'
        })
      }}
    >
      {numSelected > 0 ? (
        <Typography component="div" variant="subtitle1">
          {numSelected} selected
        </Typography>
      ) : (
        <>
          <Grid container spacing={2}>
            <Grid item lg={4} md={3} xs={6}>
              <TextField
                sx={{ width: '100%' }}
                select
                label="พื้นที่"
                value={plantfilterName}
                onChange={plantonFilterName}
              >
                {plant.map((option) => {
                  if (showPlant.indexOf(option.plant_no) >= 0 || showPlant.length === 0) {
                    return (
                      <MenuItem key={option.id} value={option.plant_no}>
                        {option.plant_no}
                      </MenuItem>
                    );
                  }
                })}
              </TextField>
            </Grid>
            <Grid item lg={4} md={3} xs={6}>
              <TextField
                sx={{ width: '100%' }}
                select
                label="ตึก"
                value={pantryfilterName}
                onChange={pantryonFilterName}
              >
                {pantry &&
                  pantry.length &&
                  pantry.map((option) => {
                    if (showPantry.length === 0 || showPantry.indexOf(option.pantry_no) >= 0)
                      return (
                        <MenuItem key={option.id} value={option.pantry_no}>
                          {option.pantry_no}
                        </MenuItem>
                      );
                  })}
              </TextField>
            </Grid>
          </Grid>
        </>
      )}
    </RootStyle>
  );
}
