/* eslint-disable */
import * as React from 'react';
import { useState } from 'react';
import { Container, Stack, Typography, TextField, Button } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Page from '../components/Page';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { httpClient } from '../utils/HttpClient';
import { server, OK } from '../constants';
import Swal from 'sweetalert2';
import { fDate } from '../utils/formatTime';

export default function ResetData() {
  const [DateDocumentvalue, setDateDocumentValue] = React.useState(new Date());
  const handleDateDocumentChange = (newValue) => {
    setDateDocumentValue(newValue.toDate());
  };
  return (
    <Page title="Reset Data | JConnect">
      <Container maxWidth="false">
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Reset Data
          </Typography>
        </Stack>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Stack>
            <DatePicker
              label="ลบข้อมูลถึงวันที่"
              inputFormat="DD/MM/YYYY"
              value={DateDocumentvalue}
              onChange={handleDateDocumentChange}
              renderInput={(params) => <TextField {...params} sx={{ width: '375px' }} />}
            />
          </Stack>
        </LocalizationProvider>
        <Button
          variant="contained"
          startIcon={<RestartAltIcon />}
          sx={{ mt: '10px', width: '375px', height: '50px' }}
          onClick={async () => {
            Swal.fire({
              title: `คุณต้องการลบข้อมูลถึงวันที่ ${fDate(DateDocumentvalue)} ใช่หรือไม่`,
              showDenyButton: true,
              confirmButtonText: 'ลบข้อมูล',
              denyButtonText: `ยกเลิก`
            }).then(async (result) => {
              /* Read more about isConfirmed, isDenied below */
              if (result.isConfirmed) {
                Swal.fire({
                  title: 'หลังจากลบแล้วไม่สามารถเรียกกลับคืนได้',
                  showDenyButton: true,
                  confirmButtonText: 'ยืนยัน',
                  denyButtonText: `ยกเลิก`
                }).then(async (result) => {
                  if (result.isConfirmed) {
                    const resultDelete = await httpClient.delete(server.ORDER_RESET_URL, {
                      data: { date: DateDocumentvalue.toJSON().slice(0, 10) }
                    });
                    // console.log(resultDelete);
                    if (resultDelete.data.result == OK) {
                      Swal.fire('ลบข้อมูลสำเร็จ', '', 'success');
                    }
                  }
                });
              } else if (result.isDenied) {
                // Swal.fire('Changes are not saved', '', 'info');
              }
            });
          }}
        >
          ลบข้อมูลจนถึงวันที่ระบุ
        </Button>
      </Container>
    </Page>
  );
}
