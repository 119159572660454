/* eslint-disable */
import * as React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import searchFill from '@iconify/icons-eva/search-fill';
import trash2Fill from '@iconify/icons-eva/trash-2-fill';
import roundFilterList from '@iconify/icons-ic/round-filter-list';
// material
import { styled } from '@mui/material/styles';
import {
  Box,
  Toolbar,
  Tooltip,
  IconButton,
  Typography,
  OutlinedInput,
  InputAdornment,
  TextField,
  MenuItem,
  Card,
  Grid,
  Button
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Stack } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { server } from '../../../constants';
import { isArray } from 'lodash';
// ----------------------------------------------------------------------

const RootStyle = styled(Toolbar)(({ theme }) => ({
  // height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(3, 1, 0, 3)
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`
  }
}));

// ----------------------------------------------------------------------

ExampleListToolbar.propTypes = {
  plantNoFrom: PropTypes.string,
  onPlantNoFrom: PropTypes.func,
  plantNoTo: PropTypes.string,
  onPlantNoTo: PropTypes.func,
  plantList: PropTypes.array,
  productNoFrom: PropTypes.string,
  onProductNoFrom: PropTypes.func,
  productNoTo: PropTypes.string,
  onProductNoTo: PropTypes.func,
  productList: PropTypes.array,
  dateFrom: PropTypes.string,
  onDateFrom: PropTypes.func,
  dateTo: PropTypes.string,
  onDateTo: PropTypes.func,
  pantryNoFrom: PropTypes.string,
  onPantryNoFrom: PropTypes.func,
  pantryNoTo: PropTypes.string,
  onPantryNoTo: PropTypes.func,
  pantryList: PropTypes.array,
  onClickPlantAll: PropTypes.func,
  onClickPlantCLEAR: PropTypes.func,
  onClickPantryAll: PropTypes.func,
  onClickPantryCLEAR: PropTypes.func
};

const ITEM_HEIGHT_PLANT = 48;
const ITEM_PADDING_TOP_PLANT = 8;
const MenuPropsPlant = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT_PLANT * 4.5 + ITEM_PADDING_TOP_PLANT,
      width: 250
    }
  }
};

const ITEM_HEIGHT_PANTRY = 48;
const ITEM_PADDING_TOP_PANTRY = 8;
const MenuPropsPantry = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT_PANTRY * 4.5 + ITEM_PADDING_TOP_PANTRY,
      width: 250
    }
  }
};

export default function ExampleListToolbar({
  plantNoFrom,
  onPlantNoFrom,
  plantNoTo,
  onPlantNoTo,
  plantList,
  productNoFrom,
  onProductNoFrom,
  productNoTo,
  onProductNoTo,
  productList,
  dateFrom,
  onDateFrom,
  dateTo,
  onDateTo,
  pantryNoFrom,
  onPantryNoFrom,
  pantryNoTo,
  onPantryNoTo,
  pantryList,
  onClickPlantAll,
  onClickPlantCLEAR,
  onClickPantryAll,
  onClickPantryCLEAR
}) {
  const showPlant = localStorage.getItem(server.LOGIN_PLANT);
  const showPantry = localStorage.getItem(server.LOGIN_PANTRY);

  return (
    <Grid container spacing={2}>
      <Grid item lg={3} md={6} xs={12}>
        <Card sx={{ p: 2 }}>
          <Grid item container spacing={2}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Grid item xs={12}>
                <Stack>
                  <DatePicker
                    label="วันที่เริ่ม"
                    inputFormat="DD/MM/YYYY"
                    value={dateFrom}
                    onChange={onDateFrom}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </Stack>
              </Grid>
              <Grid item xs={12}>
                <Stack>
                  <DatePicker
                    label="ถึงวันที่"
                    inputFormat="DD/MM/YYYY"
                    value={dateTo}
                    onChange={onDateTo}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </Stack>
              </Grid>
            </LocalizationProvider>
          </Grid>
        </Card>
      </Grid>

      <Grid item lg={3} md={6} xs={12}>
        <Card sx={{ p: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                sx={{ width: '100%' }}
                select
                label="รหัสสินค้าเริ่ม"
                value={productNoFrom}
                onChange={onProductNoFrom}
              >
                <MenuItem value="%">ทั้งหมด</MenuItem>
                {productList &&
                  productList.map((option) => {
                    return (
                      <MenuItem key={option.product_code} value={option.product_name}>
                        {option.product_code + ' - ' + option.product_name}
                      </MenuItem>
                    );
                  })}
              </TextField>
            </Grid>

            <Grid item xs={12}>
              <TextField
                sx={{ width: '100%' }}
                select
                label="ถึงรหัสสินค้า"
                value={productNoTo}
                onChange={onProductNoTo}
              >
                <MenuItem value="%">ทั้งหมด</MenuItem>
                {productList &&
                  productList.map((option) => {
                    return (
                      <MenuItem key={option.product_code} value={option.product_name}>
                        {option.product_code + ' - ' + option.product_name}
                      </MenuItem>
                    );
                  })}
              </TextField>
            </Grid>
          </Grid>
        </Card>
      </Grid>

      <Grid item lg={6} md={12} xs={12}>
        <Card sx={{ p: 2 }}>
          <Grid item container spacing={2}>
            <Grid item xs={10}>
              <FormControl sx={{ width: '100%' }}>
                <InputLabel id="plantNo">รหัสพื้นที่</InputLabel>
                <Select
                  labelId="plantNo"
                  id="plantNo"
                  multiple
                  value={plantNoFrom}
                  onChange={onPlantNoFrom}
                  input={<OutlinedInput label="รหัสพื้นที่" />}
                  renderValue={(selected) => selected.join(', ')}
                  MenuProps={MenuPropsPlant}
                >
                  {plantList &&
                    plantList.map((option) => {
                      if (showPlant.indexOf(option.plant_no) >= 0 || showPlant.length === 0) {
                        return (
                          <MenuItem key={option.id} value={option.plant_no}>
                            <Checkbox checked={plantNoFrom.indexOf(option.plant_no) > -1} />
                            {/* {option.pantry_no} */}
                            <ListItemText primary={option.plant_no} />
                          </MenuItem>
                        );
                      }
                    })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={1}>
              <Button
                variant="contained"
                sx={{ width: '100%', height: '100%' }}
                onClick={onClickPlantAll}
              >
                ALL
              </Button>
            </Grid>
            {/* <Grid item style={{ width: '90px' }}> */}
            <Grid item xs={1}>
              <Button
                variant="contained"
                sx={{ width: '100%', height: '100%' }}
                onClick={onClickPlantCLEAR}
              >
                CLEAR
              </Button>
            </Grid>

            <Grid item xs={10}>
              <FormControl sx={{ width: '100%' }}>
                <InputLabel id="pantryNo">รหัสตึก</InputLabel>
                <Select
                  labelId="pantryNo"
                  id="pantryNo"
                  multiple
                  value={pantryNoFrom}
                  onChange={onPantryNoFrom}
                  input={<OutlinedInput label="รหัสตึก" />}
                  renderValue={(selected) => selected.join(', ')}
                  MenuProps={MenuPropsPantry}
                >
                  {pantryList &&
                    isArray(pantryList) &&
                    pantryList.map((option) => {
                      if (showPantry.indexOf(option.pantry_no) >= 0 || showPantry.length === 0) {
                        return (
                          <MenuItem key={option.id} value={option.pantry_no}>
                            <Checkbox checked={pantryNoFrom.indexOf(option.pantry_no) > -1} />
                            {/* {option.pantry_no} */}
                            <ListItemText primary={option.pantry_no} />
                          </MenuItem>
                        );
                      }
                    })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={1}>
              <Button
                variant="contained"
                sx={{ width: '100%', height: '100%' }}
                onClick={onClickPantryAll}
              >
                ALL
              </Button>
            </Grid>
            {/* <Grid item style={{ width: '90px' }}> */}
            <Grid item xs={1}>
              <Button
                variant="contained"
                sx={{ width: '100%', height: '100%' }}
                onClick={onClickPantryCLEAR}
              >
                CLEAR
              </Button>
            </Grid>
          </Grid>
        </Card>
      </Grid>

      {/* <Grid item lg={3} md={6} xs={12}>
        <Card sx={{ p: 2 }}>
          <Grid item container spacing={2}>
            <Grid item xs={12}>
              <TextField
                sx={{ width: '100%' }}
                select
                label="พื้นที่เริ่ม"
                value={plantNoFrom}
                onChange={onPlantNoFrom}
              >
                <MenuItem value="%">ทั้งหมด</MenuItem>
                {plantList &&
                  plantList.map((option) => (
                    <MenuItem key={option.id} value={option.plant_no}>
                      {option.plant_no}
                    </MenuItem>
                  ))}
              </TextField>
            </Grid>

            <Grid item xs={12}>
              <TextField
                sx={{ width: '100%' }}
                select
                label="ถึงพื้นที่"
                value={plantNoTo}
                onChange={onPlantNoTo}
              >
                <MenuItem value="%">ทั้งหมด</MenuItem>
                {plantList &&
                  plantList.map((option) => (
                    <MenuItem key={option.id} value={option.plant_no}>
                      {option.plant_no}
                    </MenuItem>
                  ))}
              </TextField>
            </Grid>
          </Grid>
        </Card>
      </Grid>

      <Grid item lg={3} md={6} xs={12}>
        <Card sx={{ p: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                sx={{ width: '100%' }}
                select
                label="ตึกเริ่ม"
                value={pantryNoFrom}
                onChange={onPantryNoFrom}
              >
                <MenuItem value="%">ทั้งหมด</MenuItem>
                {pantryList &&
                  pantryList.length &&
                  pantryList.map((option) => (
                    <MenuItem key={option.id} value={option.pantry_no}>
                      {option.pantry_no}
                    </MenuItem>
                  ))}
              </TextField>
            </Grid>

            <Grid item xs={12}>
              <TextField
                sx={{ width: '100%' }}
                select
                label="ถึงตึก"
                value={pantryNoTo}
                onChange={onPantryNoTo}
              >
                <MenuItem value="%">ทั้งหมด</MenuItem>
                {pantryList &&
                  pantryList.length &&
                  pantryList.map((option) => (
                    <MenuItem key={option.id} value={option.pantry_no}>
                      {option.pantry_no}
                    </MenuItem>
                  ))}
              </TextField>
            </Grid>
          </Grid>
        </Card>
      </Grid> */}
    </Grid>
  );
}
