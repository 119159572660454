/* eslint-disable */
import * as React from 'react';
import { Icon } from '@iconify/react';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import plusFill from '@iconify/icons-eva/plus-fill';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import { useFormControl } from '@mui/material/FormControl';
import { useState, useEffect } from 'react';

import {
  Box,
  Grid,
  Card,
  Button,
  Divider,
  Container,
  Typography,
  Stack,
  OutlinedInput,
  FormControl,
  CardMedia,
  TextField
} from '@mui/material';
import { connect } from 'react-redux';
import * as actions from '../actions/product.action';

import Page from '../components/Page';
import Swal from 'sweetalert2';
import { isObject } from 'lodash';
import { imageUrl, OK, NOK, server } from '../constants';

import { httpClient } from '../utils/HttpClient';

// ----------------------------------------------------------------------

export function CreateProduct(props) {
  const navigate = useNavigate();
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageShow, setImageShow] = useState(null);
  const [productCode, setProductCode] = React.useState('');
  const [productName, setProductName] = React.useState('');
  const [productUnit, setProductUnit] = React.useState('');
  const [productPrice, setProductPrice] = React.useState('');
  // const [productImage, setProductImage] = React.useState('');

  const { id } = useParams();

  if (id) {
    // alert(id);

    useEffect(() => {
      props.getProductById(id);
    }, []);

    useEffect(() => {
      const { result } = props.productReducer;

      if (result) {
        setProductCode(result.product_code);
        setProductName(result.product_name);
        setProductUnit(result.product_unit);
        setProductPrice(result.product_price);
        setSelectedImage(result.product_image);
      }
    }, [props.productReducer]);
  }

  useEffect(() => {
    if (selectedImage) {
      if (isObject(selectedImage)) {
        setImageShow(URL.createObjectURL(selectedImage));
      } else {
        setImageShow(`${imageUrl}/${selectedImage}`);
      }
    }
  }, [selectedImage]);

  const handleChangeProductCode = (event) => {
    setProductCode(event.target.value);
  };

  const handleChangeProductName = (event) => {
    setProductName(event.target.value);
  };

  const handleChangeProductUnit = (event) => {
    setProductUnit(event.target.value);
  };
  const handleChangeProductPrice = (event) => {
    setProductPrice(event.target.value);
  };
  // const handleChangeProductImage = (event) => {
  //   setProductImage(event.target.value);
  // };

  return (
    <Page title="CreateProduct | JConnect">
      <Container>
        <Card>
          <Grid container spacing={2} sx={{ p: 3 }}>
            <Grid item container spacing={2} lg={12} md={12} xs={12}>
              <Grid item lg={12} md={12} xs={12}>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  sx={{ mb: 1 }}
                >
                  <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                    เพิ่มสินค้า
                  </Typography>
                </Stack>
              </Grid>
              <Grid item lg={12} md={12} xs={12}>
                <Divider />
              </Grid>
            </Grid>

            <Grid item container spacing={2} lg={12} md={12} xs={12}>
              <Grid item lg={3} md={4} xs={5}>
                <Box component="form" noValidate autoComplete="off">
                  <TextField
                    sx={{ width: '100%' }}
                    type="text"
                    variant="outlined"
                    label="รหัสสินค้า"
                    id="productCode"
                    value={productCode}
                    onChange={handleChangeProductCode}
                  ></TextField>
                </Box>
              </Grid>

              <Grid item lg={9} md={8} xs={7}>
                <Box component="form" noValidate autoComplete="off">
                  <TextField
                    sx={{ width: '100%' }}
                    type="text"
                    variant="outlined"
                    label="ชื่อสินค้า"
                    id="productName"
                    value={productName}
                    onChange={handleChangeProductName}
                  ></TextField>
                </Box>
              </Grid>

              <Grid item lg={6} md={6} xs={6}>
                <Box component="form" noValidate autoComplete="off">
                  <TextField
                    sx={{ width: '100%' }}
                    type="text"
                    variant="outlined"
                    label="หน่วย"
                    id="productUnit"
                    value={productUnit}
                    onChange={handleChangeProductUnit}
                  ></TextField>
                </Box>
              </Grid>
              <Grid item lg={6} md={6} xs={6}>
                <Box component="form" noValidate autoComplete="off">
                  <TextField
                    sx={{ width: '100%' }}
                    type="number"
                    variant="outlined"
                    label="ราคา"
                    id="productPrice"
                    value={productPrice}
                    onChange={handleChangeProductPrice}
                  ></TextField>
                </Box>
              </Grid>

              <Grid item lg={12} md={12} xs={12}>
                {imageShow && selectedImage && (
                  <>
                    <img src={imageShow} alt={selectedImage.name} />
                  </>
                )}
                <input
                  accept="image/*"
                  type="file"
                  id="select-image"
                  style={{ display: 'none' }}
                  onChange={(e) => setSelectedImage(e.target.files[0])}
                />

                <Button
                  htmlFor="select-image"
                  variant="contained"
                  component="label"
                  startIcon={<AddAPhotoIcon />}
                  // value={productImage}
                  // onChange={handleChangeProductImage}
                >
                  {(!imageShow || !selectedImage) && 'เพิ่มรูปภาพ'}
                  {imageShow && selectedImage && 'เปลี่ยนรูปภาพ'}
                </Button>
              </Grid>
            </Grid>

            <Grid
              item
              container
              spacing={2}
              lg={12}
              md={12}
              xs={12}
              sx={{
                display: 'flex',
                flexDirection: 'row-reverse'
              }}
            >
              <Button
                variant="contained"
                component={RouterLink}
                to="/dashboard/product/edit"
                sx={{ mt: 2 }}
              >
                ยกเลิก
              </Button>
              <Button
                variant="contained"
                sx={{ mr: 2, mt: 2 }}
                onClick={async () => {
                  if (
                    productCode == '' ||
                    productName == '' ||
                    productUnit == '' ||
                    productPrice == ''
                  ) {
                    Swal.fire('กรุณาระบุข้อมูลให้ครบถ้วน', '', 'error');
                  } else {
                    await httpClient
                      .get(`${server.PRODUCT_CHECKDUP_URL}`, {
                        params: { product_code: productCode, id: id }
                      })
                      .then(async (result) => {
                        console.log(result);
                        if (result.data.result == OK) {
                          Swal.fire('รหัสสินค้าซ้ำ', '', 'error');
                        } else if (result.data.result == NOK) {
                          let data = new FormData();
                          data.append('product_code', productCode);
                          data.append('product_name', productName);
                          data.append('product_unit', productUnit);
                          data.append('product_price', productPrice);
                          data.append('product_image', selectedImage);

                          if (id) {
                            data.append('id', id);
                            await props.putProducts(navigate, data);
                          } else {
                            await props.postProducts(navigate, data);
                          }

                          // const postplant = props.postPlants(navigate, data);
                          // postplant(dispatch);

                          Swal.fire({
                            position: 'center',
                            icon: 'success',
                            title: 'Save',
                            showConfirmButton: false,
                            timer: 1500
                          });
                        }
                      });
                  }
                }}
              >
                บันทึก
              </Button>
            </Grid>
          </Grid>
        </Card>
      </Container>
    </Page>
  );
}

const mapStateToProps = ({ productReducer }) => ({ productReducer });

const mapDispatchToProps = {
  ...actions
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateProduct);
