/* eslint-disable */
import { useState } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import searchFill from '@iconify/icons-eva/search-fill';
import trash2Fill from '@iconify/icons-eva/trash-2-fill';
import roundFilterList from '@iconify/icons-ic/round-filter-list';
// material
import { styled } from '@mui/material/styles';
import {
  Box,
  Toolbar,
  Tooltip,
  IconButton,
  Typography,
  OutlinedInput,
  InputAdornment,
  TextField,
  MenuItem,
  Grid
} from '@mui/material';
// import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
// import LocalizationProvider from '@mui/lab/LocalizationProvider';
// import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

// ----------------------------------------------------------------------

const RootStyle = styled(Toolbar)(({ theme }) => ({
  // height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(3, 1, 0, 3)
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`
  }
}));

// ----------------------------------------------------------------------

BillingListToolbar.propTypes = {
  numSelected: PropTypes.number,
  filterName: PropTypes.string,
  onFilterName: PropTypes.func,
  plantfilterName: PropTypes.string,
  plantonFilterName: PropTypes.func,
  plant: PropTypes.array,
  DateDocumentChange: PropTypes.func,
  DateDocumentvalue: PropTypes.string
};

export default function BillingListToolbar({
  numSelected,
  filterName,
  onFilterName,
  plantfilterName,
  plantonFilterName,
  plant,
  DateDocumentChange,
  DateDocumentvalue
}) {
  return (
    <RootStyle
      sx={{
        ...(numSelected > 0 && {
          color: 'primary.main',
          bgcolor: 'primary.lighter'
        })
      }}
    >
      {numSelected > 0 ? (
        <Typography component="div" variant="subtitle1">
          {numSelected} selected
        </Typography>
      ) : (
        <>
          <Grid container spacing={2}>
            <Grid item lg={6} md={6} xs={6}>
              <TextField
                sx={{ width: '100%' }}
                select
                label="บริษัท"
                value={plantfilterName}
                onChange={plantonFilterName}
              >
                {plant.map((option) => (
                  <MenuItem value={option.plant_details}>{option.plant_details}</MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid item lg={4} md={4} xs={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                {/* <Stack> */}
                {/* <DesktopDatePicker
                  label="วางบิลเดือน"
                  inputFormat="MM/yyyy"
                  views={['year', 'month']}
                  value={DateDocumentvalue}
                  onChange={DateDocumentChange}
                  renderInput={(params) => <TextField {...params} />}
                  // readOnly={true}
                /> */}

                <DatePicker
                  views={['year', 'month']}
                  inputFormat="MM/YYYY"
                  label="วางบิลเดือน"
                  value={DateDocumentvalue}
                  onChange={DateDocumentChange}
                  renderInput={(params) => <TextField {...params} helperText={null} />}
                />

                {/* </Stack> */}
              </LocalizationProvider>
            </Grid>
          </Grid>
        </>
      )}
    </RootStyle>
  );
}
