/* eslint-disable */
import * as React from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { filter } from 'lodash';
import { Icon } from '@iconify/react';
import { useState, useEffect } from 'react';
import { Link as RouterLink, useParams, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../actions/deliver.action';
import * as PlantAction from '../actions/plant.action';
import * as PantryAction from '../actions/pantry.action';
import {
  Box,
  Table,
  Grid,
  Card,
  Paper,
  Button,
  Checkbox,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Toolbar,
  TextField,
  MenuItem,
  Stack
} from '@mui/material';
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import { UserMoreMenu } from '../components/_dashboard/user';
import { DeliverProductListHead } from '../components/_dashboard/deliverproduct';
// import DeliverProductList from '../_mocks_/deliverproduct';

import Swal from 'sweetalert2';
import { useDeliver } from '../hooks/useDeliver';
import { server } from '../constants';
import { isArray, isObject } from 'lodash';
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'order_number', label: 'รหัสใบสั่งสินค้า', alignRight: false },
  { id: 'product_name', label: 'รายการสินค้า', alignRight: false },
  { id: 'product_unit', label: 'หน่วย', alignRight: false },
  { id: 'qty', label: 'จำนวน', alignRight: false }
  // { id: '' }
];

// ----------------------------------------------------------------------

// function descendingComparator(a, b, orderBy) {
//   if (b[orderBy] < a[orderBy]) {
//     return -1;
//   }
//   if (b[orderBy] > a[orderBy]) {
//     return 1;
//   }
//   return 0;
// }

// function getComparator(order, orderBy) {
//   return order === 'desc'
//     ? (a, b) => descendingComparator(a, b, orderBy)
//     : (a, b) => -descendingComparator(a, b, orderBy);
// }

// function applySortFilter(array, comparator, query) {
//   const stabilizedThis = dataFilter.map((el, index) => [el, index]);
//   stabilizedThis.sort((a, b) => {
//     const order = comparator(a[0], b[0]);
//     if (order !== 0) return order;
//     return a[1] - b[1];
//   });
//   if (query) {
//     dataFilter = filter(dataFilter, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
//   }
//   return stabilizedThis.map((el) => el[0]);
// }

export function DeliverProduct(props) {
  const navigate = useNavigate();
  const pageDatas = useDeliver();
  const [plantList, setPlantList] = useState(null);
  const [pantryList, setPantryList] = useState(null);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(
    parseInt(localStorage.getItem(server.ROWS_PER_PAGE))
  );

  const showPlant = localStorage.getItem(server.LOGIN_PLANT);
  const showPantry = localStorage.getItem(server.LOGIN_PANTRY);

  useEffect(() => {
    props.getPlants();
  }, []);

  useEffect(() => {
    props.getPantryByPlantNo(pageDatas.plantNo);
  }, [pageDatas.plantNo]);

  // useEffect(() => {
  //   props.getPantrys();
  // }, []);

  useEffect(() => {
    const { result } = props.plantReducer;
    // console.log(result);
    if (result) setPlantList(result);
  }, [props.plantReducer]);

  useEffect(() => {
    const { result } = props.pantryReducer;
    // console.log(result);
    if (result) setPantryList(result);
  }, [props.pantryReducer]);

  const { id } = useParams();

  useEffect(() => {
    if (id && pageDatas.isState == 'E' && pageDatas.isPerState == '') {
      const { result } = props.deliverReducer;
      if (result) {
        pageDatas.setPlantNo(result.plant_no);
        pageDatas.setPantryNo(result.pantry_no);
        pageDatas.setDeliverNumber(result.deliver_number);
        pageDatas.setDeliverDate(result.deliver_date);
        pageDatas.setDeliverPurchase(result.deliver_purchase);
        pageDatas.setDeliverApprove(result.deliver_approve);
        pageDatas.setDeliverApproveDate(result.deliver_approve_date);
        pageDatas.setStatus(result.status);
      }
    }
  }, [props.deliverReducer]);

  useEffect(() => {
    if (id && pageDatas.isState == 'E' && pageDatas.isPerState == '') {
      const { result } = props.deliverdtReducer;

      if (result && isArray(result)) {
        pageDatas.setDeliverDT(result);
      }
    }
  }, [props.deliverdtReducer]);

  useEffect(() => {
    if (id && pageDatas.isState == 'E' && pageDatas.isPerState == '') {
      // console.log('E');
      props.getDeliverById(id);
    } else if (pageDatas.isState == 'A' && pageDatas.isPerState == '') {
      // console.log('A');
      pageDatas.emptyData();
    }
  }, [pageDatas.isState]);

  useEffect(() => {
    if (id && pageDatas.isState == 'E' && pageDatas.isPerState == '') {
      // console.log('E');
      props.getDeliverDTById(id);
    }
  }, [pageDatas.isState]);

  pageDatas.setIsPerState('');

  const handleChangePlantNo = (event) => {
    pageDatas.setPlantNo(event.target.value);
    pageDatas.setPantryNo('');
  };

  const handleChangePantryNo = (event) => {
    pageDatas.setPantryNo(event.target.value);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = DeliverProductList.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    localStorage.setItem(server.ROWS_PER_PAGE, event.target.value);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - pageDatas.deliverDT.length) : 0;

  // const filteredUsers = applySortFilter(
  //   DeliverProductList,
  //   getComparator(order, orderBy),
  //   filterName
  // );

  return (
    <Page title="Deliver | JConnect">
      <Container maxWidth="false">
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            รายละเอียดใบส่งสินค้า
          </Typography>
          <Button
            variant="contained"
            // component={RouterLink}
            // to="#"
            startIcon={<Icon icon={plusFill} />}
            onClick={() => {
              if (pageDatas.plantNo != '' && pageDatas.pantryNo != '') {
                pageDatas.setIsPerState(id);
                // pageDatas.setIsState('P');
                navigate('/dashboard/deliverpopup', { replace: true });
              }
            }}
          >
            เพิ่มใบสั่งสินค้า
          </Button>
        </Stack>

        <Grid container spacing={2} sx={{ mt: 3 }}>
          <Grid item lg={4} md={6} xs={12}>
            <Box
              sx={{
                borderRadius: 2,
                border: 1,
                borderColor: 'text.primary',
                p: 3
              }}
            >
              {(pageDatas.plantNo || pageDatas.isState == 'A') && (
                <TextField
                  id="outlined-select-currency"
                  select
                  label="พื้นที่"
                  sx={{ width: '100%', mt: 2, mb: 2 }}
                  value={pageDatas.plantNo}
                  onChange={handleChangePlantNo}
                  error={pageDatas.plantNo == ''}
                  helperText={pageDatas.plantNo == '' ? 'กรุณาเลือก พื้นที่' : ''}
                >
                  {plantList &&
                    plantList.map((option) => {
                      if (showPlant.indexOf(option.plant_no) >= 0 || showPlant.length === 0) {
                        return (
                          <MenuItem key={option.id} value={option.plant_no}>
                            {option.plant_no}
                          </MenuItem>
                        );
                      }
                    })}
                </TextField>
              )}
              {(pageDatas.pantryNo || pageDatas.isState == 'A') && (
                <TextField
                  id="outlined-select-currency"
                  select
                  label="ตึก"
                  sx={{ width: '100%', mt: 2, mb: 2 }}
                  value={pageDatas.pantryNo}
                  onChange={handleChangePantryNo}
                  error={pageDatas.pantryNo == ''}
                  helperText={pageDatas.pantryNo == '' ? 'กรุณาเลือก ตึก' : ''}
                >
                  {pantryList &&
                    pantryList.map((option) => {
                      if (showPantry.length === 0 || showPantry.indexOf(option.pantry_no) >= 0)
                        return (
                          <MenuItem key={option.id} value={option.pantry_no}>
                            {option.pantry_no}
                          </MenuItem>
                        );
                    })}
                </TextField>
              )}
            </Box>
          </Grid>

          <Grid item lg={8} md={6} xs={12}>
            <Box
              sx={{
                border: 1,
                borderRadius: 2,
                borderColor: 'text.primary',
                p: 3
              }}
            >
              {pageDatas.deliverDT && isArray(pageDatas.deliverDT) && (
                <>
                  <Scrollbar>
                    <TableContainer sx={{ minWidth: 450 }}>
                      <Table>
                        <DeliverProductListHead
                          order={order}
                          orderBy={orderBy}
                          headLabel={TABLE_HEAD}
                          rowCount={pageDatas.deliverDT.length}
                          numSelected={selected.length}
                          onRequestSort={handleRequestSort}
                          onSelectAllClick={handleSelectAllClick}
                        />
                        <TableBody>
                          {pageDatas.deliverDT
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row) => {
                              const { id, order_number, product_name, product_unit, qty } = row;
                              const isItemSelected = selected.indexOf(id) !== -1;

                              return (
                                <TableRow
                                  hover
                                  key={id}
                                  tabIndex={-1}
                                  role="checkbox"
                                  selected={isItemSelected}
                                  aria-checked={isItemSelected}
                                >
                                  <TableCell align="left">{order_number}</TableCell>
                                  <TableCell align="left">{product_name}</TableCell>
                                  <TableCell align="left">{product_unit}</TableCell>
                                  <TableCell align="left">{qty}</TableCell>

                                  {/* <TableCell align="right">
                                    <UserMoreMenu />
                                  </TableCell> */}
                                </TableRow>
                              );
                            })}
                          {emptyRows > 0 && (
                            <TableRow style={{ height: 53 * emptyRows }}>
                              <TableCell colSpan={6} />
                            </TableRow>
                          )}
                        </TableBody>
                        {/* {isUserNotFound && (
                          <TableBody>
                            <TableRow>
                              <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                                <SearchNotFound searchQuery={filterName} />
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        )} */}
                      </Table>
                    </TableContainer>
                  </Scrollbar>

                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={pageDatas.deliverDT.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </>
              )}
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row-reverse',
                  //   p: 1,
                  mt: 2,
                  bgcolor: 'background.paper'
                }}
              >
                <Button variant="contained" component={RouterLink} to="/dashboard/deliver">
                  ยกเลิก
                </Button>
                <Button
                  variant="contained"
                  sx={{ mr: 2 }}
                  onClick={() => {
                    if (pageDatas.plantNo != '' && pageDatas.pantryNo != '') {
                      let data = new FormData();
                      data.append('plant_no', pageDatas.plantNo);
                      data.append('pantry_no', pageDatas.pantryNo);
                      data.append('deliver_number', pageDatas.deliverNumber);
                      data.append('deliver_date', pageDatas.deliverDate);
                      data.append('deliver_purchase', pageDatas.deliverPurchase);
                      // data.append('deliver_approve', pageDatas.deliverApprove);
                      // data.append('deliver_approve_date', pageDatas.deliverApproveDate);
                      data.append('status', pageDatas.status);

                      if (id) {
                        data.append('id', id);
                        props.putDelivers(navigate, data, pageDatas.deliverDT);
                      } else {
                        data.append(
                          'deliver_number',
                          'OD-' + new Date().toJSON().slice(0, 10).split`-`.join`` + '001'
                        );
                        data.append('deliver_date', new Date().toJSON());
                        props.postDelivers(navigate, data, pageDatas.deliverDT);
                      }

                      // const postplant = props.postPlants(navigate, data);
                      // postplant(dispatch);

                      Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: 'Save',
                        showConfirmButton: false,
                        timer: 1500
                      });
                    }
                  }}
                >
                  บันทึก
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}

const mapStateToProps = ({ deliverReducer, deliverdtReducer, pantryReducer, plantReducer }) => ({
  deliverReducer,
  deliverdtReducer,
  pantryReducer,
  plantReducer
});

const mapDispatchToProps = {
  ...actions,
  ...PlantAction,
  ...PantryAction
};

export default connect(mapStateToProps, mapDispatchToProps)(DeliverProduct);
