/* eslint-disable */
import {
  HTTP_ADMIN_SUCCESS,
  HTTP_ADMIN_FETCHING,
  HTTP_ADMIN_FAILED,
  server,
  OK,
  YES
} from '../constants';
import { httpClient } from '../utils/HttpClient';

const setStateAdminToSuccess = (payload) => ({
  type: HTTP_ADMIN_SUCCESS,
  payload
});

const setStateAdminToFetching = () => ({
  type: HTTP_ADMIN_FETCHING
});

const setStateAdminToFailed = () => ({
  type: HTTP_ADMIN_FAILED
});

export const getAdmins = () => {
  return async (dispatch) => {
    dispatch(setStateAdminToFetching());
    await doGetAdmins(dispatch);
  };
};

const doGetAdmins = async (dispatch) => {
  try {
    let result = await httpClient.get(server.ADMIN_URL);
    //console.log(' *** doGetPlants >> ', result);
    dispatch(setStateAdminToSuccess(result.data));
  } catch (error) {
    alert(JSON.stringify(error));
    dispatch(setStateAdminToFailed());
  }
};

export const deleteAdmins = (id, type) => {
  return async (dispatch) => {
    dispatch(setStateAdminToFetching());
    await httpClient.delete(`${server.ADMIN_URL}/${id}`);
    await doGetAdminByType(dispatch, type);
  };
};

export const getAdminById = (id) => {
  return (dispatch) => {
    // dispatch(finishInitialization(false))
    dispatch(setStateAdminToFetching());
    httpClient
      .get(`${server.ADMIN_URL}/${id}`)
      .then((result) => {
        dispatch(setStateAdminToSuccess(result.data));
      })
      .catch((err) => {
        console.log(err);
        dispatch(setStateAdminToFailed());
      });
  };
};

export const getAdminByType = (type) => {
  return async (dispatch) => {
    dispatch(setStateAdminToFetching());
    await doGetAdminByType(dispatch, type);
  };
};

const doGetAdminByType = async (dispatch, type) => {
  try {
    let result = await httpClient.get(`${server.ADMIN_URL}/type/${type}`);
    dispatch(setStateAdminToSuccess(result.data));
  } catch (error) {
    alert(JSON.stringify(error));
    dispatch(setStateAdminToFailed());
  }
};

export const postAdmins = (navigate, data) => {
  return async (dispatch) => {
    dispatch(setStateAdminToFetching());
    let result = await httpClient.post(server.ADMIN_URL, data);
    console.log(result);

    if (result.data.result == OK) {
      navigate(`/dashboard/admin/${data.get('admin_type')}`, { replace: true });
      dispatch(setStateAdminToSuccess(result));
    } else {
      dispatch(setStateAdminToFailed());
    }
  };
};

export const putAdmins = (navigate, data) => {
  console.log(data.get('admin_type'));
  return async (dispatch) => {
    dispatch(setStateAdminToFetching());
    let result = await httpClient.put(server.ADMIN_URL, data);
    if (result.data.result == OK) {
      navigate(`/dashboard/admin/${data.get('admin_type')}`, { replace: true });
      dispatch(setStateAdminToSuccess(result));
    } else {
      dispatch(setStateAdminToFailed());
    }
  };
};
