/* eslint-disable */
import { useEffect, useState } from 'react';
import { server } from '../constants';
import { useRecoilState, atom } from 'recoil';

export { useQuotation };

const baseApi = 'Quotation';

const statusAtom = atom({
  key: `${baseApi}statusAtom`,
  default: 'A'
});

const plantNoAtom = atom({
  key: `${baseApi}plantNoAtom`,
  default: ''
});

const pantryNoAtom = atom({
  key: `${baseApi}pantryNoAtom`,
  default: ''
});

const quotationNumberAtom = atom({
  key: `${baseApi}quotationNumberAtom`,
  default: ''
});

const quotationDateAtom = atom({
  key: `${baseApi}quotationDateAtom`,
  default: ''
});

const quotationPurchaseAtom = atom({
  key: `${baseApi}quotationPurchaseAtom`,
  default: ''
});

const quotationApproveAtom = atom({
  key: `${baseApi}quotationApproveAtom`,
  default: ''
});

const quotationApproveDateAtom = atom({
  key: `${baseApi}quotationApproveDateAtom`,
  default: ''
});

const quotationPriceAtom = atom({
  key: `${baseApi}quotationPriceAtom`,
  default: ''
});

const quotationDTAtom = atom({
  key: `${baseApi}quotationDTAtom`,
  default: {}
});

const isStateAtom = atom({
  key: `${baseApi}isStateAtom`,
  default: ''
});

const isPerStateAtom = atom({
  key: `${baseApi}isPerStateAtom`,
  default: ''
});

function useQuotation() {
  const [status, setStatus] = useRecoilState(statusAtom);
  const [plantNo, setPlantNo] = useRecoilState(plantNoAtom);
  const [pantryNo, setPantryNo] = useRecoilState(pantryNoAtom);
  const [quotationNumber, setQuotationNumber] = useRecoilState(quotationNumberAtom);
  const [quotationDate, setQuotationDate] = useRecoilState(quotationDateAtom);
  const [quotationPurchase, setQuotationPurchase] = useRecoilState(quotationPurchaseAtom);
  const [quotationApprove, setQuotationApprove] = useRecoilState(quotationApproveAtom);
  const [quotationApproveDate, setQuotationApproveDate] = useRecoilState(quotationApproveDateAtom);
  const [quotationPrice, setQuotationPrice] = useRecoilState(quotationPriceAtom);
  const [quotationDT, setQuotationDT] = useRecoilState(quotationDTAtom);
  const [isState, setIsState] = useRecoilState(isStateAtom);
  const [isPerState, setIsPerState] = useRecoilState(isPerStateAtom);

  const [loading, setLoading] = useState(true);

  return {
    status,
    setStatus,
    plantNo,
    setPlantNo,
    pantryNo,
    setPantryNo,
    quotationNumber,
    setQuotationNumber,
    quotationDate,
    setQuotationDate,
    quotationPurchase,
    setQuotationPurchase,
    quotationApprove,
    setQuotationApprove,
    quotationApproveDate,
    setQuotationApproveDate,
    quotationPrice,
    setQuotationPrice,
    quotationDT,
    setQuotationDT,
    loading,
    setLoading,

    isState,
    setIsState,
    isPerState,
    setIsPerState,

    emptyData
  };

  async function emptyData() {
    // console.log(localStorage.getItem(server.LOGIN_USERNAME));
    setStatus('A');
    setPlantNo('');
    setPantryNo('');
    setQuotationNumber('');
    setQuotationDate(new Date().toJSON());
    setQuotationPurchase(localStorage.getItem(server.LOGIN_USERNAME));
    // setOrderApproveBy('');
    // setOrderApproveDate('');
    setQuotationDT([]);
  }
}
