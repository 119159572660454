/* eslint-disable */
import { filter } from 'lodash';
import { Icon } from '@iconify/react';
import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import keyboardreturn from '@iconify/icons-ic/keyboard-return';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../actions/order.action';
import * as PlantAction from '../actions/plant.action';
import * as PantryAction from '../actions/pantry.action';
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Box,
  Grid
} from '@mui/material';
// components
import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import {
  CheckOrderMoreMenu,
  CheckOrderListToolbar,
  CheckOrderListHead
} from '../components/_dashboard/checkorder';

import { fDate, fDateTime } from '../utils/formatTime';
//
import CheckorderList from '../_mocks_/checkorder';

import { orderStatus, server } from '../constants';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'plant_no', label: 'พื้นที่', alignRight: false },
  { id: 'pantry_no', label: 'ตึก', alignRight: false },
  { id: 'order_number', label: 'เลขที่เอกสาร', alignRight: false },
  { id: 'order_date', label: 'วันที่เอกสาร', alignRight: false },
  { id: 'order_by', label: 'ผู้สั่งสินค้า', alignRight: false },
  { id: 'order_verify_by', label: 'ผู้ตรวจสอบสั่งสินค้า', alignRight: false },
  { id: 'order_verify_date', label: 'วันที่ตรวจสอบสั่งสินค้า', alignRight: false },
  { id: 'status', label: 'สถานะ', alignRight: false },
  { id: '' }
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query, plant, pantry, status) {
  if (Array.isArray(array)) {
    let dataFilter = [...array];

    if (query || plant || pantry || status) {
      dataFilter = filter(
        dataFilter,
        (_checkorder) =>
          _checkorder.order_number.toLowerCase().indexOf(query.toLowerCase()) !== -1 &&
          (_checkorder.plant_no.toLowerCase() == plant.toLowerCase() || plant == '') &&
          (_checkorder.pantry_no.toLowerCase() == pantry.toLowerCase() ||
            pantry == '' ||
            pantry == '%') &&
          (_checkorder.status.toLowerCase() == status.toLowerCase() ||
            status == '' ||
            status == '%')
      );
    }

    const stabilizedThis = dataFilter.map((el, index) => [el, index]);

    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });

    return stabilizedThis.map((el) => el[0]);
  } else return [];
}

export function Checkorder(props) {
  const navigate = useNavigate();

  const pPlant = useParams().plant;
  const pOrder = useParams().order;
  const pStatus = useParams().status;

  const [orderDT, setOrderDT] = useState([]);
  const [plantList, setPlantList] = useState(null);
  const [pantryList, setPantryList] = useState([]);
  const [orderList, setOrderList] = useState([]);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [plantfilterName, setPlantFilterName] = useState(pPlant);
  const [pantryfilterName, setPantryFilterName] = useState('');
  const [statusfilterName, setStatusFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(
    parseInt(localStorage.getItem(server.ROWS_PER_PAGE))
  );

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = orderList.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    localStorage.setItem(server.ROWS_PER_PAGE, event.target.value);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const handleplantFilterByName = (event) => {
    setPlantFilterName(event.target.value);
    setPantryFilterName('');
    setStatusFilterName('');
  };

  const handlepantryFilterByName = (event) => {
    setPantryFilterName(event.target.value);
  };

  const handlestatusFilterByName = (event) => {
    setStatusFilterName(event.target.value);
  };

  useEffect(() => {
    props.getPlants();
  }, []);

  useEffect(() => {
    props.getPantryByPlantNo(plantfilterName);
  }, [plantfilterName]);

  useEffect(() => {
    const { result } = props.plantReducer;
    if (result) setPlantList(result);
  }, [props.plantReducer]);

  useEffect(() => {
    const { result } = props.pantryReducer;
    if (result && plantfilterName != '') setPantryList(result);
  }, [props.pantryReducer]);

  useEffect(() => {
    props.getOrdersConfirm(pPlant, pOrder, pStatus);
  }, []);

  useEffect(() => {
    const { result, isPage } = props.orderReducer;

    if (result && isPage == 'CONFIRM') setOrderList(result);
  }, [props.orderReducer]);

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - orderList.length) : 0;

  const filteredUsers = applySortFilter(
    orderList,
    getComparator(order, orderBy),
    filterName,
    plantfilterName,
    pantryfilterName,
    statusfilterName
  );

  const isUserNotFound = filteredUsers.length === 0;

  return (
    <Page title="Checkorder | JConnect">
      <Container maxWidth="false">
        <Stack mb={5}>
          <Grid container>
            <Grid item lg={4} md={4} xs={6}>
              <Typography variant="h4" gutterBottom>
                รับใบสั่งสินค้า
              </Typography>
            </Grid>
            <Grid item lg={4} md={4} xs={0}></Grid>
            <Grid item container lg={4} md={4} xs={6} spacing={2}>
              <Grid item lg={6} md={6} xs={12}>
                {/* <Button
                  variant="contained"
                  sx={{ width: '100%' }}
                  onClick={() => {
                    orderList.map((row) => {
                      const { id, status } = row;

                      if (status == 'V') {
                        let data = new FormData();
                        data.append('status', 'D');
                        data.append('id', id);
                        props.putCheckOrder(data);
                      }
                    });
                  }}
                >
                  พิมพ์คำสั่งสินค้า
                </Button> */}
              </Grid>
              {/* <Grid item lg={4} md={8} xs={12}>
                <Button variant="contained" component={RouterLink} to="#" sx={{ width: '100%' }}>
                  ยืนยันคำสั่งสินค้า
                </Button>
              </Grid> */}
              <Grid item lg={6} md={6} xs={12}>
                <Button
                  variant="contained"
                  component={RouterLink}
                  to="/dashboard/checkorderby"
                  startIcon={<Icon icon={keyboardreturn} />}
                  sx={{ width: '100%' }}
                >
                  ย้อนกลับ
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Stack>

        <Card>
          {orderList && plantList && pantryList && (
            <>
              <CheckOrderListToolbar
                numSelected={selected.length}
                filterName={filterName}
                onFilterName={handleFilterByName}
                plantfilterName={plantfilterName}
                plantonFilterName={handleplantFilterByName}
                pantryfilterName={pantryfilterName}
                pantryonFilterName={handlepantryFilterByName}
                plant={plantList}
                pantry={pantryList}
                statusfilterName={statusfilterName}
                statusonFilterName={handlestatusFilterByName}
              />

              <Scrollbar>
                <TableContainer sx={{ minWidth: 1100 }}>
                  <Table>
                    <CheckOrderListHead
                      order={order}
                      orderBy={orderBy}
                      headLabel={TABLE_HEAD}
                      rowCount={orderList.length}
                      numSelected={selected.length}
                      onRequestSort={handleRequestSort}
                      onSelectAllClick={handleSelectAllClick}
                    />
                    <TableBody>
                      {filteredUsers
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row) => {
                          const {
                            id,
                            plant_no,
                            pantry_no,
                            order_number,
                            order_date,
                            order_by,
                            order_verify_by,
                            order_verify_date,
                            status
                          } = row;
                          const isItemSelected = selected.indexOf(id) !== -1;

                          return (
                            <TableRow
                              hover
                              key={id}
                              tabIndex={-1}
                              role="checkbox"
                              selected={isItemSelected}
                              aria-checked={isItemSelected}
                            >
                              {/* <TableCell padding="checkbox">
                                <Checkbox
                                  checked={isItemSelected}
                                  onChange={(event) => handleClick(event, id)}
                                />
                              </TableCell> */}
                              <TableCell align="left">{plant_no}</TableCell>
                              <TableCell align="left">{pantry_no}</TableCell>
                              <TableCell align="left">{order_number}</TableCell>
                              <TableCell align="left">{fDate(order_date)}</TableCell>
                              <TableCell align="left">{order_by}</TableCell>
                              <TableCell align="left">{order_verify_by}</TableCell>
                              <TableCell align="left">{fDateTime(order_verify_date)}</TableCell>
                              <TableCell align="left">
                                {
                                  orderStatus[orderStatus.findIndex((obj) => obj.value == status)]
                                    .label
                                }
                              </TableCell>

                              <TableCell align="right">
                                <CheckOrderMoreMenu
                                  props={props}
                                  id={id}
                                  status={status}
                                  pPlant={pPlant}
                                  pOrder={pOrder}
                                  pStatus={pStatus}
                                />
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>
                    {isUserNotFound && (
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                            <SearchNotFound searchQuery={filterName} />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
              </Scrollbar>

              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={filteredUsers.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </>
          )}
        </Card>
      </Container>
    </Page>
  );
}

const mapStateToProps = ({ orderReducer, pantryReducer, plantReducer }) => ({
  orderReducer,
  pantryReducer,
  plantReducer
});

const mapDispatchToProps = {
  ...actions,
  ...PlantAction,
  ...PantryAction
};

export default connect(mapStateToProps, mapDispatchToProps)(Checkorder);
