/* eslint-disable */
import { Icon } from '@iconify/react';
import LocalGroceryStoreIcon from '@mui/icons-material/LocalGroceryStore';
import VerifiedIcon from '@mui/icons-material/Verified';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import AssignmentIcon from '@mui/icons-material/Assignment';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import StorageIcon from '@mui/icons-material/Storage';
import SummarizeIcon from '@mui/icons-material/Summarize';
import FeedIcon from '@mui/icons-material/Feed';
import SellIcon from '@mui/icons-material/Sell';
import PersonIcon from '@mui/icons-material/Person';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import LocalMallIcon from '@mui/icons-material/LocalMall';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import AddToDriveOutlinedIcon from '@mui/icons-material/AddToDriveOutlined';
import userManual from '@iconify/icons-arcticons/user-manual';
// ----------------------------------------------------------------------

// const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

const sidebarConfig = [
  // {
  //   title: 'dashboard',
  //   path: '/dashboard/app',
  //   icon: getIcon(pieChart2Fill)
  // },
  // {
  //   title: 'home',
  //   path: '/dashboard/user',
  //   icon: <HomeIcon />
  // },
  // {
  //   title: 'product',
  //   path: '/dashboard/products',
  //   icon: getIcon(shoppingBagFill)
  // },
  // {
  //   title: 'blog',
  //   path: '/dashboard/blog',
  //   icon: getIcon(fileTextFill)
  // },

  // {
  //   title: 'login',
  //   path: '/login',
  //   icon: getIcon(lockFill)
  // },
  // {
  //   title: 'register',
  //   path: '/register',
  //   icon: getIcon(personAddFill)
  // },
  {
    title: 'ใบสั่งสินค้า',
    path: '/dashboard/order',
    icon: <LocalGroceryStoreIcon />,
    name: 'order'
  },
  {
    title: 'อนุมัติสั่งสินค้า',
    path: '/dashboard/approveby',
    icon: <VerifiedIcon />,
    name: 'order_approve'
  },
  {
    title: 'รับใบสั่งสินค้า',
    path: '/dashboard/checkorderby',
    icon: <FactCheckIcon />,
    name: 'order_verify'
  },
  {
    title: 'ใบเสนอราคา',
    path: '/dashboard/quotation',
    icon: <FeedIcon />,
    name: 'quotation'
  },
  // {
  //   title: 'ใบส่งสินค้า',
  //   path: '/dashboard/deliver',
  //   icon: <LocalShippingIcon />,
  //   name: 'delivery_order'
  // },
  {
    title: 'ตรวจรับสินค้า',
    path: '/dashboard/checkproductby',
    icon: <SummarizeIcon />,
    name: 'goods_received'
  },
  {
    title: 'สินค้าคงเหลือ',
    path: '/dashboard/stock',
    icon: <LocalMallIcon />,
    name: 'inventory_balance'
  },
  {
    title: 'รายชื่อผู้ติดต่อ',
    path: '/dashboard/contact',
    icon: <ContactPhoneIcon />,
    name: 'contact'
  },
  {
    title: '-'
  },
  {
    title: 'รายการวางบิล',
    path: '/dashboard/reportbilling',
    icon: <AssignmentIcon />,
    name: 'report_billing'
  },
  {
    title: 'รายงานสรุป',
    path: '/dashboard/reportsummary',
    icon: <AssignmentIcon />,
    name: 'report_summary'
  },
  {
    title: 'รายงาน',
    path: '/dashboard/reportorder',
    icon: <AssignmentIcon />,
    name: 'report_order'
  },
  {
    title: '-'
  },
  {
    title: 'พื้นที่',
    path: '/dashboard/plant',
    icon: <CorporateFareIcon />,
    name: 'plant'
  },
  {
    title: 'ตึก',
    path: '/dashboard/pantry',
    icon: <StorageIcon />,
    name: 'pantry'
  },
  {
    title: 'กำหนดผู้ใช้งาน GC',
    path: '/dashboard/admin/GC',
    icon: <PersonIcon />,
    name: 'user_gc'
  },
  {
    title: 'กำหนดผู้ใช้งาน',
    path: '/dashboard/admin/JD',
    icon: <PersonIcon />,
    name: 'user'
  },
  {
    title: 'กำหนดระดับสิทธิ',
    path: '/dashboard/usergroup',
    icon: <AdminPanelSettingsIcon />,
    name: 'user_group'
  },
  {
    title: 'รายการสินค้า',
    path: '/dashboard/product/show',
    icon: <SellIcon />,
    name: 'product'
  },
  {
    title: 'กำหนดสินค้า',
    path: '/dashboard/product/edit',
    icon: <SellIcon />,
    name: 'product_edit'
  },
  {
    title: 'กำหนดข่าวสาร',
    path: '/dashboard/newpost/edit',
    icon: <SellIcon />,
    name: 'newpost_edit'
  },
  {
    title: '-'
  },
  {
    title: 'Reset Data',
    path: '/reset',
    icon: <RestartAltIcon />,
    name: 'reset_data'
  },
  {
    title: 'Recovery Data',
    path: '/recovery',
    icon: <AddToDriveOutlinedIcon />,
    name: 'recovery_data'
  },
  {
    title: '-'
  },
  {
    title: 'คู่มือการใช้งาน',
    path: '/manual_user',
    icon: <Icon icon={userManual} width={24} height={24} />,
    name: 'manual_user'
  },
  {
    title: 'คู่มือการใช้งาน JD',
    path: '/manual_admin',
    icon: <Icon icon={userManual} width={24} height={24} />,
    name: 'manual_admin'
  }
];

export default sidebarConfig;
