/* eslint-disable */
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';

import { connect } from 'react-redux';
import * as actions from '../actions/usergroup.action';

import {
  Card,
  TextField,
  Button,
  Checkbox,
  Container,
  Typography,
  Grid,
  Divider,
  FormControlLabel,
  MenuItem,
  Stack
} from '@mui/material';

import Page from '../components/Page';
import Swal from 'sweetalert2';
import sidebarConfig from '../layouts/dashboard/SidebarConfig';
import { selShowPrice, selType } from '../constants';

export function UserGroupProduct(props) {
  const [usergroupList, setUserGroupList] = useState(null);
  const navigate = useNavigate();
  const aStatus = [
    {
      value: 'A',
      label: 'ใช้งาน '
    },
    {
      value: 'I',
      label: ' ไม่ใช้งาน'
    }
  ];

  const [status, setStatus] = useState('A');
  const [permissionCode, setPermissionCode] = useState('');
  const [permissionName, setPermissionName] = useState('');
  const [menuList, setMenuList] = useState([]);
  const [showPrice, setShowPrice] = useState('I');
  const [type, setType] = useState('J');

  const { id } = useParams();

  if (id) {
    // alert(id);

    useEffect(() => {
      props.getUsergroupById(id);
    }, []);

    useEffect(() => {
      const { result } = props.usergroupReducer;

      if (result) {
        setPermissionCode(result.permission_code);
        setPermissionName(result.permission_name);
        if (result.permission_menu) setMenuList(result.permission_menu.split(','));
        setStatus(result.status);
        setShowPrice(result.permission_show_price);
        setType(result.permission_type);
      }
    }, [props.usergroupReducer]);
  }

  const handleChangeStatus = (event) => {
    setStatus(event.target.value);
  };

  const handleChangeShowPrice = (event) => {
    setShowPrice(event.target.value);
  };

  const handleChangeType = (event) => {
    setType(event.target.value);
  };

  const handleChangePermissionCode = (event) => {
    setPermissionCode(event.target.value);
  };

  const handleChangePermissionName = (event) => {
    setPermissionName(event.target.value);
  };

  const handleChangeMenu = (event) => {
    typeof menuList === 'string' ? menuList.split(',') : menuList;

    if (event.target.checked) {
      setMenuList([...menuList, event.target.name]);
    } else {
      console.log(menuList.indexOf(event.target.name));
      setMenuList(menuList.filter((t) => t !== event.target.name));
    }

    // menuList.forEach(function (item, index, object) {
    //   if (sidebarConfig.findIndex((sc) => sc.name === item) == -1) {
    //     object.splice(index, 1);
    //   }
    // });
  };

  return (
    <Page title="UserGroup| JConnect">
      <Container>
        <Card>
          <Grid container spacing={2} sx={{ p: 3 }}>
            <Grid item container spacing={2} lg={12} md={12} xs={12}>
              <Grid item lg={12} md={12} xs={12}>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  sx={{ mb: 1 }}
                >
                  <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                    เพิ่มระดับสิทธิ
                  </Typography>
                </Stack>
              </Grid>
              <Grid item lg={12} md={12} xs={12}>
                <Divider />
              </Grid>
            </Grid>

            <Grid item container spacing={2} lg={12} md={12} xs={12}>
              <Grid item lg={2} md={2} xs={12}>
                <TextField
                  id="permissionCode"
                  label="รหัสระดับสิทธิ"
                  sx={{ width: '100%' }}
                  value={permissionCode}
                  onChange={handleChangePermissionCode}
                ></TextField>
              </Grid>
              <Grid item lg={4} md={4} xs={12}>
                <TextField
                  id="permissionName"
                  label="ชื่อระดับสิทธิ"
                  sx={{ width: '100%' }}
                  value={permissionName}
                  onChange={handleChangePermissionName}
                ></TextField>
              </Grid>
              <Grid item lg={2} md={2} xs={12}>
                <TextField
                  id="outlined-select-currency"
                  select
                  label="ประเภท"
                  value={type}
                  onChange={handleChangeType}
                  sx={{ width: '100%' }}
                >
                  {selType.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item lg={2} md={2} xs={12}>
                <TextField
                  id="outlined-select-currency"
                  select
                  label="ราคา"
                  value={showPrice}
                  onChange={handleChangeShowPrice}
                  sx={{ width: '100%' }}
                >
                  {selShowPrice.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item lg={2} md={2} xs={12}>
                <TextField
                  id="outlined-select-currency"
                  select
                  label="สถานะ"
                  value={status}
                  onChange={handleChangeStatus}
                  sx={{ width: '100%' }}
                >
                  {aStatus.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              <Grid item lg={12} md={12} xs={12}>
                <Divider />
              </Grid>
            </Grid>

            <Grid item container spacing={2} lg={12} md={12} xs={12}>
              <Grid item lg={12} md={12} xs={12}>
                <Typography variant="p" component="p" sx={{ flexGrow: 1 }}>
                  เมนูอนุญาตใช้งาน
                </Typography>
              </Grid>

              {sidebarConfig.map((menu) =>
                menu.title == '-' ? (
                  <Grid item lg={12} md={12} xs={12}>
                    <Divider />
                  </Grid>
                ) : (
                  <Grid item lg={4} md={4} xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={menuList.indexOf(menu.name) > -1}
                          onChange={handleChangeMenu}
                          name={menu.name}
                        />
                      }
                      label={menu.title}
                      sx={{ mt: -1 }}
                    />
                  </Grid>
                )
              )}

              {/* <Grid item container lg={12} md={12} xs={12}>
                <Grid item lg={4} md={4} xs={12}>
                  <FormControlLabel
                    control={<Checkbox checked={oder} onChange={Change} name="oder" />}
                    label="ใบสั่งสินค้า"
                    sx={{ mt: -1 }}
                  />
                </Grid>
                <Grid item lg={4} md={4} xs={12}>
                  <FormControlLabel
                    control={<Checkbox checked={approve} onChange={Change} name="approve" />}
                    label="อนุมัติสินค้า"
                  />
                </Grid>
                <Grid item lg={4} md={4} xs={12}>
                  <FormControlLabel
                    control={<Checkbox checked={product} onChange={Change} name="product" />}
                    label="ตรวจสอบสั่งสินค้า"
                  />
                </Grid>
                <Grid item lg={4} md={4} xs={12}>
                  <FormControlLabel
                    control={<Checkbox checked={check} onChange={Change} name="check" />}
                    label="ใบเสนอราคา"
                  />
                </Grid>
                <Grid item lg={4} md={4} xs={12}>
                  <FormControlLabel
                    control={<Checkbox checked={deliver} onChange={Change} name="deliver" />}
                    label="ใบส่งสินค้า"
                  />
                </Grid>
              </Grid>

              <Grid item lg={12} md={12} xs={12}>
                <Divider />
              </Grid>

              <Grid item container lg={12} md={12} xs={12}>
                <Grid item lg={4} md={4} xs={12}>
                  <FormControlLabel
                    control={<Checkbox checked={budget} onChange={Change} name="budget" />}
                    label="รายงานงบประมาณ"
                  />
                </Grid>
                <Grid item lg={4} md={4} xs={12}>
                  <FormControlLabel
                    control={<Checkbox checked={report} onChange={Change} name="report" />}
                    label="รายงานสั่งสินค้า"
                  />
                </Grid>
                <Grid item lg={4} md={4} xs={12}>
                  <FormControlLabel
                    control={<Checkbox checked={backlog} onChange={Change} name="backlog" />}
                    label="รายงานค้างส่งส่งสินค้า"
                  />
                </Grid>
              </Grid>

              <Grid item lg={12} md={12} xs={12}>
                <Divider />
              </Grid>

              <Grid item container lg={12} md={12} xs={12}>
                <Grid item lg={4} md={4} xs={12}>
                  <FormControlLabel
                    control={<Checkbox checked={plant} onChange={Change} name="plant" />}
                    label="กำหนด Plant"
                  />
                </Grid>
                <Grid item lg={4} md={4} xs={12}>
                  <FormControlLabel
                    control={<Checkbox checked={pantry} onChange={Change} name="pantry" />}
                    label="กำหนด Pantry"
                  />
                </Grid>
                <Grid item lg={4} md={4} xs={12}>
                  <FormControlLabel
                    control={<Checkbox checked={admin} onChange={Change} name="admin" />}
                    label="กำหนดผู้ใช้งาน"
                  />
                </Grid>
                <Grid item lg={4} md={4} xs={12}>
                  <FormControlLabel
                    control={<Checkbox checked={user} onChange={Change} name="user" />}
                    label="กำหนดระดับสิทธิ"
                  />
                </Grid>
                <Grid item lg={4} md={4} xs={12}>
                  <FormControlLabel
                    control={<Checkbox checked={price} onChange={Change} name="price" />}
                    label="กำหนดสินค้าและราคา"
                  />
                </Grid>
              </Grid> */}
              <Grid item lg={12} md={12} xs={12}>
                <Divider />
              </Grid>
            </Grid>

            <Grid
              item
              container
              spacing={2}
              lg={12}
              md={12}
              xs={12}
              sx={{
                display: 'flex',
                flexDirection: 'row-reverse'
              }}
            >
              <Button
                variant="contained"
                component={RouterLink}
                to="/dashboard/usergroup"
                sx={{ mt: 2 }}
              >
                ยกเลิก
              </Button>
              <Button
                variant="contained"
                sx={{ mr: 2, mt: 2 }}
                onClick={() => {
                  let data = new FormData();
                  data.append('permission_code', permissionCode);
                  data.append('permission_name', permissionName);
                  data.append('permission_menu', menuList);
                  data.append('status', status);
                  data.append('permission_show_price', showPrice);
                  data.append('permission_type', type);

                  if (id) {
                    data.append('id', id);
                    props.putUsergroups(navigate, data);
                  } else {
                    props.postUsergroups(navigate, data);
                  }

                  // const postplant = props.postPlants(navigate, data);
                  // postplant(dispatch);

                  Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: 'Save',
                    showConfirmButton: false,
                    timer: 1500
                  });
                }}
              >
                บันทึก
              </Button>
            </Grid>
          </Grid>
        </Card>
      </Container>
    </Page>
  );
}

const mapStateToProps = ({ usergroupReducer }) => ({ usergroupReducer });

const mapDispatchToProps = {
  ...actions
};

export default connect(mapStateToProps, mapDispatchToProps)(UserGroupProduct);
