/* eslint-disable */
import * as React from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { filter } from 'lodash';
import { Icon } from '@iconify/react';
import { useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../actions/order.action';
import * as PlantAction from '../actions/plant.action';
import * as PantryAction from '../actions/pantry.action';
import {
  Box,
  Table,
  Grid,
  Card,
  Paper,
  Button,
  Checkbox,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Toolbar,
  TextField,
  MenuItem,
  Stack
} from '@mui/material';
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import { UserListHead, UserMoreMenu } from '../components/_dashboard/user';
// import OrderProductList from '../_mocks_/orderproduct';
import { OrderProductListHead } from '../components/_dashboard/orderproduct';

import Swal from 'sweetalert2';

import { useOrder } from '../hooks/useOrder';

import { isArray, isObject } from 'lodash';
import { server } from '../constants';
import { useField } from 'formik';
import { fCurrency } from '../utils/formatNumber';
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'product_name', label: 'รายการสินค้า', alignRight: false },
  { id: 'product_unit', label: 'หน่วย', alignRight: false },
  { id: 'product_price', label: 'ราคา/หน่วย', alignRight: false },
  { id: 'qty', label: 'จำนวน', alignRight: false },
  { id: 'product_total', label: 'รวม', alignRight: false }
  // { id: '' }
];

const TABLE_HEAD_NO_PRICE = [
  { id: 'product_name', label: 'รายการสินค้า', alignRight: false },
  { id: 'product_unit', label: 'หน่วย', alignRight: false },
  { id: 'qty', label: 'จำนวน', alignRight: false }
  // { id: '' }
];

// ----------------------------------------------------------------------

// function descendingComparator(a, b, orderBy) {
//   if (b[orderBy] < a[orderBy]) {
//     return -1;
//   }
//   if (b[orderBy] > a[orderBy]) {
//     return 1;
//   }
//   return 0;
// }

// function getComparator(order, orderBy) {
//   return order === 'desc'
//     ? (a, b) => descendingComparator(a, b, orderBy)
//     : (a, b) => -descendingComparator(a, b, orderBy);
// }

// function applySortFilter(array, comparator, query) {
//   const stabilizedThis = dataFilter.map((el, index) => [el, index]);
//   stabilizedThis.sort((a, b) => {
//     const order = comparator(a[0], b[0]);
//     if (order !== 0) return order;
//     return a[1] - b[1];
//   });
//   if (query) {
//     dataFilter = filter(dataFilter, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
//   }
//   return stabilizedThis.map((el) => el[0]);
// }

export function OrderProduct(props) {
  const pageDatas = useOrder();
  const [plantList, setPlantList] = useState(null);
  const [pantryList, setPantryList] = useState(null);
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(
    parseInt(localStorage.getItem(server.ROWS_PER_PAGE))
  );
  const [memo, setMemo] = useState('');

  const showPlant = localStorage.getItem(server.LOGIN_PLANT);
  const showPantry = localStorage.getItem(server.LOGIN_PANTRY);

  useEffect(() => {
    props.getPlants();
  }, []);

  useEffect(() => {
    props.getPantryByPlantNo(pageDatas.plantNo);
  }, [pageDatas.plantNo]);

  useEffect(() => {
    const { result } = props.plantReducer;
    if (result) setPlantList(result);
  }, [props.plantReducer]);

  useEffect(() => {
    const { result } = props.pantryReducer;
    if (result) setPantryList(result);
  }, [props.pantryReducer]);

  const { id } = useParams();

  // console.log(pageDatas.isState);
  // if (id && pageDatas.isState == 'E') {
  // alert(id);

  // pageDatas.editData(props);

  // useEffect(() => {
  //   props.getOrderById(id);
  // }, []);

  useEffect(() => {
    if (id && pageDatas.isState == 'E' && pageDatas.isPerState == '') {
      const { result } = props.orderReducer;

      if (result) {
        pageDatas.setPlantNo(result.plant_no);
        pageDatas.setPantryNo(result.pantry_no);
        pageDatas.setOrderNumber(result.order_number);
        pageDatas.setOrderDate(result.order_date);
        pageDatas.setOrderBys(result.order_by);
        setMemo(result.order_memo);
        // setOrderApproveBy(result.order_approve_by);
        // setOrderApproveDate(result.order_approve_date);
        pageDatas.setStatus(result.status);
        pageDatas.setLoading(false);
      }
    }
  }, [props.orderReducer]);

  // useEffect(() => {
  //   props.getOrderDTById(id);
  // }, []);

  useEffect(() => {
    if (id && pageDatas.isState == 'E' && pageDatas.isPerState == '') {
      const { result } = props.orderdtReducer;

      if (result && isArray(result)) {
        pageDatas.setOrderDT(result);
      }
    }
  }, [props.orderdtReducer]);

  // } else

  // if (pageDatas.isState == 'A') {
  //   pageDatas.emptyData;
  // }

  useEffect(() => {
    if (id && pageDatas.isState == 'E' && pageDatas.isPerState == '') {
      // console.log('E');
      props.getOrderById(id);
    } else if (pageDatas.isState == 'A' && pageDatas.isPerState == '') {
      // console.log('A');
      pageDatas.emptyData();
    }
  }, [pageDatas.isState]);

  useEffect(() => {
    if (id && pageDatas.isState == 'E' && pageDatas.isPerState == '') {
      // console.log('E');
      props.getOrderDTById(id);
    }
  }, [pageDatas.isState]);

  pageDatas.setIsPerState('');

  // useEffect(() => {
  //   console.log(props);
  // }, [props]);

  const handleChangePlantNo = (event) => {
    pageDatas.setPlantNo(event.target.value);
    pageDatas.setPantryNo('');
  };

  const handleChangePantryNo = (event) => {
    pageDatas.setPantryNo(event.target.value);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = OrderProductList.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    localStorage.setItem(server.ROWS_PER_PAGE, event.target.value);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const handleChangeMemo = (event) => {
    setMemo(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - pageDatas.orderDT.length) : 0;

  // const filteredUsers = applySortFilter(
  //   pageDatas.orderDT,
  //   getComparator(order, orderBy),
  //   filterName
  // );

  console.log(pageDatas);

  return (
    <Page title="Order | JConnect">
      <Container maxWidth="false">
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            เพิ่มใบสั่งสินค้า
          </Typography>
          {pageDatas.status == 'A' && (
            <Button
              variant="contained"
              // component={RouterLink}
              // to="/dashboard/orderpopup"
              startIcon={<Icon icon={plusFill} />}
              onClick={() => {
                if (pageDatas.plantNo != '' && pageDatas.pantryNo != '') {
                  pageDatas.setIsPerState(id);
                  // pageDatas.setIsState('P');
                  navigate('/dashboard/orderpopup', { replace: true });
                }
              }}
            >
              เพิ่มและแก้ไขสินค้า
            </Button>
          )}
        </Stack>

        <Grid container spacing={2} sx={{ mt: 3 }}>
          <Grid item lg={4} md={6} xs={12} container spacing={2}>
            <Grid item lg={12} md={12} xs={12}>
              <Box
                sx={{
                  borderRadius: 2,
                  border: 1,
                  borderColor: 'text.primary',
                  p: 3
                }}
              >
                {/* {(pageDatas.plantNo || pageDatas.isState == 'A') && ( */}
                <TextField
                  id="outlined-select-currency"
                  label="พื้นที่"
                  select
                  sx={{ width: '100%', mt: 2, mb: 2 }}
                  value={pageDatas.plantNo}
                  onChange={handleChangePlantNo}
                  error={pageDatas.plantNo == ''}
                  helperText={pageDatas.plantNo == '' ? 'กรุณาเลือก พื้นที่' : ''}
                >
                  {plantList &&
                    plantList.map((option) => {
                      if (showPlant.indexOf(option.plant_no) >= 0 || showPlant.length === 0) {
                        return (
                          <MenuItem key={option.id} value={option.plant_no}>
                            {option.plant_no}
                          </MenuItem>
                        );
                      }
                    })}
                </TextField>
                {/* )} */}
                {/* {(pageDatas.pantryNo || pageDatas.isState == 'A') && ( */}
                <TextField
                  id="outlined-select-currency"
                  label="ตึก"
                  select
                  value={pageDatas.pantryNo}
                  onChange={handleChangePantryNo}
                  sx={{ width: '100%', mt: 2, mb: 2 }}
                  error={pageDatas.pantryNo == ''}
                  helperText={pageDatas.pantryNo == '' ? 'กรุณาเลือก ตึก' : ''}
                >
                  {pantryList &&
                    isArray(pantryList) &&
                    pantryList.map((option) => {
                      if (showPantry.length === 0 || showPantry.indexOf(option.pantry_no) >= 0)
                        return (
                          <MenuItem key={option.id} value={option.pantry_no}>
                            {option.pantry_no}
                          </MenuItem>
                        );
                    })}
                </TextField>
                {/* )} */}
              </Box>

              <Box
                sx={{
                  borderRadius: 2,
                  border: 1,
                  borderColor: 'text.primary',
                  p: 3,
                  mt: 2
                }}
              >
                <TextField
                  id="memo"
                  label="หมายเหตุ"
                  multiline
                  rows={4}
                  sx={{ width: '100%' }}
                  value={memo}
                  onChange={handleChangeMemo}
                />
              </Box>
            </Grid>

            {/* <Grid item lg={12} md={12} xs={12}>
              <Box
                sx={{
                  borderRadius: 2,
                  border: 1,
                  borderColor: 'text.primary',
                  p: 3
                }}
              >
                <TextField
                  id="memo"
                  label="หมายเหตุ"
                  multiline
                  rows={4}
                  sx={{ width: '100%' }}
                  value={memo}
                  onChange={handleChangeMemo}
                />
              </Box>
            </Grid> */}
          </Grid>

          <Grid item lg={8} md={6} xs={12}>
            <Box
              sx={{
                border: 1,
                borderRadius: 2,
                borderColor: 'text.primary',
                p: 3
              }}
            >
              {localStorage.getItem(server.LOGIN_SHOW_PRICE) === 'A' && (
                <Grid container spacing={2}>
                  <Grid item lg={6} md={6} xs={6}>
                    <TextField
                      type="text"
                      label="ยอดรวมรายการ :"
                      value={pageDatas.orderDT.length}
                      variant="outlined"
                      sx={{ width: '100%', mb: 2 }}
                      inputProps={{ readOnly: true }}
                    />
                  </Grid>

                  <Grid item lg={6} md={6} xs={6}>
                    <TextField
                      type="text"
                      label="ยอดรวมทั้งหมด :"
                      value={fCurrency(
                        isArray(pageDatas.orderDT) &&
                          pageDatas.orderDT.reduce(function (prev, current) {
                            return prev + +(current.product_price * current.qty);
                          }, 0)
                      )}
                      variant="outlined"
                      sx={{ width: '100%', mb: 2 }}
                      inputProps={{ readOnly: true }}
                    />
                  </Grid>
                </Grid>
              )}
              {pageDatas.orderDT && isArray(pageDatas.orderDT) && (
                <>
                  <Scrollbar>
                    <TableContainer sx={{ minWidth: 350 }}>
                      <Table>
                        <OrderProductListHead
                          order={order}
                          orderBy={orderBy}
                          headLabel={
                            localStorage.getItem(server.LOGIN_SHOW_PRICE) === 'A'
                              ? TABLE_HEAD
                              : TABLE_HEAD_NO_PRICE
                          }
                          rowCount={pageDatas.orderDT.length}
                          numSelected={selected.length}
                          onRequestSort={handleRequestSort}
                          onSelectAllClick={handleSelectAllClick}
                        />
                        <TableBody>
                          {pageDatas.orderDT // filteredUsers
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row) => {
                              const { id, product_name, product_unit, qty, product_price } = row;
                              const isItemSelected = selected.indexOf(id) !== -1;

                              return (
                                <TableRow
                                  hover
                                  key={id}
                                  tabIndex={-1}
                                  role="checkbox"
                                  selected={isItemSelected}
                                  aria-checked={isItemSelected}
                                >
                                  <TableCell align="left">{product_name}</TableCell>
                                  <TableCell align="left">{product_unit}</TableCell>
                                  {localStorage.getItem(server.LOGIN_SHOW_PRICE) === 'A' && (
                                    <TableCell align="left">{fCurrency(product_price)}</TableCell>
                                  )}
                                  <TableCell align="left">{qty}</TableCell>
                                  {localStorage.getItem(server.LOGIN_SHOW_PRICE) === 'A' && (
                                    <TableCell align="left">
                                      {fCurrency(product_price * qty)}
                                    </TableCell>
                                  )}
                                  {/* <TableCell align="right">
                                    <UserMoreMenu />
                                  </TableCell> */}
                                </TableRow>
                              );
                            })}
                          {emptyRows > 0 && (
                            <TableRow style={{ height: 53 * emptyRows }}>
                              <TableCell colSpan={6} />
                            </TableRow>
                          )}
                        </TableBody>
                        {/* {
                        (orderDT.length = 0 && (
                          <TableBody>
                            <TableRow>
                              <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                                Data Not Found
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        ))
                      } */}
                      </Table>
                    </TableContainer>
                  </Scrollbar>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={pageDatas.orderDT.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </>
              )}
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row-reverse',
                  //   p: 1,
                  mt: 2,
                  bgcolor: 'background.paper'
                }}
              >
                {pageDatas.status == 'A' && (
                  <>
                    <Button variant="contained" component={RouterLink} to="/dashboard/order">
                      ยกเลิก
                    </Button>
                    <Button
                      variant="contained"
                      sx={{ mr: 2 }}
                      onClick={() => {
                        if (pageDatas.plantNo != '' && pageDatas.pantryNo != '') {
                          let data = new FormData();
                          data.append('plant_no', pageDatas.plantNo);
                          data.append('pantry_no', pageDatas.pantryNo);
                          data.append('order_by', pageDatas.orderBys);
                          // data.append('order_approve_by', pageDatas.orderApproveBy);
                          // data.append('order_approve_date', pageDatas.orderApproveDate);
                          data.append('status', pageDatas.status);
                          data.append('order_memo', memo);

                          if (id) {
                            data.append('id', id);
                            props.putOrders(navigate, data, pageDatas.orderDT);
                          } else {
                            // data.append(
                            //   'order_number',
                            //   new Date().toJSON().slice(0, 7).split`-`.join`` + '001'
                            // );
                            // data.append('order_date', new Date().toJSON());
                            props.postOrders(navigate, data, pageDatas.orderDT);
                          }

                          // const postplant = props.postPlants(navigate, data);
                          // postplant(dispatch);

                          Swal.fire({
                            position: 'center',
                            icon: 'success',
                            title: 'Save',
                            showConfirmButton: false,
                            timer: 1500
                          });
                        }
                      }}
                    >
                      บันทึก
                    </Button>
                  </>
                )}
                {pageDatas.status !== 'A' && (
                  <Button variant="contained" component={RouterLink} to="/dashboard/order">
                    ปิด
                  </Button>
                )}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}

const mapStateToProps = ({ orderReducer, orderdtReducer, pantryReducer, plantReducer }) => ({
  orderReducer,
  orderdtReducer,
  pantryReducer,
  plantReducer
});

const mapDispatchToProps = {
  ...actions,
  ...PlantAction,
  ...PantryAction
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderProduct);
