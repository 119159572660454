/* eslint-disable */
import { useFormik } from 'formik';
import { useState, useEffect } from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { Icon } from '@iconify/react';
// material
import { Container, Stack, Typography, Button } from '@mui/material';
import plusFill from '@iconify/icons-eva/plus-fill';
import editFill from '@iconify/icons-eva/edit-fill';
import trash2Outline from '@iconify/icons-eva/trash-2-outline';

import { connect } from 'react-redux';
import * as actions from '../actions/product.action';
// components
import Page from '../components/Page';
import { Productlist } from '../components/_dashboard/Productlist';
//
// import PRODUCTS from '../_mocks_/productlist';

// material
import { Menu, MenuItem, IconButton, ListItemIcon, ListItemText } from '@mui/material';

import Swal from 'sweetalert2';
import { isArray } from 'lodash';

// ----------------------------------------------------------------------

export function Product(props, id) {
  const { type } = useParams();
  const [productList, setProductList] = useState([]);
  const [openFilter, setOpenFilter] = useState(false);

  const formik = useFormik({
    initialValues: {
      gender: '',
      category: '',
      colors: '',
      priceRange: '',
      rating: ''
    },
    onSubmit: () => {
      setOpenFilter(false);
    }
  });

  const { resetForm, handleSubmit } = formik;

  const handleOpenFilter = () => {
    setOpenFilter(true);
  };

  const handleCloseFilter = () => {
    setOpenFilter(false);
  };

  const handleResetFilter = () => {
    handleSubmit();
    resetForm();
  };

  async function fetchData() {
    await props.getProducts();
  }

  useEffect(() => {
    props.getProducts();
  }, []);

  useEffect(() => {
    const { result } = props.productReducer;

    if (result) setProductList(result);
  }, [props.productReducer]);

  return (
    <Page title="Product| JConnect">
      <Container maxWidth={false}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            รายการสินค้า
          </Typography>
          {type == 'edit' && (
            <Button
              variant="contained"
              component={RouterLink}
              to="/dashboard/createproduct"
              startIcon={<Icon icon={plusFill} />}
            >
              เพิ่มสินค้า
            </Button>
          )}
        </Stack>

        {/* <Stack
          direction="row"
          flexWrap="wrap-reverse"
          alignItems="center"
          justifyContent="flex-end"
          sx={{ mb: 5 }}
        >
          <Stack direction="row" spacing={1} flexShrink={0} sx={{ my: 1 }}>
            <ProductFilterSidebar
              formik={formik}
              isOpenFilter={openFilter}
              onResetFilter={handleResetFilter}
              onOpenFilter={handleOpenFilter}
              onCloseFilter={handleCloseFilter}
            />
            <ProductSort />
          </Stack>
        </Stack> */}
        {productList && isArray(productList) && (
          <>
            <Productlist products={productList} props={props} type={type} />
          </>
        )}
        {/* <ProductCartWidget /> */}
      </Container>
    </Page>
  );
}

const mapStateToProps = ({ productReducer }) => ({ productReducer });

const mapDispatchToProps = {
  ...actions
};

export default connect(mapStateToProps, mapDispatchToProps)(Product);
