/* eslint-disable */
import { useEffect, useState } from 'react';
import { Box, Grid, Container, Typography, Stack, Button } from '@mui/material';
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../actions/newpost.action';
// components
import Page from '../components/Page';
import {
  AppTasks,
  AppNewUsers,
  AppBugReports,
  AppItemOrders,
  AppNewsUpdate,
  AppWeeklySales,
  AppOrderTimeline,
  AppCurrentVisits,
  AppWebsiteVisits,
  AppTrafficBySite,
  AppCurrentSubject,
  AppConversionRates
} from '../components/_dashboard/app';
import { Postlist } from '../components/_dashboard/newpost';
import { isArray } from 'lodash';

// ----------------------------------------------------------------------

export function Newpost(props) {
  const { type } = useParams();
  const [newpostList, setNewpostList] = useState([]);

  useEffect(() => {
    props.getNewposts();
  }, []);

  useEffect(() => {
    const { result } = props.newpostReducer;

    if (result) {
      // console.log(result);
      setNewpostList(result);
    }
  }, [props.newpostReducer]);

  return (
    <Page title="JConnect">
      <Container maxWidth="flase">
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            ข่าวสาร
          </Typography>
          {type == 'edit' && (
            <Button
              variant="contained"
              component={RouterLink}
              to={`/dashboard/post/${type}`}
              startIcon={<Icon icon={plusFill} />}
            >
              New Post
            </Button>
          )}
        </Stack>

        {newpostList && isArray(newpostList) && (
          <>
            <Postlist news={newpostList} props={props} type={type} />
          </>
        )}
      </Container>
    </Page>
  );
}

const mapStateToProps = ({ newpostReducer }) => ({ newpostReducer });

const mapDispatchToProps = {
  ...actions
};

export default connect(mapStateToProps, mapDispatchToProps)(Newpost);
