/* eslint-disable */
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import trash2Outline from '@iconify/icons-eva/trash-2-outline';
import { Link as RouterLink } from 'react-router-dom';
import editFill from '@iconify/icons-eva/edit-fill';
// material
import { Grid } from '@mui/material';
import ProductlistCard from './ProductlistCard';
import { Menu, MenuItem, IconButton, ListItemIcon, ListItemText } from '@mui/material';

// ----------------------------------------------------------------------

Productlist.propTypes = {
  products: PropTypes.array.isRequired
};

export default function Productlist({ products, props, type, ...other }) {
  console.log(products);

  return (
    <Grid container spacing={3} {...other}>
      {products.map((product) => (
        <Grid key={product.id} item xs={12} sm={6} md={2}>
          <ProductlistCard product={product} props={props} type={type} />
        </Grid>
      ))}
    </Grid>
  );
}
