/* eslint-disable */
import {
  HTTP_LOGIN_FETCHING,
  HTTP_LOGIN_SUCCESS,
  HTTP_LOGIN_FAILED,
  OK,
  NO,
  YES,
  server,
  HTTP_LOGIN_CLEAR
} from '../constants';
import { httpClient } from '../utils/HttpClient';
import { getUsergroupByCode } from './usergroup.action';

export const setLoginStateToFetching = () => ({
  type: HTTP_LOGIN_FETCHING
});

export const setLoginStateToSuccess = (payload) => ({
  type: HTTP_LOGIN_SUCCESS,
  payload
});

export const setLoginStateToFailed = (payload) => ({
  type: HTTP_LOGIN_FAILED,
  payload
});

export const setLoginStateToClear = () => ({
  type: HTTP_LOGIN_CLEAR
});

export const autoLogin = (navigate) => {
  return () => {
    // if (localStorage.getItem(server.LOGIN_PASSED) == YES) {
    //   setTimeout(() => navigate('/dashboard/newpost', { replace: true }), 100);
    // }
  };
};

export const login = (navigate, credential) => {
  return async (dispatch) => {
    dispatch(setLoginStateToFetching());
    let result = await httpClient.post(server.LOGIN_URL, credential);
    // alert(JSON.stringify(credential));
    //   let result = await httpClient.post(server.LOGIN_URL, credential);
    //alert(JSON.stringify(result.data));
    if (result.data.result == OK) {
      // console.log(credential.username);
      localStorage.setItem(server.LOGIN_USERNAME, credential.username);
      // console.log(localStorage.getItem(server.LOGIN_USER));
      // console.log(JSON.parse(result.data.message).admin_permission);
      // getUsergroupByCode(JSON.parse(result.data.message).admin_permission);
      // localStorage.setItem(server.LOGIN_MENU, JSON.parse(result.data.message).admin_permission);
      localStorage.setItem(server.LOGIN_PASSED, YES);
      // getState().appReducer.app.forceUpdate();
      if (JSON.parse(result.data.message).plant_no) {
        localStorage.setItem(
          server.LOGIN_PLANT,
          JSON.parse(result.data.message).plant_no.split(',')
        );
      } else {
        localStorage.setItem(server.LOGIN_PLANT, []);
      }

      if (JSON.parse(result.data.message).pantry_no) {
        localStorage.setItem(
          server.LOGIN_PANTRY,
          JSON.parse(result.data.message).pantry_no.split(',')
        );
      } else {
        localStorage.setItem(server.LOGIN_PANTRY, []);
      }

      let resultG = await httpClient.get(
        `${server.USERGROUP_URL}/code/${JSON.parse(result.data.message).admin_permission}`
      );

      if (resultG) {
        localStorage.setItem(server.LOGIN_MENU, resultG.data.permission_menu);
        localStorage.setItem(server.LOGIN_SHOW_PRICE, resultG.data.permission_show_price);

        console.log(JSON.parse(result.data.message).admin_permission.toLowerCase());

        if (JSON.parse(result.data.message).admin_permission.toLowerCase() == 'driver') {
          navigate('/dashboard/checkproductby', { replace: true });
        } else {
          navigate('/dashboard/newpost', { replace: true });
        }
        dispatch(setLoginStateToSuccess(result));
      }
    } else {
      dispatch(setLoginStateToFailed(result));
    }
  };
};

export const logout = (navigate) => {
  return async (dispatch) => {
    dispatch(setLoginStateToClear());
    localStorage.setItem(server.LOGIN_PASSED, NO);
    navigate('/', { replace: true });
  };
};
