/* eslint-disable */
import { useEffect, useState } from 'react';
import { server } from '../constants';
import { useRecoilState, atom } from 'recoil';

export { useConfirm };

const baseApi = 'confirm';

const statusAtom = atom({
  key: `${baseApi}statusAtom`,
  default: 'A'
});

const plantNoAtom = atom({
  key: `${baseApi}plantNoAtom`,
  default: ''
});

const pantryNoAtom = atom({
  key: `${baseApi}pantryNoAtom`,
  default: ''
});

const orderNumberAtom = atom({
  key: `${baseApi}orderNumberAtom`,
  default: ''
});

const orderDateAtom = atom({
  key: `${baseApi}orderDateAtom`,
  default: ''
});

const orderBysAtom = atom({
  key: `${baseApi}orderBysAtom`,
  default: ''
});

const orderDTAtom = atom({
  key: `${baseApi}orderDTAtom`,
  default: {}
});

const deliverVerifyDateAtom = atom({
  key: `${baseApi}deliverVerifyDateAtom`,
  default: {}
});

const isStateAtom = atom({
  key: `${baseApi}isStateAtom`,
  default: ''
});

const isPerStateAtom = atom({
  key: `${baseApi}isPerStateAtom`,
  default: ''
});

function useConfirm() {
  const [status, setStatus] = useRecoilState(statusAtom);
  const [plantNo, setPlantNo] = useRecoilState(plantNoAtom);
  const [pantryNo, setPantryNo] = useRecoilState(pantryNoAtom);
  const [orderNumber, setOrderNumber] = useRecoilState(orderNumberAtom);
  const [orderDate, setOrderDate] = useRecoilState(orderDateAtom);
  const [orderBys, setOrderBys] = useRecoilState(orderBysAtom);
  const [orderDT, setOrderDT] = useRecoilState(orderDTAtom);
  const [DeliverVerifyDate, setDeliverVerifyDate] = useRecoilState(deliverVerifyDateAtom);

  const [isState, setIsState] = useRecoilState(isStateAtom);
  const [isPerState, setIsPerState] = useRecoilState(isPerStateAtom);

  const [loading, setLoading] = useState(true);

  return {
    status,
    setStatus,
    plantNo,
    setPlantNo,
    pantryNo,
    setPantryNo,
    orderNumber,
    setOrderNumber,
    orderDate,
    setOrderDate,
    orderBys,
    setOrderBys,
    // orderApproveBy,
    // setOrderApproveBy,
    // orderApproveDate,
    // setOrderApproveDate,
    orderDT,
    setOrderDT,
    DeliverVerifyDate,
    setDeliverVerifyDate,
    loading,
    setLoading,

    isState,
    setIsState,
    isPerState,
    setIsPerState
  };
}
