/* eslint-disable */
import { filter } from 'lodash';
import { Icon } from '@iconify/react';
import { useState, useEffect } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink } from 'react-router-dom';
// material
import {
  Card,
  Table,
  Stack,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination
} from '@mui/material';
import { connect } from 'react-redux';
import * as actions from '../actions/pantry.action';
import * as PlantAction from '../actions/plant.action';
// components
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import { PantryListToolbar, PantryListHead, PantryMoreMenu } from '../components/_dashboard/pantry';
import { server } from '../constants';
//
// import PantryList from '../_mocks_/pantry';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'plant_no', label: 'พื้นที่', alignRight: false },
  { id: 'pantry_no', label: 'ตึก', alignRight: false },
  { id: 'plant_details', label: 'รายละเอียด', alignRight: false },
  { id: 'status', label: 'สถานะ', alignRight: false },
  { id: '' }
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, pantry, plant) {
  if (Array.isArray(array)) {
    let dataFilter = [...array];

    if (pantry || plant) {
      dataFilter = filter(
        dataFilter,
        (_pantry) =>
          _pantry.pantry_no.toLowerCase().indexOf(pantry.toLowerCase()) !== -1 &&
          (_pantry.plant_no.toLowerCase() == plant.toLowerCase() || plant == '' || plant == '%')
      );
    }

    const stabilizedThis = dataFilter.map((el, index) => [el, index]);

    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });

    return stabilizedThis.map((el) => el[0]);
  } else return [];
}

export function Pantry(props) {
  const [plantList, setPlantList] = useState(null);
  const [pantryList, setPantryList] = useState([]);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [pantryfilterName, setpantryFilterName] = useState('');
  const [plantfilterName, setPlantFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(
    parseInt(localStorage.getItem(server.ROWS_PER_PAGE))
  );

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = filteredUsers.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    localStorage.setItem(server.ROWS_PER_PAGE, event.target.value);
  };

  const handlepantryFilterByName = (event) => {
    setpantryFilterName(event.target.value);
  };

  const handleplantFilterByName = (event) => {
    setPlantFilterName(event.target.value);
  };

  useEffect(() => {
    //fetchData();
    props.getPlants();
    props.getPantrys();
  }, []);

  useEffect(() => {
    const { result } = props.pantryReducer;
    if (result) setPantryList(result);
  }, [props.pantryReducer]);

  useEffect(() => {
    const { result } = props.plantReducer;
    // console.log(result);
    if (result) setPlantList(result);
  }, [props.plantReducer]);

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - pantryList.length) : 0;

  const filteredUsers =
    pantryList != null
      ? applySortFilter(
          pantryList,
          getComparator(order, orderBy),
          pantryfilterName,
          plantfilterName
        )
      : [];

  const isUserNotFound = filteredUsers.length === 0;

  return (
    <Page title="Pantry | JConnect">
      <Container maxWidth="false">
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            กำหนดตึก
          </Typography>
          <Button
            variant="contained"
            component={RouterLink}
            to="/dashboard/pantryproduct"
            startIcon={<Icon icon={plusFill} />}
          >
            เพิ่มตึก
          </Button>
        </Stack>

        <Card>
          {plantList && pantryList && (
            <>
              <PantryListToolbar
                numSelected={selected.length}
                pantryfilterName={pantryfilterName}
                pantryonFilterName={handlepantryFilterByName}
                plantfilterName={plantfilterName}
                plantonFilterName={handleplantFilterByName}
                plant={plantList}
                pantry={pantryList}
              />

              <Scrollbar>
                <TableContainer sx={{ minWidth: 450 }}>
                  <Table>
                    <PantryListHead
                      order={order}
                      orderBy={orderBy}
                      headLabel={TABLE_HEAD}
                      rowCount={filteredUsers.length}
                      numSelected={selected.length}
                      onRequestSort={handleRequestSort}
                      onSelectAllClick={handleSelectAllClick}
                    />
                    <TableBody>
                      {filteredUsers
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row) => {
                          const { id, plant_no, pantry_no, pantry_details, status } = row;
                          const isItemSelected = selected.indexOf(id) !== -1;

                          return (
                            <TableRow
                              hover
                              key={id}
                              tabIndex={-1}
                              role="checkbox"
                              selected={isItemSelected}
                              aria-checked={isItemSelected}
                            >
                              <TableCell padding="checkbox">
                                <Checkbox
                                  checked={isItemSelected}
                                  onChange={(event) => handleClick(event, id)}
                                />
                              </TableCell>
                              <TableCell align="left">{plant_no}</TableCell>
                              <TableCell align="left">{pantry_no}</TableCell>
                              <TableCell align="left">{pantry_details}</TableCell>
                              <TableCell align="left">
                                {status == 'A' ? 'ใช้งาน' : 'ไม่ใช้งาน'}
                              </TableCell>

                              <TableCell align="right">
                                <PantryMoreMenu props={props} id={id} />
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>
                    {isUserNotFound && (
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                            <SearchNotFound searchQuery={pantryfilterName} />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
              </Scrollbar>

              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={filteredUsers.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </>
          )}
        </Card>
      </Container>
    </Page>
  );
}

const mapStateToProps = ({ pantryReducer, plantReducer }) => ({ pantryReducer, plantReducer });

const mapDispatchToProps = {
  ...actions,
  ...PlantAction
};

export default connect(mapStateToProps, mapDispatchToProps)(Pantry);
