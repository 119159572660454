import { Icon } from '@iconify/react';
import { useRef, useState } from 'react';
import editFill from '@iconify/icons-eva/edit-fill';
import { useNavigate } from 'react-router-dom';
import trash2Outline from '@iconify/icons-eva/trash-2-outline';
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill';
// material
import { Menu, MenuItem, IconButton, ListItemIcon, ListItemText } from '@mui/material';

import Swal from 'sweetalert2';

import { useOrder } from '../../../hooks/useOrder';
// ----------------------------------------------------------------------

export default function OrderMoreMenu({ props, id, status }) {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  const pageDatas = useOrder();
  const navigate = useNavigate();

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Icon icon={moreVerticalFill} width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' }
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        {status === 'A' && (
          <>
            <MenuItem
              sx={{ color: 'text.secondary' }}
              onClick={() => {
                setIsOpen(false);
                Swal.fire({
                  title: 'Are you sure?',
                  text: "You won't be able to Delete!",
                  icon: 'warning',
                  showCancelButton: true,
                  confirmButtonColor: '#3085d6',
                  cancelButtonColor: '#d33',
                  confirmButtonText: 'Yes, delete it!'
                }).then((result) => {
                  if (result.isConfirmed) {
                    // console.log(id);

                    props.deleteOrders(id);

                    // Swal.fire('Deleted!', 'Your file has been deleted.', 'success');
                  }
                });
              }}
            >
              <ListItemIcon>
                <Icon icon={trash2Outline} width={24} height={24} />
              </ListItemIcon>
              <ListItemText primary="Delete" primaryTypographyProps={{ variant: 'body2' }} />
            </MenuItem>

            <MenuItem
              // component={RouterLink}
              // to={`/dashboard/orderproduct/${id}`}
              sx={{ color: 'text.secondary' }}
              onClick={() => {
                pageDatas.setIsState('E');
                navigate(`/dashboard/orderproduct/${id}`, { replace: true });
              }}
            >
              <ListItemIcon>
                <Icon icon={editFill} width={24} height={24} />
              </ListItemIcon>
              <ListItemText primary="Edit" primaryTypographyProps={{ variant: 'body2' }} />
            </MenuItem>
          </>
        )}
        {status !== 'A' && (
          <MenuItem
            sx={{ color: 'text.secondary' }}
            onClick={() => {
              pageDatas.setIsState('E');
              navigate(`/dashboard/orderproduct/${id}`, { replace: true });
            }}
          >
            <ListItemIcon>
              <Icon icon="carbon:view-filled" width={24} height={24} />
            </ListItemIcon>
            <ListItemText primary="View" primaryTypographyProps={{ variant: 'body2' }} />
          </MenuItem>
        )}
      </Menu>
    </>
  );
}
