/* eslint-disable */
import PropTypes from 'prop-types';

import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
// material
import { Box, Card, Link, Typography, Stack, Button, Container } from '@mui/material';
import { styled } from '@mui/material/styles';
import { connect } from 'react-redux';
import * as actions from '../../../actions/newpost.action';
import { NewpostMoreMenu } from '../../../components/_dashboard/newpost';
// utils
import { fCurrency } from '../../../utils/formatNumber';
//
import { imageUrlPost } from '../../../constants';

// ----------------------------------------------------------------------

const ProductImgStyle = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute'
});

// ----------------------------------------------------------------------

PostCard.propTypes = {
  news: PropTypes.object
};

export function PostCard({ news, props, type }) {
  const { message, header_name, newpost_image } = news;

  return (
    <Card>
      <Box sx={{ pt: '100%', position: 'relative' }}>
        {type == 'edit' && <NewpostMoreMenu props={props} id={news.id} type={type} />}
        <ProductImgStyle
          alt={header_name}
          src={`${imageUrlPost}/${newpost_image}?dummy=${Math.random()}`}
        />
      </Box>

      <Stack spacing={2} sx={{ p: 3 }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="subtitle1">{header_name}</Typography>
        </Stack>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="subtitle1">{message}</Typography>
        </Stack>
      </Stack>
    </Card>
  );
}

const mapStateToProps = ({ newpostReducer }) => ({ newpostReducer });

const mapDispatchToProps = {
  ...actions
};

export default connect(mapStateToProps, mapDispatchToProps)(PostCard);
