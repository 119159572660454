/* eslint-disable */
import * as React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import searchFill from '@iconify/icons-eva/search-fill';
import trash2Fill from '@iconify/icons-eva/trash-2-fill';
import roundFilterList from '@iconify/icons-ic/round-filter-list';
// material
import { styled } from '@mui/material/styles';
import {
  Box,
  Toolbar,
  Tooltip,
  IconButton,
  Typography,
  OutlinedInput,
  InputAdornment,
  TextField,
  Grid
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Stack } from '@mui/material';
// ----------------------------------------------------------------------

const RootStyle = styled(Toolbar)(({ theme }) => ({
  // height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(3, 1, 0, 3)
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`
  }
}));

// ----------------------------------------------------------------------

BackLogListToolbar.propTypes = {
  numSelected: PropTypes.number,
  filterName: PropTypes.string,
  onFilterName: PropTypes.func
};

export default function BackLogListToolbar({ numSelected, filterName, onFilterName }) {
  const [DateFromvalue, setDateFromValue] = React.useState();

  const handleDateFromChange = (newValue) => {
    setDateFromValue(newValue.toDate());
  };

  const [DateToValue, setDateToValue] = React.useState();

  const handleDateToChange = (newValue) => {
    setDateToValue(newValue.toDate());
  };

  return (
    <RootStyle
      sx={{
        ...(numSelected > 0 && {
          color: 'primary.main',
          bgcolor: 'primary.lighter'
        })
      }}
    >
      {numSelected > 0 ? (
        <Typography component="div" variant="subtitle1">
          {numSelected} selected
        </Typography>
      ) : (
        <>
          <Grid container spacing={2}>
            <Grid item container spacing={2} lg={6} md={6} xs={12}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Grid item lg={5.5} md={5.5} xs={5.5}>
                  <Stack>
                    <DatePicker
                      label="วันที่เริ่ม"
                      inputFormat="DD/MM/YYYY"
                      value={DateFromvalue}
                      onChange={handleDateFromChange}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </Stack>
                </Grid>

                <Grid item lg={1} md={1} xs={1}>
                  <Typography variant="h6" textAlign="center">
                    ถึง
                  </Typography>
                </Grid>

                <Grid item lg={5.5} md={5.5} xs={5.5}>
                  <Stack>
                    <DatePicker
                      label="ถึงวันที่"
                      inputFormat="DD/MM/YYYY"
                      value={DateToValue}
                      onChange={handleDateToChange}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </Stack>
                </Grid>
              </LocalizationProvider>
            </Grid>
            <Grid item container spacing={2} lg={6} md={6} xs={12}>
              <Grid item lg={5.5} md={5.5} xs={5.5}>
                <TextField sx={{ width: '100%' }} select label="รหัสสินค้า"></TextField>
              </Grid>
              <Grid item lg={1} md={1} xs={1}>
                <Typography variant="h6" textAlign="center">
                  ถึง
                </Typography>
              </Grid>
              <Grid item lg={5.5} md={5.5} xs={5.5}>
                <TextField sx={{ width: '100%' }} select label="รหัสสินค้า"></TextField>
              </Grid>
            </Grid>

            <Grid item container spacing={2} lg={6} md={6} xs={12}>
              <Grid item lg={5.5} md={5.5} xs={5.5}>
                <TextField sx={{ width: '100%' }} select label="พื้นที่"></TextField>
              </Grid>

              <Grid item lg={1} md={1} xs={1}>
                <Typography variant="h6" textAlign="center">
                  ถึง
                </Typography>
              </Grid>

              <Grid item lg={5.5} md={5.5} xs={5.5}>
                <TextField sx={{ width: '100%' }} select label="พื้นที่"></TextField>
              </Grid>
            </Grid>

            <Grid item container spacing={2} lg={6} md={6} xs={12}>
              <Grid item lg={5.5} md={5.5} xs={5.5}>
                <TextField sx={{ width: '100%' }} select label="ตึก"></TextField>
              </Grid>

              <Grid item lg={1} md={1} xs={1}>
                <Typography variant="h6" textAlign="center">
                  ถึง
                </Typography>
              </Grid>

              <Grid item lg={5.5} md={5.5} xs={5.5}>
                <TextField sx={{ width: '100%' }} select label="ตึก"></TextField>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </RootStyle>
  );
}
