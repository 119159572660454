/* eslint-disable */
import { filter } from 'lodash';
import { Icon } from '@iconify/react';
import { sentenceCase } from 'change-case';
import { useState } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink } from 'react-router-dom';
// material
import {
  Box,
  Card,
  Grid,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination
} from '@mui/material';
// components
import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import { UserListHead, UserMoreMenu } from '../components/_dashboard/user';
import {
  PickingListHead,
  PickingMoreMenu,
  PickingListToolbar
} from '../components/_dashboard/picking';
//
import PickingList from '../_mocks_/picking';
import { orderStatus, server } from '../constants';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'รหัสสินค้า', label: 'รหัสสินค้า', alignRight: false },
  { id: 'รายการสินค้า', label: 'รายการสินค้า', alignRight: false },
  { id: 'date1', label: '05/10/21', alignRight: false },
  { id: 'date2', label: '12/10/21', alignRight: false },
  { id: 'date3', label: '19/10/21', alignRight: false },
  { id: 'date4', label: '26/10/21', alignRight: false },
  { id: 'total', label: 'ทั้งหมด', alignRight: false },
  { id: 'price', label: 'ราคา', alignRight: false },
  { id: 'amount', label: 'จำนวน', alignRight: false }
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  let dataFilter = [...array];

  if (query) {
    dataFilter = filter(
      dataFilter,
      (_billing) => _billing.เลขที่ใบกำกับภาษี.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }

  const stabilizedThis = dataFilter.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  return stabilizedThis.map((el) => el[0]);
}

export default function Pantry() {
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(
    parseInt(localStorage.getItem(server.ROWS_PER_PAGE))
  );

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = PickingList.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    localStorage.setItem(server.ROWS_PER_PAGE, event.target.value);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - PickingList.length) : 0;

  const filteredUsers = applySortFilter(PickingList, getComparator(order, orderBy), filterName);

  const isUserNotFound = filteredUsers.length === 0;

  return (
    <Page title="Billing | JConnect">
      <Container maxWidth="false">
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            รายการเบิกสินค้าประจำเดือน
          </Typography>
        </Stack>

        <Card>
          {/* <PickingListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
          /> */}
          <Scrollbar>
            <TableContainer sx={{ minWidth: 500 }}>
              <Table>
                <PickingListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={PickingList.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredUsers
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      const {
                        id,
                        รหัสสินค้า,
                        รายการสินค้า,
                        date1,
                        date2,
                        date3,
                        date4,
                        total,
                        price,
                        amount
                      } = row;
                      const isItemSelected = selected.indexOf(id) !== -1;

                      return (
                        <TableRow
                          hover
                          key={id}
                          tabIndex={-1}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                        >
                          <TableCell align="left">{รหัสสินค้า}</TableCell>
                          <TableCell align="left">{รายการสินค้า}</TableCell>
                          <TableCell align="left">{date1}</TableCell>
                          <TableCell align="left">{date2}</TableCell>
                          <TableCell align="left">{date3}</TableCell>
                          <TableCell align="left">{date4}</TableCell>
                          <TableCell align="left">{total}</TableCell>
                          <TableCell align="left">{price}</TableCell>
                          <TableCell align="left">{amount}</TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={PickingList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row-reverse',
              p: 1,
              mr: 2,
              mb: 2,
              bgcolor: 'background.paper'
            }}
          >
            <Button variant="contained" component={RouterLink} to="/dashboard/billing">
              ยกเลิก
            </Button>
            <Button variant="contained" sx={{ mr: 2 }}>
              บันทึก
            </Button>
          </Box>
        </Card>
      </Container>
    </Page>
  );
}
