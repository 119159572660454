/* eslint-disable */
import { filter } from 'lodash';
import { Icon } from '@iconify/react';
import logoutline from '@iconify/icons-eva/log-out-outline';
import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination
} from '@mui/material';
// components
import { connect } from 'react-redux';
import * as actions from '../actions/order.action';
import * as PlantAction from '../actions/plant.action';
import * as PantryAction from '../actions/pantry.action';
import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import { UserListHead, UserListToolbar } from '../components/_dashboard/user';
import {
  CheckProductByMoreMenu,
  CheckProductByListToolbar,
  CheckProductByListHead
} from '../components/_dashboard/checkproductby';
//
// import CheckproductList from '../_mocks_/checkproduct';
import { fDate, fDateTime } from '../utils/formatTime';
import { orderStatus, server } from '../constants';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: '' },
  { id: 'plant_no', label: 'พื้นที่' }
  // { id: 'pantry_no', label: 'ตึก', alignRight: false },
  // { id: 'order_number', label: 'เลขที่เอกสาร', alignRight: false },
  // { id: 'order_date', label: 'วันที่เอกสาร', alignRight: false },
  // { id: 'order_by', label: 'ผู้สั่งสินค้า', alignRight: false },
  // { id: 'deliver_verify_by', label: 'ผู้ตรวจรับสั่งสินค้า', alignRight: false },
  // { id: 'deliver_verify_date', label: 'วันที่ตรวจรับสั่งสินค้า', alignRight: false },
  // { id: 'status', label: 'สถานะ', alignRight: false }
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, plant) {
  if (Array.isArray(array)) {
    let dataFilter = [...array];

    if (plant) {
      dataFilter = filter(
        dataFilter,
        (_checkproduct) =>
          _checkproduct.plant_no.toLowerCase() == plant.toLowerCase() || plant == '' || plant == '%'
      );
    }

    const stabilizedThis = dataFilter.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });

    return stabilizedThis.map((el) => el[0]);
  } else return [];
}

export function Checkproduct(props) {
  const [plantList, setPlantList] = useState(null);
  const [pantryList, setPantryList] = useState([]);
  const [deliverList, setDeliverList] = useState([]);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [plantfilterName, setPlantFilterName] = useState('');
  const [pantryfilterName, setPantryFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(
    parseInt(localStorage.getItem(server.ROWS_PER_PAGE))
  );

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = deliverList.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    localStorage.setItem(server.ROWS_PER_PAGE, event.target.value);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const handleplantFilterByName = (event) => {
    setPlantFilterName(event.target.value);
    setPantryFilterName('');
  };

  const handlepantryFilterByName = (event) => {
    setPantryFilterName(event.target.value);
  };

  useEffect(() => {
    props.getPlants();
  }, []);

  useEffect(() => {
    props.getPantryByPlantNo(plantfilterName);
  }, [plantfilterName]);

  useEffect(() => {
    const { result } = props.pantryReducer;
    if (result && plantfilterName != '') setPantryList(result);
  }, [props.pantryReducer]);

  useEffect(() => {
    const { result } = props.plantReducer;
    if (result) setPlantList(result);
  }, [props.plantReducer]);

  useEffect(() => {
    props.getOrdersReceivedGroupPlant();
    // props.getOrdersReceived();
  }, []);

  useEffect(() => {
    const { result } = props.orderReducer;

    if (result) setDeliverList(result);
  }, [props.orderReducer]);

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - deliverList.length) : 0;

  const filteredUsers = applySortFilter(
    deliverList,
    getComparator(order, orderBy),
    plantfilterName
  );

  const isUserNotFound = filteredUsers.length === 0;

  return (
    <Page title="CheckProduct | JConnect">
      <Container maxWidth="false">
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            ตรวจรับสินค้า
          </Typography>
          <Button
            variant="contained"
            component={RouterLink}
            to="/login"
            startIcon={<Icon icon={logoutline} />}
          >
            ออกจากระบบ
          </Button>
        </Stack>

        <Card>
          {deliverList && plantList && pantryList && (
            <>
              <CheckProductByListToolbar
                numSelected={selected.length}
                filterName={filterName}
                onFilterName={handleFilterByName}
                plantfilterName={plantfilterName}
                plantonFilterName={handleplantFilterByName}
                pantryfilterName={pantryfilterName}
                pantryonFilterName={handlepantryFilterByName}
                plant={plantList}
                pantry={pantryList}
              />

              <Scrollbar>
                <TableContainer sx={{ minWidth: 400 }}>
                  <Table>
                    <CheckProductByListHead
                      order={order}
                      orderBy={orderBy}
                      headLabel={TABLE_HEAD}
                      rowCount={deliverList.length}
                      numSelected={selected.length}
                      onRequestSort={handleRequestSort}
                      onSelectAllClick={handleSelectAllClick}
                    />
                    <TableBody>
                      {filteredUsers
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row) => {
                          const {
                            id,
                            plant_no
                            // pantry_no,
                            // order_number,
                            // order_date,
                            // order_by,
                            // deliver_verify_by,
                            // deliver_verify_date,
                            // status
                          } = row;
                          const isItemSelected = selected.indexOf(id) !== -1;

                          return (
                            <TableRow
                              hover
                              key={id}
                              tabIndex={-1}
                              role="checkbox"
                              selected={isItemSelected}
                              aria-checked={isItemSelected}
                            >
                              {/* <TableCell padding="checkbox">
                                <Checkbox
                                  checked={isItemSelected}
                                  onChange={(event) => handleClick(event, id)}
                                />
                              </TableCell> */}
                              <TableCell align="left">
                                <CheckProductByMoreMenu props={props} plant={plant_no} />
                              </TableCell>
                              <TableCell align="left">{plant_no}</TableCell>
                              {/* <TableCell align="left">{pantry_no}</TableCell>
                              <TableCell align="left">{order_number}</TableCell>
                              <TableCell align="left">{fDate(order_date)}</TableCell>
                              <TableCell align="left">{order_by}</TableCell>
                              <TableCell align="left">{deliver_verify_by}</TableCell>
                              <TableCell align="left">{fDateTime(deliver_verify_date)}</TableCell>
                              <TableCell align="left">
                                {
                                  orderStatus[orderStatus.findIndex((obj) => obj.value == status)]
                                    .label
                                }
                              </TableCell> */}
                            </TableRow>
                          );
                        })}
                      {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>
                    {isUserNotFound && (
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                            <SearchNotFound searchQuery={filterName} />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
              </Scrollbar>

              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={filteredUsers.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </>
          )}
        </Card>
      </Container>
    </Page>
  );
}

const mapStateToProps = ({ orderReducer, pantryReducer, plantReducer }) => ({
  orderReducer,
  pantryReducer,
  plantReducer
});

const mapDispatchToProps = {
  ...actions,
  ...PlantAction,
  ...PantryAction
};

export default connect(mapStateToProps, mapDispatchToProps)(Checkproduct);
