/* eslint-disable */
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import trash2Outline from '@iconify/icons-eva/trash-2-outline';
import { Link as RouterLink } from 'react-router-dom';
import editFill from '@iconify/icons-eva/edit-fill';
// material
import { Grid } from '@mui/material';
import { PostCard } from './PostCard';
import { Menu, MenuItem, IconButton, ListItemIcon, ListItemText } from '@mui/material';

// ----------------------------------------------------------------------

Postlist.propTypes = {
  news: PropTypes.array.isRequired
};

export default function Postlist({ news, props, type, ...other }) {
  return (
    <Grid container spacing={3} {...other}>
      {news.map((newpost) => (
        <Grid key={newpost.id} item xs={12} sm={6} md={4}>
          <PostCard news={newpost} props={props} type={type} />
        </Grid>
      ))}
    </Grid>
  );
}
