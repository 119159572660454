/* eslint-disable */
// routes
import Router from './routes';
// theme
import ThemeConfig from './theme';
import GlobalStyles from './theme/globalStyles';
// components
import ScrollToTop from './components/ScrollToTop';
import { BaseOptionChartStyle } from './components/charts/BaseOptionChart';

import { RecoilRoot, atom, selector, useRecoilState, useRecoilValue } from 'recoil';

// ----------------------------------------------------------------------

export default function App() {
  return (
    <RecoilRoot>
      <ThemeConfig>
        <ScrollToTop />
        <GlobalStyles />
        <BaseOptionChartStyle />
        <Router />
      </ThemeConfig>
    </RecoilRoot>
  );
}
