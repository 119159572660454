/* eslint-disable */
import { useEffect, useState } from 'react';
import { server } from '../constants';
import { useRecoilState, atom } from 'recoil';

export { useCheckOrder };

const baseApi = 'checkorder';

const filterStatusAtom = atom({
  key: `${baseApi}filterStatusAtom`,
  default: 'P'
});

const filterPlantAtom = atom({
  key: `${baseApi}filterPlantAtom`,
  default: '%'
});

function useCheckOrder() {
  const [filterPlant, setFilterPlant] = useRecoilState(filterPlantAtom);
  const [filterStatus, setFilterStatus] = useRecoilState(filterStatusAtom);

  return {
    filterPlant,
    setFilterPlant,
    filterStatus,
    setFilterStatus
  };
}
