/* eslint-disable */
import { useEffect, useState } from 'react';
import { server } from '../constants';
import { useRecoilState, atom } from 'recoil';

export { useDeliver };

const baseApi = 'deliver';

const statusAtom = atom({
  key: `${baseApi}statusAtom`,
  default: 'A'
});

const plantNoAtom = atom({
  key: `${baseApi}plantNoAtom`,
  default: ''
});

const pantryNoAtom = atom({
  key: `${baseApi}pantryNoAtom`,
  default: ''
});

const deliverNumberAtom = atom({
  key: `${baseApi}deliverNumberAtom`,
  default: ''
});

const deliverDateAtom = atom({
  key: `${baseApi}deliverDateAtom`,
  default: ''
});

const deliverPurchaseAtom = atom({
  key: `${baseApi}deliverPurchaseAtom`,
  default: ''
});
const deliverApproveAtom = atom({
  key: `${baseApi}deliverApproveAtom`,
  default: ''
});
const deliverApproveDateAtom = atom({
  key: `${baseApi}deliverApproveDateAtom`,
  default: ''
});

const deliverDTAtom = atom({
  key: `${baseApi}deliverDTAtom`,
  default: {}
});

const isStateAtom = atom({
  key: `${baseApi}isStateAtom`,
  default: ''
});

const isPerStateAtom = atom({
  key: `${baseApi}isPerStateAtom`,
  default: ''
});

function useDeliver() {
  const [status, setStatus] = useRecoilState(statusAtom);
  const [plantNo, setPlantNo] = useRecoilState(plantNoAtom);
  const [pantryNo, setPantryNo] = useRecoilState(pantryNoAtom);
  const [deliverNumber, setDeliverNumber] = useRecoilState(deliverNumberAtom);
  const [deliverDate, setDeliverDate] = useRecoilState(deliverDateAtom);
  const [deliverPurchase, setDeliverPurchase] = useRecoilState(deliverPurchaseAtom);
  const [deliverApprove, setDeliverApprove] = useRecoilState(deliverApproveAtom);
  const [deliverApproveDate, setDeliverApproveDate] = useRecoilState(deliverApproveDateAtom);
  const [deliverDT, setDeliverDT] = useRecoilState(deliverDTAtom);

  const [isState, setIsState] = useRecoilState(isStateAtom);
  const [isPerState, setIsPerState] = useRecoilState(isPerStateAtom);

  const [loading, setLoading] = useState(true);

  return {
    status,
    setStatus,
    plantNo,
    setPlantNo,
    pantryNo,
    setPantryNo,
    deliverNumber,
    setDeliverNumber,
    deliverDate,
    setDeliverDate,
    deliverPurchase,
    setDeliverPurchase,
    deliverApprove,
    setDeliverApprove,
    deliverApproveDate,
    setDeliverApproveDate,
    deliverDT,
    setDeliverDT,

    loading,
    setLoading,

    isState,
    setIsState,
    isPerState,
    setIsPerState,

    emptyData
  };

  async function emptyData() {
    // console.log(localStorage.getItem(server.LOGIN_USERNAME));
    setStatus('A');
    setPlantNo('');
    setPantryNo('');
    setDeliverNumber('');
    setDeliverDate('');
    setDeliverPurchase(localStorage.getItem(server.LOGIN_USERNAME));
    // setDeliverApprove('');
    // setDeliverApproveDate('');
    setDeliverDT([]);
  }
}
