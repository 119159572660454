// scroll bar
import 'simplebar/src/simplebar.css';

import ReactDOM from 'react-dom';
import { BrowserRouter, useHistory } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';

import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { Provider } from 'react-redux';
import logger from 'redux-logger';
import reducers from './reducers';

//
import App from './App';
import * as serviceWorker from './serviceWorker';
import reportWebVitals from './reportWebVitals';
import { server } from './constants';

localStorage.setItem(server.ROWS_PER_PAGE, 5);

const store = createStore(reducers, applyMiddleware(thunk, logger));

const ReduxApp = (
  <HelmetProvider>
    <BrowserRouter>
      {/* <App /> */}
      <Provider store={store}>
        <App />
      </Provider>
    </BrowserRouter>
  </HelmetProvider>
);

ReactDOM.render(ReduxApp, document.getElementById('root'));

// If you want to enable client cache, register instead.
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
