/* eslint-disable */
import {
  HTTP_QUOTATION_FETCHING,
  HTTP_QUOTATION_SUCCESS,
  HTTP_QUOTATION_FAILED,
  HTTP_QUOTATIONDT_FETCHING,
  HTTP_QUOTATIONDT_SUCCESS,
  HTTP_QUOTATIONDT_FAILED,
  server,
  OK,
  YES
} from '../constants';
import { httpClient } from '../utils/HttpClient';

const setStateQuotationToSuccess = (payload) => ({
  type: HTTP_QUOTATION_SUCCESS,
  payload
});

const setStateQuotationToFetching = () => ({
  type: HTTP_QUOTATION_FETCHING
});

const setStateQuotationToFailed = () => ({
  type: HTTP_QUOTATION_FAILED
});
const setStateQuotationDTToSuccess = (payload) => ({
  type: HTTP_QUOTATIONDT_SUCCESS,
  payload
});

const setStateQuotationDTToFetching = () => ({
  type: HTTP_QUOTATIONDT_FETCHING
});

const setStateQuotationDTToFailed = () => ({
  type: HTTP_QUOTATIONDT_FAILED
});

export const getQuotations = () => {
  return async (dispatch) => {
    dispatch(setStateQuotationToFetching());
    await doGetQuotations(dispatch);
  };
};

const doGetQuotations = async (dispatch) => {
  try {
    let result = await httpClient.get(
      `${server.QUOTATION__LIST_URL}/${localStorage.getItem(server.LOGIN_USERNAME)}`
    );
    //console.log(' *** doGetPlants >> ', result);
    dispatch(setStateQuotationToSuccess(result.data));
  } catch (error) {
    alert(JSON.stringify(error));
    dispatch(setStateQuotationToFailed());
  }
};

export const deleteQuotations = (id) => {
  return async (dispatch) => {
    dispatch(setStateQuotationToFetching());
    await httpClient.delete(`${server.QUOTATION_URL}/${id}`);
    await doGetQuotations(dispatch);
  };
};

export const getQuotationById = (id) => {
  return (dispatch) => {
    // dispatch(finishInitialization(false))
    dispatch(setStateQuotationToFetching());
    httpClient
      .get(`${server.QUOTATION_URL}/${id}`)
      .then((result) => {
        dispatch(setStateQuotationToSuccess(result.data));
      })
      .catch((err) => {
        console.log(err);
        dispatch(setStateQuotationToFailed());
      });
  };
};

export const getQuotationDTById = (id) => {
  return (dispatch) => {
    // dispatch(finishInitialization(false))
    dispatch(setStateQuotationDTToFetching());
    httpClient
      .get(`${server.QUOTATIONDT_URL}/${id}`)
      .then((result) => {
        dispatch(setStateQuotationDTToSuccess(result.data));
      })
      .catch((err) => {
        console.log(err);
        dispatch(setStateQuotationDTToFailed());
      });
  };
};

export const postQuotations = (navigate, data, datadt) => {
  return async (dispatch) => {
    dispatch(setStateQuotationToFetching());
    let result = await httpClient.post(server.QUOTATION_URL, data);
    // console.log(result);

    const id = JSON.parse(result.data.message).id;

    result = await httpClient.post(`${server.QUOTATIONDT_URL}/${id}`, datadt);

    if (result.data.result == OK) {
      navigate('/dashboard/quotation', { replace: true });
      dispatch(setStateQuotationToSuccess(result));
    } else {
      dispatch(setStateQuotationToFailed());
    }
  };
};

export const putQuotations = (navigate, data, datadt) => {
  return async (dispatch) => {
    dispatch(setStateQuotationToFetching());
    let result = await httpClient.put(server.QUOTATION_URL, data);
    const id = data.get('id');

    // console.log(data.get('id'));

    result = await httpClient.post(`${server.QUOTATIONDT_URL}/${id}`, datadt);
    if (result.data.result == OK) {
      navigate('/dashboard/quotation', { replace: true });
      dispatch(setStateQuotationToSuccess(result));
    } else {
      dispatch(setStateQuotationToFailed());
    }
  };
};
