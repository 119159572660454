import { HTTP_ORDER_FETCHING, HTTP_ORDER_SUCCESS, HTTP_ORDER_FAILED } from '../constants';

const initialState = {
  result: null,
  isFatching: false,
  isError: false,
  isPage: ''
};

export default (state = initialState, { type, payload, page }) => {
  switch (type) {
    case HTTP_ORDER_FETCHING:
      return { ...state, result: [], isFetching: true, isError: false };
    case HTTP_ORDER_SUCCESS:
      return { ...state, result: payload, isFetching: false, isError: false, isPage: page };
    case HTTP_ORDER_FAILED:
      return { ...state, result: [], isFetching: false, isError: true };

    default:
      return state;
  }
};
