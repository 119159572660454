/* eslint-disable */
import { filter, isArray, isObject } from 'lodash';
import { Icon } from '@iconify/react';
import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink } from 'react-router-dom';
// material
import {
  Box,
  Card,
  Grid,
  Table,
  Stack,
  Avatar,
  Button,
  TextField,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination
} from '@mui/material';
// components
import { connect } from 'react-redux';
import * as PlantAction from '../actions/plant.action';
import * as PantryAction from '../actions/pantry.action';
import * as ProductActions from '../actions/product.action';
import * as StockActions from '../actions/stock.action';
import { OK } from '../constants';
import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import { UserListHead, UserMoreMenu } from '../components/_dashboard/user';
import {
  StockListHead,
  StockMoreMenu,
  StockListToolbar,
  Stockinput
} from '../components/_dashboard/stock';
import Swal from 'sweetalert2';
import { orderStatus, server } from '../constants';

// import StockList from '../_mocks_/stock';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'product_code', label: 'รหัสสินค้า', alignRight: false },
  { id: 'product_name', label: 'ชื่อสินค้า', alignRight: false },
  { id: 'product_unit', label: 'หน่วย', alignRight: false },
  { id: 'product_inventory', label: 'สินค้าคงเหลือ', alignRight: false }
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, plant, pantry) {
  if (Array.isArray(array)) {
    let dataFilter = [...array];

    // if (plant || pantry) {
    //   dataFilter = filter(
    //     array,
    //     (_stock) =>
    //       _stock.Plant.toLowerCase().indexOf(plant.toLowerCase()) !== -1 &&
    //       _stock.Pantry.toLowerCase().indexOf(pantry.toLowerCase()) !== -1
    //   );
    // }

    const stabilizedThis = dataFilter.map((el, index) => [el, index]);

    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });

    return stabilizedThis.map((el) => el[0]);
  } else return [];
}

export function Stock(props) {
  const [plantList, setPlantList] = useState(null);
  const [pantryList, setPantryList] = useState([]);
  const [productList, setProductList] = useState([]);
  const [stock, setStock] = useState([]);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [plantfilterName, setPlantFilterName] = useState('');
  const [pantryfilterName, setPantryFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(
    parseInt(localStorage.getItem(server.ROWS_PER_PAGE))
  );

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = filteredUsers.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    localStorage.setItem(server.ROWS_PER_PAGE, event.target.value);
  };

  const handleplantFilterByName = (event) => {
    setPlantFilterName(event.target.value);
    setPantryFilterName('');
    setProductList([]);
  };

  const handlepantryFilterByName = (event) => {
    setPantryFilterName(event.target.value);
  };

  useEffect(() => {
    props.getPlants();
  }, []);

  useEffect(() => {
    props.getPantryByPlantNo(plantfilterName);
  }, [plantfilterName]);

  useEffect(() => {
    const { result } = props.pantryReducer;
    if (result && plantfilterName != '') setPantryList(result);
  }, [props.pantryReducer]);

  useEffect(() => {
    const { result } = props.plantReducer;
    // console.log(result);
    if (result) {
      setPlantList(result);
    }
  }, [props.plantReducer]);

  useEffect(() => {
    if (pantryfilterName != '') {
      props.getProducts();
      setStock([]);
    }
  }, [pantryfilterName]);

  useEffect(() => {
    if (pantryfilterName != '') {
      const { result } = props.productReducer;

      if (result) {
        setProductList(result);
        props.getStockByPantry(plantfilterName, pantryfilterName);
      }
    }
  }, [props.productReducer]);

  useEffect(() => {
    const { result, isFatching, isError } = props.stockReducer;

    if (result && !isFatching && !isError) {
      if (isArray(result) && result.length) {
        setStock(result);
        let arrProductList = [...productList];
        result.map((item) => {
          let objIndex = arrProductList.findIndex((obj) => obj.id == item.product_id);
          arrProductList[objIndex] = { ...arrProductList[objIndex], qty: item.qty };
        });
        setProductList(arrProductList);
      } else if (result.data) {
        if (result.data.result == OK) {
          console.log(result);
          props.getProducts();
        }
      }
    }
  }, [props.stockReducer]);

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - productList.length) : 0;

  const filteredUsers = applySortFilter(
    productList,
    getComparator(order, orderBy),
    plantfilterName,
    pantryfilterName
  );

  const isUserNotFound = filteredUsers.length === 0;

  const handleChangeQty = (event) => {
    let objIndex = productList.findIndex((obj) => obj.id == event.target.id);

    let arrProductList = [...productList];
    arrProductList[objIndex] = { ...arrProductList[objIndex], qty: event.target.value };
    setProductList(arrProductList);
  };

  return (
    <Page title="Stock | JConnect">
      <Container maxWidth="false">
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Stocks
          </Typography>
        </Stack>

        <Card>
          {productList && plantList && pantryList && (
            <>
              <StockListToolbar
                numSelected={selected.length}
                plantfilterName={plantfilterName}
                plantonFilterName={handleplantFilterByName}
                pantryfilterName={pantryfilterName}
                pantryonFilterName={handlepantryFilterByName}
                plant={plantList}
                pantry={pantryList}
              />
              <Scrollbar>
                <TableContainer sx={{ minWidth: 500 }}>
                  <Table>
                    <StockListHead
                      order={order}
                      orderBy={orderBy}
                      headLabel={TABLE_HEAD}
                      rowCount={filteredUsers.length}
                      numSelected={selected.length}
                      onRequestSort={handleRequestSort}
                      onSelectAllClick={handleSelectAllClick}
                    />
                    <TableBody>
                      {filteredUsers
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row) => {
                          const { id, product_code, product_name, product_unit, qty } = row;
                          const isItemSelected = selected.indexOf(id) !== -1;

                          return (
                            <TableRow
                              hover
                              key={id}
                              tabIndex={-1}
                              role="checkbox"
                              selected={isItemSelected}
                              aria-checked={isItemSelected}
                            >
                              <TableCell align="left">{product_code}</TableCell>
                              <TableCell align="left">{product_name}</TableCell>
                              <TableCell align="left">{product_unit}</TableCell>
                              <TableCell align="left">
                                {/* <Stockinput /> */}
                                <TextField
                                  inputProps={{
                                    // step: 1,
                                    min: 1,
                                    max: 99999,
                                    type: 'number',
                                    style: { textAlign: 'center' }
                                  }}
                                  id={id}
                                  value={qty || ''}
                                  onChange={handleChangeQty}
                                  align="right"
                                  onWheel={(e) => e.target.blur()}
                                />
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>
                    {isUserNotFound && (
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                            <SearchNotFound />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
              </Scrollbar>

              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={filteredUsers.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />

              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row-reverse',
                  p: 1,
                  mr: 2,
                  mb: 2,
                  bgcolor: 'background.paper'
                }}
              >
                <Button
                  variant="contained"
                  onClick={() => {
                    if (productList.length) {
                      props.getProducts();
                    }
                  }}
                >
                  ยกเลิก
                </Button>
                <Button
                  variant="contained"
                  sx={{ mr: 2 }}
                  onClick={() => {
                    if (productList.length) {
                      // console.log(stock);
                      let stockBal = [...stock];
                      const requests = productList.map((item) => {
                        const objIndex = stock.findIndex((obj) => obj.product_id == item.id);

                        if (objIndex > -1) {
                          // if (item.qty > 0) {
                          stockBal[objIndex] = { ...stockBal[objIndex], qty: item.qty };
                          // } else {
                          //   markers.splice(objIndex, 1);
                          //   // console.log(markers);
                          // }
                          console.log(stockBal);
                        } else if (item.qty > 0) {
                          console.log(item);

                          stockBal.push({
                            product_id: item.id,
                            qty: item.qty
                          });
                        }
                      });

                      Promise.all(requests).then(() => {
                        // setStock(stockBal);
                        // console.log(stock);

                        props.postStock(plantfilterName, pantryfilterName, stockBal);
                        Swal.fire({
                          position: 'center',
                          icon: 'success',
                          title: 'Save',
                          showConfirmButton: false,
                          timer: 1500
                        });

                        props.getProducts();
                      });
                    }
                  }}
                >
                  บันทึก
                </Button>
              </Box>
            </>
          )}
        </Card>
      </Container>
    </Page>
  );
}

const mapStateToProps = ({ productReducer, pantryReducer, plantReducer, stockReducer }) => ({
  productReducer,
  pantryReducer,
  plantReducer,
  stockReducer
});

const mapDispatchToProps = {
  ...ProductActions,
  ...PlantAction,
  ...PantryAction,
  ...StockActions
};

export default connect(mapStateToProps, mapDispatchToProps)(Stock);
