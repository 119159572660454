/* eslint-disable */
import * as React from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { filter } from 'lodash';
import { Icon } from '@iconify/react';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { connect } from 'react-redux';
import * as actions from '../actions/product.action';

import {
  Box,
  Table,
  Grid,
  Card,
  Paper,
  Button,
  Checkbox,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  FormControl,
  OutlinedInput,
  TextField,
  MenuItem,
  Stack
} from '@mui/material';
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
// import OrderPopupList from '../_mocks_/orderpopup';
import { QuotationProductListHead } from '../components/_dashboard/quotationproduct';

import { useQuotation } from '../hooks/useQuotation';
import { orderStatus, server, selQTY } from '../constants';
import { fCurrency } from '../utils/formatNumber';
import { isArray, isObject } from 'lodash';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'product_code', label: 'รหัสสินค้า', alignRight: false },
  { id: 'product_name', label: 'ชื่อสินค้า', alignRight: false },
  { id: 'product_unit', label: 'หน่วย', alignRight: false },
  { id: 'product_price', label: 'ราคา/หน่วย', alignRight: false },
  { id: 'qty', label: 'จำนวน', alignRight: false },
  { id: 'product_total', label: 'รวม', alignRight: false }
];

const TABLE_HEAD_NO_PRICE = [
  { id: 'product_code', label: 'รหัสสินค้า', alignRight: false },
  { id: 'product_name', label: 'ชื่อสินค้า', alignRight: false },
  { id: 'product_unit', label: 'หน่วย', alignRight: false },
  { id: 'qty', label: 'จำนวน', alignRight: false }
  // { id: '' }
];

// ----------------------------------------------------------------------

// function descendingComparator(a, b, orderBy) {
//   if (b[orderBy] < a[orderBy]) {
//     return -1;
//   }
//   if (b[orderBy] > a[orderBy]) {
//     return 1;
//   }
//   return 0;
// }

// function getComparator(order, orderBy) {
//   return order === 'desc'
//     ? (a, b) => descendingComparator(a, b, orderBy)
//     : (a, b) => -descendingComparator(a, b, orderBy);
// }

// function applySortFilter(array, comparator, query) {
//   const stabilizedThis = dataFilter.map((el, index) => [el, index]);
//   stabilizedThis.sort((a, b) => {
//     const order = comparator(a[0], b[0]);
//     if (order !== 0) return order;
//     return a[1] - b[1];
//   });
//   if (query) {
//     dataFilter = filter(dataFilter, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
//   }
//   return stabilizedThis.map((el) => el[0]);
// }

export function QuotationPopup(props) {
  const pageDatas = useQuotation();
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [rowsPerPage, setRowsPerPage] = useState(
    parseInt(localStorage.getItem(server.ROWS_PER_PAGE))
  );

  const [productList, setProductList] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [totalRow, setTotalRow] = useState(0);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    localStorage.setItem(server.ROWS_PER_PAGE, event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - productList.length) : 0;

  // const filteredUsers = applySortFilter(productList, getComparator(order, orderBy), filterName);

  // const isUserNotFound = filteredUsers.length === 0;

  useEffect(() => {
    props.getProducts();
  }, []);

  useEffect(() => {
    const { result, isFatching, isError } = props.productReducer;

    if (result && !isFatching && !isError && result.length) {
      isArray(pageDatas.quotationDT) &&
        pageDatas.quotationDT.map((item) => {
          let objIndex = result.findIndex((obj) => obj.id == item.product_id);
          result[objIndex].qty = item.qty;
        });
      setProductList(result);
      setTotalRow(pageDatas.quotationDT.length);
      setTotalPrice(
        isArray(pageDatas.quotationDT) &&
          pageDatas.quotationDT.reduce(function (prev, current) {
            return prev + +(current.product_price * current.qty);
          }, 0)
      );
    }
  }, [props.productReducer]);

  const handleChangeQty = (event, id) => {
    // console.log(event.target.value);
    // console.log(event.target.id);

    let objIndex = productList.findIndex((obj) => obj.id == id);
    let arrProductList = [...productList];
    arrProductList[objIndex] = { ...arrProductList[objIndex], qty: event.target.value };
    setProductList(arrProductList);

    let tRow = 0;
    let tPrice = 0;

    arrProductList.map((item) => {
      if (item.qty > 0) {
        tRow = tRow + 1;
        tPrice = tPrice + item.qty * item.product_price;
      }
    });

    setTotalRow(tRow);
    setTotalPrice(tPrice);

    // setProductList([...(productList.id = event.target.id), event.target.value]);
  };

  console.log(pageDatas);

  return (
    <Page title="Quotation | JConnect">
      <Container>
        <Grid container spacing={2} sx={{ mt: 3 }}>
          <Grid item lg={1} md={1} xs={0}></Grid>
          <Grid item lg={10} md={10} xs={12}>
            <Box
              sx={{
                border: 1,
                borderRadius: 1,
                borderColor: 'text.primary',
                p: 3
              }}
            >
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{ mb: 1 }}
              >
                <Typography variant="h4" gutterBottom sx={{ borderBottom: 1, width: '100%' }}>
                  เพิ่มสินค้า
                </Typography>
              </Stack>

              {localStorage.getItem(server.LOGIN_SHOW_PRICE) === 'A' && (
                <Grid container spacing={2}>
                  <Grid item lg={6} md={6} xs={6}>
                    <TextField
                      type="text"
                      label="ยอดรวมรายการ :"
                      value={totalRow}
                      variant="outlined"
                      sx={{ width: '100%', mb: 0 }}
                      inputProps={{ readOnly: true }}
                    />
                  </Grid>

                  <Grid item lg={6} md={6} xs={6}>
                    <TextField
                      type="text"
                      label="ยอดรวมทั้งหมด :"
                      value={fCurrency(totalPrice)}
                      variant="outlined"
                      sx={{ width: '100%', mb: 0 }}
                      inputProps={{ readOnly: true }}
                    />
                  </Grid>
                </Grid>
              )}
              <>
                <Scrollbar>
                  <TableContainer sx={{ minWidth: 500 }}>
                    <Table>
                      <QuotationProductListHead
                        order={order}
                        orderBy={orderBy}
                        headLabel={
                          localStorage.getItem(server.LOGIN_SHOW_PRICE) === 'A'
                            ? TABLE_HEAD
                            : TABLE_HEAD_NO_PRICE
                        }
                        onRequestSort={handleRequestSort}
                      />
                      <TableBody>
                        {productList // filteredUsers
                          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                          .map((row) => {
                            const {
                              id,
                              product_code,
                              product_name,
                              product_unit,
                              qty,
                              product_price
                            } = row;
                            const isItemSelected = selected.indexOf(id) !== -1;

                            return (
                              <TableRow
                                hover
                                key={id}
                                tabIndex={-1}
                                role="checkbox"
                                selected={isItemSelected}
                                aria-checked={isItemSelected}
                              >
                                <TableCell align="left">{product_code}</TableCell>
                                <TableCell align="left">{product_name}</TableCell>
                                <TableCell align="left">{product_unit}</TableCell>
                                {localStorage.getItem(server.LOGIN_SHOW_PRICE) === 'A' && (
                                  <TableCell align="left">{fCurrency(product_price)}</TableCell>
                                )}
                                <TableCell align="left">
                                  <TextField
                                    inputProps={{
                                      // step: 1,
                                      min: 1,
                                      max: 99999,
                                      type: 'number',
                                      style: { textAlign: 'center' }
                                    }}
                                    id={id}
                                    value={qty || ''}
                                    onChange={(e) => handleChangeQty(e, id)}
                                    align="right"
                                    onWheel={(e) => e.target.blur()}
                                    select={product_name.indexOf('ถุงขยะ') != -1}
                                    SelectProps={{
                                      MenuProps: {
                                        PaperProps: {
                                          style: {
                                            maxHeight: 48 * 4.5 + 8
                                          }
                                        }
                                      }
                                    }}
                                    sx={{ minWidth: 90 }}
                                  >
                                    {selQTY.map((option) => {
                                      return (
                                        <MenuItem value={option.value}>{option.value}</MenuItem>
                                      );
                                    })}
                                  </TextField>
                                  {/* <OrderPopupinput /> */}
                                </TableCell>
                                {localStorage.getItem(server.LOGIN_SHOW_PRICE) === 'A' && (
                                  <TableCell align="left">
                                    {fCurrency(product_price * qty)}
                                  </TableCell>
                                )}
                              </TableRow>
                            );
                          })}
                        {emptyRows > 0 && (
                          <TableRow style={{ height: 53 * emptyRows }}>
                            <TableCell colSpan={6} />
                          </TableRow>
                        )}
                      </TableBody>
                      {/* {isUserNotFound && (
                        <TableBody>
                          <TableRow>
                            <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                              <SearchNotFound searchQuery={filterName} />
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      )} */}
                    </Table>
                  </TableContainer>
                </Scrollbar>

                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={productList.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </>

              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row-reverse',
                  //   p: 1,
                  mt: 2,
                  bgcolor: 'background.paper'
                }}
              >
                <Button
                  variant="contained"
                  // component={RouterLink} to="/dashboard/orderproduct"
                  onClick={() => {
                    if (pageDatas.isPerState)
                      navigate(`/dashboard/quotationproduct/${pageDatas.isPerState}`, {
                        replace: true
                      });
                    else navigate('/dashboard/quotationproduct', { replace: true });
                  }}
                >
                  ยกเลิก
                </Button>
                <Button
                  variant="contained"
                  sx={{ mr: 2 }}
                  onClick={() => {
                    let markers = []; // [...pageDatas.quotationDT];
                    let mkIndex = -1;
                    const requests = productList.map((item) => {
                      const objIndex = pageDatas.quotationDT.findIndex(
                        (obj) => obj.product_id == item.id
                      );

                      if (objIndex > -1) {
                        if (item.qty > 0) {
                          mkIndex = markers.push(pageDatas.quotationDT[objIndex]) - 1;
                          console.log(mkIndex);
                          markers[mkIndex] = { ...markers[mkIndex], qty: item.qty };
                        }
                        // else {
                        //   markers.splice(objIndex, 1);
                        // }
                      } else if (item.qty > 0) {
                        markers.push({
                          product_id: item.id,
                          product_code: item.product_code,
                          product_name: item.product_name,
                          product_unit: item.product_unit,
                          product_price: item.product_price,
                          qty: item.qty
                        });
                      }
                    });
                    console.log(markers);
                    Promise.all(requests).then(() => {
                      pageDatas.setQuotationDT(markers);

                      if (pageDatas.isPerState)
                        navigate(`/dashboard/quotationproduct/${pageDatas.isPerState}`, {
                          replace: true
                        });
                      else navigate('/dashboard/quotationproduct', { replace: true });
                    });
                  }}
                >
                  ตกลง
                </Button>
              </Box>
            </Box>
          </Grid>
          <Grid item lg={3} md={3} xs={0}></Grid>
        </Grid>
      </Container>
    </Page>
  );
}

const mapStateToProps = ({ productReducer }) => ({ productReducer });

const mapDispatchToProps = {
  ...actions
};

export default connect(mapStateToProps, mapDispatchToProps)(QuotationPopup);
