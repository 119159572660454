/* eslint-disable */
import { filter } from 'lodash';
import { Icon } from '@iconify/react';
import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink } from 'react-router-dom';
// material
import {
  Card,
  Button,
  Stack,
  Menu,
  MenuItem,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination
} from '@mui/material';
// components
import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import { ReportListToolbar, ReportListHead } from '../components/_dashboard/report';
// import ReportList from '../_mocks_/report';
import { server, apiUrl } from '../constants';
import * as PlantAction from '../actions/plant.action';
import * as ProductAction from '../actions/product.action';
import { connect } from 'react-redux';
import printerfill from '@iconify/icons-eva/printer-fill';
import { styled, alpha } from '@mui/material/styles';

// ----------------------------------------------------------------------

export function ReportSum(props) {
  const [plantList, setPlantList] = useState(null);
  const [plantNoFrom, setPlantNoFrom] = useState([]);
  const [plantNoTo, setPlantNoTo] = useState('%');

  const [productList, setProductList] = useState(null);
  const [productNoFrom, setProductNoFrom] = useState('%');
  const [productNoTo, setProductNoTo] = useState('%');

  const [dateFrom, setDateFrom] = useState(new Date());
  const [dateTo, setDateTo] = useState(new Date());

  const [showReport, setShowReport] = useState();

  const onClickAll = () => {
    let value = [];

    plantList.map((list) => {
      console.log(list.plant_no);
      value.push(list.plant_no);
    });

    setPlantNoFrom(value);
  };

  const onClickCLEAR = () => {
    setPlantNoFrom([]);

    console.log('clear');
  };

  const handlePlantNoFrom = (event) => {
    const {
      target: { value }
    } = event;

    typeof value === 'string' ? value.split(',') : value;

    value.forEach(function (item, index, object) {
      // if (item == 'ทั้งหมด')
      if (plantList.findIndex((pl) => pl.plant_no === item) == -1 && item != 'ทั้งหมด') {
        object.splice(index, 1);
        // console.log(item);
      }
    });

    setPlantNoFrom(value);
    console.log(value);

    // setPlantNoFrom(event.target.value);
    // setPlantNoTo(event.target.value);
  };

  const handlePlantNoTo = (event) => {
    setPlantNoTo(event.target.value);
  };

  const handleProductNoFrom = (event) => {
    setProductNoFrom(event.target.value);
    setProductNoTo(event.target.value);
  };

  const handleProductNoTo = (event) => {
    setProductNoTo(event.target.value);
  };

  const handleDateFrom = (newValue) => {
    setDateFrom(newValue.toDate());
    setDateTo(newValue.toDate());
  };

  const handleDateTo = (newValue) => {
    setDateTo(newValue.toDate());
  };

  useEffect(() => {
    props.getPlants();
  }, []);

  useEffect(() => {
    const { result } = props.plantReducer;
    if (result) setPlantList(result);
  }, [props.plantReducer]);

  useEffect(() => {
    props.getProducts();
  }, []);

  useEffect(() => {
    const { result } = props.productReducer;
    if (result) setProductList(result);
  }, [props.productReducer]);

  const [anchorEl, setAnchorEl] = useState(null);
  const printOpen = Boolean(anchorEl);
  const StyledMenu = styled((props) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      {...props}
    />
  ))(({ theme }) => ({
    '& .MuiPaper-root': {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      minWidth: 180,
      color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
      boxShadow:
        'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
      '& .MuiMenu-list': {
        padding: '4px 0'
      },
      '& .MuiMenuItem-root': {
        '& .MuiSvgIcon-root': {
          fontSize: 18,
          color: theme.palette.text.secondary,
          marginRight: theme.spacing(1.5)
        },
        '&:active': {
          backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity)
        }
      }
    }
  }));

  return (
    <Page title="Report Summary | JConnect">
      <Container maxWidth="false">
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            รายงานสรุป
          </Typography>
          <Button
            variant="contained"
            startIcon={<Icon icon={printerfill} width={24} height={24} />}
            // onClick={() => {
            //   if (dateFrom && dateTo && productNoFrom && productNoTo && plantNoFrom && plantNoTo) {
            //     // setShowReport(
            //     const report = `${apiUrl}/report/delivery/summary?pDateFrom=${dateFrom
            //       .toJSON()
            //       .slice(0, 10)}&pDateTo=${dateTo
            //       .toJSON()
            //       .slice(
            //         0,
            //         10
            //       )}&pProductFrom=${productNoFrom}&pProductTo=${productNoTo}&pPlantFrom=${plantNoFrom}&pPlantTo=${plantNoTo}`;

            //     // `${apiUrl}/report/billing?pMonth=${2}&pYear=${2022}&pGroup=${'PTT'}`
            //     // );

            //     const pdfWindow = window.open('summary');
            //     const title = 'PDF';
            //     const URI = 'report/summery';
            //     const html = `
            //         <html>
            //           <head>
            //             <title>${title}</title>
            //           </head>
            //           <body>
            //             <iframe src="${report}" width="100%" height="99.70%">
            //           </body>
            //         </html>
            //       `;
            //     pdfWindow.document.write(html);
            //     pdfWindow.document.close();
            //     pdfWindow.history.pushState(null, null, URI);
            //   }
            // }}
            id="basic-button"
            aria-controls={printOpen ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={printOpen ? 'true' : undefined}
            onClick={(event) => {
              setAnchorEl(event.currentTarget);
            }}
          >
            พิมพ์รายงานสรุปจัดส่ง
            {/* รายการเบิกสินค้าประจำเดือน */}
          </Button>

          <StyledMenu
            id="basic-menu"
            anchorEl={anchorEl}
            open={printOpen}
            onClose={() => {
              setAnchorEl(null);
            }}
            MenuListProps={{
              'aria-labelledby': 'basic-button'
            }}
          >
            <MenuItem
              onClick={async () => {
                if (
                  dateFrom &&
                  dateTo &&
                  productNoFrom &&
                  productNoTo &&
                  plantNoFrom &&
                  plantNoTo
                ) {
                  let plantNoArray = '';
                  plantNoFrom.map((plant_no) => {
                    plantNoArray =
                      plantNoArray + (plantNoArray == '' ? '' : ',') + '%27' + plant_no + '%27';
                  });
                  if (plantNoArray == '') plantNoArray = '%27' + 'ALL' + '%27';
                  // console.log(plantNoArray);

                  const report = `${apiUrl}/report/delivery/summary?pDateFrom=${dateFrom
                    .toJSON()
                    .slice(0, 10)}&pDateTo=${dateTo
                    .toJSON()
                    .slice(
                      0,
                      10
                    )}&pProductFrom=${productNoFrom}&pProductTo=${productNoTo}&pPlantFrom=${plantNoArray}&pPlantTo=${plantNoTo}`;

                  const pdfWindow = window.open('summary');
                  const title = 'PDF';
                  const URI = 'report/summery';
                  const html = `
                    <html>
                      <head>
                        <title>${title}</title>
                      </head>
                      <body>
                        <iframe src="${report}" width="100%" height="99.70%">
                      </body>
                    </html>
                  `;
                  pdfWindow.document.write(html);
                  pdfWindow.document.close();
                  pdfWindow.history.pushState(null, null, URI);
                }

                setAnchorEl(null);
              }}
            >
              PDF File
            </MenuItem>
            <MenuItem
              onClick={() => {
                window.location.href = `${apiUrl}/report/delivery/summary/report_dilivery_summary.xlsx?pDateFrom=${dateFrom
                  .toJSON()
                  .slice(0, 10)}&pDateTo=${dateTo
                  .toJSON()
                  .slice(
                    0,
                    10
                  )}&pProductFrom=${productNoFrom}&pProductTo=${productNoTo}&pPlantFrom=${plantNoFrom}&pPlantTo=${plantNoTo}`;

                setAnchorEl(null);
              }}
            >
              Excle File
            </MenuItem>
          </StyledMenu>
        </Stack>
        <ReportListToolbar
          plantNoFrom={plantNoFrom}
          onPlantNoFrom={handlePlantNoFrom}
          plantNoTo={plantNoTo}
          onPlantNoTo={handlePlantNoTo}
          plantList={plantList}
          productNoFrom={productNoFrom}
          onProductNoFrom={handleProductNoFrom}
          productNoTo={productNoTo}
          onProductNoTo={handleProductNoTo}
          productList={productList}
          dateFrom={dateFrom}
          onDateFrom={handleDateFrom}
          dateTo={dateTo}
          onDateTo={handleDateTo}
          onClickPlantAll={onClickAll}
          onClickPlantCLEAR={onClickCLEAR}
        />
        {/* {showReport && (
          <Card sx={{ mt: 2, height: '1200px' }}>
            <iframe width="100%" height="100%" frameBorder="0" src={showReport}></iframe>
          </Card>
        )} */}
      </Container>
    </Page>
  );
}

const mapStateToProps = ({ plantReducer, productReducer }) => ({
  plantReducer,
  productReducer
});

const mapDispatchToProps = {
  ...PlantAction,
  ...ProductAction
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportSum);
