import {
  HTTP_LOGIN_FETCHING,
  HTTP_LOGIN_SUCCESS,
  HTTP_LOGIN_FAILED,
  HTTP_LOGIN_CLEAR
} from '../constants';

const initialState = {
  result: null,
  isFatching: false,
  isError: false
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case HTTP_LOGIN_FETCHING:
      return { ...state, result: [], isFetching: true, isError: false };
    case HTTP_LOGIN_SUCCESS:
      return { ...state, result: payload, isFetching: false, isError: false };
    case HTTP_LOGIN_FAILED:
      return { ...state, result: payload, isFetching: false, isError: true };
    case HTTP_LOGIN_CLEAR:
      return initialState;
    default:
      return state;
  }
};
