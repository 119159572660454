/* eslint-disable */
import { filter, isArray, isDate } from 'lodash';
import { Icon } from '@iconify/react';
import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';
import printerfill from '@iconify/icons-eva/printer-fill';
import savefill from '@iconify/icons-eva/save-fill';
import { Link as RouterLink } from 'react-router-dom';
// material
import {
  Box,
  Card,
  Grid,
  Table,
  Menu,
  MenuItem,
  Button,
  TextField,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination
} from '@mui/material';
// components
import { connect } from 'react-redux';
import * as PlantAction from '../actions/plant.action';
import * as PantryAction from '../actions/pantry.action';
import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import { UserListHead, UserMoreMenu } from '../components/_dashboard/user';
import {
  BillingListHead,
  BillingMoreMenu,
  BillingListToolbar
} from '../components/_dashboard/billing';
//
// import BillingList from '../_mocks_/billing';
import { server, apiUrl } from '../constants';
import { httpClient } from '../utils/HttpClient';
// import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
// import LocalizationProvider from '@mui/lab/LocalizationProvider';
// import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { fCurrency } from '../utils/formatNumber';
import Swal from 'sweetalert2';
import { styled, alpha } from '@mui/material/styles';
import { isDayjs } from 'dayjs';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'plant_no', label: 'พื้นที่', alignRight: false },
  { id: 'invoice_number', label: 'เลขที่ใบกำกับภาษี', alignRight: false },
  { id: 'invoice_date', label: 'วันที่ใบกำกับภาษี', alignRight: false },
  { id: 'amount', label: 'ยอดก่อนภาษี', alignRight: false },
  { id: 'amount_total', label: 'ยอดรวมภาษี', alignRight: false },
  { id: '' }
];

// ----------------------------------------------------------------------

export function Billing(props) {
  const [plantList, setPlantList] = useState(null);
  const [page, setPage] = useState(0);
  const [plantfilterName, setPlantFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(
    parseInt(localStorage.getItem(server.ROWS_PER_PAGE))
  );
  const [DateDocumentvalue, setDateDocumentValue] = useState(new Date());

  const [billingList, setBillingList] = useState([]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    localStorage.setItem(server.ROWS_PER_PAGE, event.target.value);
  };

  const handleplantFilterByName = (event) => {
    setPlantFilterName(event.target.value);
  };

  useEffect(() => {
    props.getPlantDetail();
  }, []);

  useEffect(() => {
    const { result } = props.plantReducer;
    if (result) setPlantList(result);
  }, [props.plantReducer]);

  useEffect(() => {
    if (plantfilterName !== '') {
      const gMonth = DateDocumentvalue.getMonth() + 1;
      const gYear = DateDocumentvalue.getFullYear();
      // console.log(`${server.REPORT_BILLING_URL}/${gMonth}/${gYear}/${plantfilterName}`);

      httpClient
        .get(`${server.REPORT_BILLING_URL}/${gMonth}/${gYear}/${plantfilterName}`)
        .then((result) => {
          setBillingList(result.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [plantfilterName, DateDocumentvalue]);

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - billingList.length) : 0;

  const isUserNotFound = billingList.length === 0;

  const handleDateDocumentChange = (newValue) => {
    // if (isDate(newValue))
    setDateDocumentValue(newValue.toDate());
    // console.log(newValue);
  };

  const handleChangeInvoiceNumber = (event, plant_no) => {
    // console.log(event.target.value);
    // console.log(billingList);

    let objIndex = billingList.findIndex((obj) => obj.plant_no == plant_no);
    // console.log(objIndex);

    // Edit Object in Array
    let arrBillingList = [...billingList];
    console.log(event.target.value);
    arrBillingList[objIndex] = { ...arrBillingList[objIndex], invoice_number: event.target.value };
    setBillingList(arrBillingList);
  };

  const handleChangeInvoiceDate = (newValue, plant_no) => {
    // console.log(newValue);
    // console.log(billingList);

    const dValue = newValue.toDate();

    const gDay = dValue.getDate();
    const gMonth = dValue.getMonth();
    const gYear = dValue.getFullYear();

    let objIndex = billingList.findIndex((obj) => obj.plant_no == plant_no);
    // console.log(objIndex);

    // Edit Object in Array

    let arrBillingList = [...billingList];
    arrBillingList[objIndex] = {
      ...arrBillingList[objIndex],
      invoice_date: isDate(dValue) ? new Date(gYear, gMonth, gDay, 12, 0, 0) : null
    };
    setBillingList(arrBillingList);
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElD, setAnchorElD] = useState(null);
  const [popupPlan, setPopupPlan] = useState('');
  const printOpen = Boolean(anchorEl);
  const printDetailOpen = Boolean(anchorElD);
  const StyledMenu = styled((props) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      {...props}
    />
  ))(({ theme }) => ({
    '& .MuiPaper-root': {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      minWidth: 180,
      color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
      boxShadow:
        'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
      '& .MuiMenu-list': {
        padding: '4px 0'
      },
      '& .MuiMenuItem-root': {
        '& .MuiSvgIcon-root': {
          fontSize: 18,
          color: theme.palette.text.secondary,
          marginRight: theme.spacing(1.5)
        },
        '&:active': {
          backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity)
        }
      }
    }
  }));

  return (
    <Page title="Report Billing | JConnect">
      <Container maxWidth="false">
        {/* <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}> */}
        <Grid container>
          <Grid item lg={4} md={4} xs={6}>
            <Typography variant="h4" gutterBottom>
              รายการวางบิล
            </Typography>
          </Grid>
          <Grid item lg={4} md={4} xs={0}></Grid>
          <Grid item container lg={4} md={4} xs={6} spacing={1}>
            <Grid item lg={6} md={6} xs={12}>
              <Button
                variant="contained"
                // component={RouterLink}
                // to="/dashboard/picking"
                startIcon={<Icon icon={savefill} width={24} height={24} />}
                sx={{ width: '100%' }}
                onClick={async () => {
                  let data = [];

                  billingList.map((row) => {
                    const { plant_no, invoice_number, invoice_date } = row;

                    // if (invoice_number && invoice_date)
                    {
                      // console.log(invoice_number);
                      data.push({
                        plant_no: plant_no,
                        invoice_number: invoice_number,
                        invoice_date: invoice_date
                      });
                    }
                  });

                  // if (data.length) {
                  const gMonth = DateDocumentvalue.getMonth() + 1;
                  const gYear = DateDocumentvalue.getFullYear();
                  httpClient
                    .put(`${server.ORDER_BILLING_URL}/${gMonth}/${gYear}`, data)
                    .then((result) => {
                      Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: 'Save',
                        showConfirmButton: false,
                        timer: 1500
                      });
                    })
                    .catch((err) => {
                      console.log(err);
                    });
                  // } else {
                  //   Swal.fire('กรุณาทำรายการ', '', 'error');
                  // }
                }}
              >
                บันทึกใบกำกับภาษี
              </Button>
            </Grid>
            <Grid item lg={6} md={6} xs={12}>
              <Button
                variant="contained"
                // component={RouterLink}
                // to="/dashboard/picking"
                startIcon={<Icon icon={printerfill} width={24} height={24} />}
                sx={{ width: '100%' }}
                // onClick={async () => {
                //   const pdfWindow = window.open('billing');
                //   const title = 'PDF';
                //   const URI = 'report/billing';
                //   const html = `
                //     <html>
                //       <head>
                //         <title>${title}</title>
                //       </head>
                //       <body>
                //         <iframe src="${apiUrl}/report/billing?pMonth=${
                //     DateDocumentvalue.getMonth() + 1
                //   }&pYear=${DateDocumentvalue.getFullYear()}&pGroup=${plantfilterName}" width="100%" height="99.70%">
                //       </body>
                //     </html>
                //   `;
                //   pdfWindow.document.write(html);
                //   pdfWindow.document.close();
                //   pdfWindow.history.pushState(null, null, URI);
                // }}

                id="basic-button"
                aria-controls={printOpen ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={printOpen ? 'true' : undefined}
                onClick={(event) => {
                  setAnchorEl(event.currentTarget);
                }}
              >
                พิมพ์รายการวางบิล
              </Button>

              <StyledMenu
                id="basic-menu"
                anchorEl={anchorEl}
                open={printOpen}
                onClose={() => {
                  setAnchorEl(null);
                }}
                MenuListProps={{
                  'aria-labelledby': 'basic-button'
                }}
              >
                <MenuItem
                  onClick={async () => {
                    const pdfWindow = window.open('billing');
                    const title = 'PDF';
                    const URI = 'report/billing';
                    const html = `
                    <html>
                      <head>
                        <title>${title}</title>
                      </head>
                      <body>
                        <iframe src="${apiUrl}/report/billing?pMonth=${
                      DateDocumentvalue.getMonth() + 1
                    }&pYear=${DateDocumentvalue.getFullYear()}&pGroup=${plantfilterName}" width="100%" height="99.70%">
                      </body>
                    </html>
                  `;
                    pdfWindow.document.write(html);
                    pdfWindow.document.close();
                    pdfWindow.history.pushState(null, null, URI);

                    setAnchorEl(null);
                  }}
                >
                  PDF File
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    window.location.href = `${apiUrl}/report/billing/report_billing.xlsx?pMonth=${
                      DateDocumentvalue.getMonth() + 1
                    }&pYear=${DateDocumentvalue.getFullYear()}&pGroup=${plantfilterName}`;

                    setAnchorEl(null);
                  }}
                >
                  Excle File
                </MenuItem>
              </StyledMenu>
            </Grid>
          </Grid>
        </Grid>
        {/* </Stack> */}

        <Card>
          {plantList && (
            <>
              <BillingListToolbar
                numSelected={null}
                filterName={null}
                onFilterName={null}
                plantfilterName={plantfilterName}
                plantonFilterName={handleplantFilterByName}
                plant={plantList}
                DateDocumentChange={handleDateDocumentChange}
                DateDocumentvalue={DateDocumentvalue}
              />
              <Scrollbar>
                <TableContainer sx={{ minWidth: 500 }}>
                  <Table>
                    <BillingListHead
                      order={null}
                      orderBy={null}
                      headLabel={TABLE_HEAD}
                      rowCount={billingList.length}
                      numSelected={null}
                      onRequestSort={null}
                      onSelectAllClick={null}
                    />
                    <TableBody>
                      {billingList &&
                        isArray(billingList) &&
                        billingList.map((row) => {
                          const {
                            plant_no,
                            order_month_year,
                            invoice_number,
                            invoice_date,
                            amount,
                            amount_total
                          } = row;

                          // console.log(row);

                          return (
                            <TableRow>
                              <TableCell align="left">{plant_no}</TableCell>
                              <TableCell align="left">
                                <TextField
                                  value={invoice_number ? invoice_number : ''}
                                  onChange={(e) => handleChangeInvoiceNumber(e, plant_no)}
                                  align="right"
                                  // sx={{ Width: '100%' }}
                                ></TextField>
                              </TableCell>
                              <TableCell align="left">
                                {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
                                  <DesktopDatePicker
                                    inputFormat="dd/MM/yyyy"
                                    value={invoice_date}
                                    onChange={(e) => handleChangeInvoiceDate(e, plant_no)}
                                    renderInput={(params) => <TextField {...params} />}
                                    // readOnly={true}
                                  />
                                </LocalizationProvider> */}

                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                  <DatePicker
                                    inputFormat="DD/MM/YYYY"
                                    value={invoice_date}
                                    onChange={(e) => handleChangeInvoiceDate(e, plant_no)}
                                    renderInput={(params) => (
                                      <TextField {...params} helperText={null} />
                                    )}
                                    // readOnly={true}
                                  />
                                </LocalizationProvider>
                              </TableCell>
                              <TableCell align="left">{fCurrency(amount)}</TableCell>
                              <TableCell align="left">{fCurrency(amount_total)}</TableCell>

                              <TableCell align="right" width={'280 px'}>
                                <Button
                                  variant="contained"
                                  startIcon={<Icon icon={printerfill} width={24} height={24} />}
                                  sx={{ width: '100%' }}
                                  // onClick={async () => {
                                  //   const pdfWindow = window.open('billing detail');
                                  //   const title = 'PDF';
                                  //   const URI = 'report/billing/detail';
                                  //   const html = `
                                  //         <html>
                                  //           <head>
                                  //             <title>${title}</title>
                                  //           </head>
                                  //           <body>
                                  //             <iframe src="${apiUrl}/report/billing/detail?pMonth=${
                                  //     DateDocumentvalue.getMonth() + 1
                                  //   }&pYear=${DateDocumentvalue.getFullYear()}&pPlant=${plant_no}" width="100%" height="99.70%">
                                  //           </body>
                                  //         </html>
                                  //       `;
                                  //   pdfWindow.document.write(html);
                                  //   pdfWindow.document.close();
                                  //   pdfWindow.history.pushState(null, null, URI);
                                  // }}

                                  id="basic-button-detail"
                                  aria-controls={printDetailOpen ? 'basic-menu-detail' : undefined}
                                  aria-haspopup="true"
                                  aria-expanded={printDetailOpen ? 'true' : undefined}
                                  onClick={(event) => {
                                    setAnchorElD(event.currentTarget);
                                    setPopupPlan(plant_no);
                                  }}
                                >
                                  รายการเบิกสินค้าประจำเดือน
                                </Button>

                                <StyledMenu
                                  id="basic-menu-detail"
                                  anchorEl={anchorElD}
                                  open={printDetailOpen}
                                  onClose={() => {
                                    setAnchorElD(null);
                                    setPopupPlan('');
                                  }}
                                  MenuListProps={{
                                    'aria-labelledby': 'basic-button-detail'
                                  }}
                                >
                                  <MenuItem
                                    onClick={async () => {
                                      const pdfWindow = window.open('billing detail');
                                      const title = 'PDF';
                                      const URI = 'report/billing/detail';
                                      const html = `
                                            <html>
                                              <head>
                                                <title>${title}</title>
                                              </head>
                                              <body>
                                                <iframe src="${apiUrl}/report/billing/detail?pMonth=${
                                        DateDocumentvalue.getMonth() + 1
                                      }&pYear=${DateDocumentvalue.getFullYear()}&pPlant=${popupPlan}" width="100%" height="99.70%">
                                              </body>
                                            </html>
                                          `;
                                      pdfWindow.document.write(html);
                                      pdfWindow.document.close();
                                      pdfWindow.history.pushState(null, null, URI);
                                    }}
                                  >
                                    PDF File
                                  </MenuItem>
                                  <MenuItem
                                    onClick={() => {
                                      window.location.href = `${apiUrl}/report/billing/detail/report_billing_detail.xlsx?pMonth=${
                                        DateDocumentvalue.getMonth() + 1
                                      }&pYear=${DateDocumentvalue.getFullYear()}&pPlant=${popupPlan}`;

                                      setAnchorElD(null);
                                    }}
                                  >
                                    Excle File
                                  </MenuItem>
                                </StyledMenu>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>
                    {isUserNotFound && (
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                            <SearchNotFound searchQuery={''} />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
              </Scrollbar>
            </>
          )}
        </Card>
      </Container>
    </Page>
  );
}

const mapStateToProps = ({ pantryReducer, plantReducer }) => ({
  pantryReducer,
  plantReducer
});

const mapDispatchToProps = {
  ...PlantAction,
  ...PantryAction
};

export default connect(mapStateToProps, mapDispatchToProps)(Billing);
