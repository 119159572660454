/* eslint-disable */
import * as React from 'react';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import * as actions from '../actions/pantry.action';
import * as PlantAction from '../actions/plant.action';
import {
  Box,
  Grid,
  Card,
  Button,
  Divider,
  Container,
  Typography,
  TextField,
  MenuItem,
  Stack
} from '@mui/material';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';

import Page from '../components/Page';
import Swal from 'sweetalert2';

// ----------------------------------------------------------------------

export function PantryProduct(props) {
  const [plantList, setPlantList] = useState(null);
  const navigate = useNavigate();
  const aStatus = [
    {
      value: 'A',
      label: 'ใช้งาน '
    },
    {
      value: 'I',
      label: ' ไม่ใช้งาน'
    }
  ];

  const [status, setStatus] = React.useState('A');
  const [plantNo, setPlantNo] = React.useState('');
  const [pantryNo, setPantryNo] = React.useState('');
  const [pantryDetails, setPantryDetails] = React.useState('');

  useEffect(() => {
    props.getPlants();
  }, []);

  useEffect(() => {
    const { result } = props.plantReducer;
    // console.log(result);
    if (result) setPlantList(result);
  }, [props.plantReducer]);

  const { id } = useParams();

  if (id) {
    // alert(id);

    useEffect(() => {
      props.getPantryById(id);
    }, []);

    useEffect(() => {
      const { result } = props.pantryReducer;

      if (result) {
        setPantryNo(result.pantry_no);
        setPlantNo(result.plant_no);
        setPantryDetails(result.pantry_details);
        setStatus(result.status);
      }
    }, [props.pantryReducer]);
  }
  const handleChangeStatus = (event) => {
    setStatus(event.target.value);
  };

  const handleChangePlantNo = (event) => {
    setPlantNo(event.target.value);
  };
  const handleChangePantryNo = (event) => {
    setPantryNo(event.target.value);
  };

  const handleChangePantryDetails = (event) => {
    setPantryDetails(event.target.value);
  };

  return (
    <Page title="Pantry | JConnect">
      <Container>
        <Card>
          <Grid container spacing={2} sx={{ p: 3 }}>
            <Grid item container spacing={2} lg={12} md={12} xs={12}>
              <Grid item lg={12} md={12} xs={12}>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  sx={{ mb: 1 }}
                >
                  <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                    เพิ่มตึก
                  </Typography>
                </Stack>
              </Grid>
              <Grid item lg={12} md={12} xs={12}>
                <Divider />
              </Grid>
            </Grid>

            <Grid item container spacing={2} lg={12} md={12} xs={12}>
              <Grid item lg={12} md={12} xs={12}>
                <TextField
                  id="plantNo"
                  label="พื้นที่"
                  select
                  value={plantNo}
                  onChange={handleChangePlantNo}
                  sx={{ width: '100%' }}
                >
                  {plantList &&
                    plantList.map((option) => (
                      <MenuItem key={option.id} value={option.plant_no}>
                        {option.plant_no}
                      </MenuItem>
                    ))}
                </TextField>
              </Grid>
              <Grid item lg={12} md={12} xs={12}>
                <TextField
                  sx={{ width: '100%' }}
                  label="ตึก"
                  id="pantryno"
                  value={pantryNo}
                  onChange={handleChangePantryNo}
                ></TextField>
              </Grid>
              <Grid item lg={12} md={12} xs={12}>
                <TextField
                  sx={{ width: '100%' }}
                  label="รายละเอียด"
                  id="pantrydetails"
                  value={pantryDetails}
                  onChange={handleChangePantryDetails}
                />
              </Grid>
              <Grid item lg={6} md={6} xs={12}>
                <TextField
                  id="status"
                  select
                  label="สถานะ"
                  value={status}
                  onChange={handleChangeStatus}
                  sx={{ width: '100%' }}
                >
                  {aStatus.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>

            <Grid
              item
              container
              spacing={2}
              lg={12}
              md={12}
              xs={12}
              sx={{
                display: 'flex',
                flexDirection: 'row-reverse'
              }}
            >
              <Button
                variant="contained"
                component={RouterLink}
                to="/dashboard/pantry"
                sx={{ mt: 2 }}
              >
                ยกเลิก
              </Button>
              <Button
                variant="contained"
                sx={{ mr: 2, mt: 2 }}
                onClick={() => {
                  let data = new FormData();
                  data.append('plant_no', plantNo);
                  data.append('pantry_no', pantryNo);
                  data.append('pantry_details', pantryDetails);
                  data.append('status', status);

                  if (id) {
                    data.append('id', id);
                    props.putPantrys(navigate, data);
                  } else props.postPantrys(navigate, data);

                  // const postplant = props.postPlants(navigate, data);
                  // postplant(dispatch);

                  Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: 'Save',
                    showConfirmButton: false,
                    timer: 1500
                  });
                }}
              >
                บันทึก
              </Button>
            </Grid>
          </Grid>
        </Card>
      </Container>
    </Page>
  );
}

const mapStateToProps = ({ pantryReducer, plantReducer }) => ({ pantryReducer, plantReducer });

const mapDispatchToProps = {
  ...actions,
  ...PlantAction
};

export default connect(mapStateToProps, mapDispatchToProps)(PantryProduct);
