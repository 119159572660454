/* eslint-disable */
import {
  HTTP_PANTRY_SUCCESS,
  HTTP_PANTRY_FETCHING,
  HTTP_PANTRY_FAILED,
  server,
  OK,
  YES
} from '../constants';
import { httpClient } from '../utils/HttpClient';

const setStatePantryToSuccess = (payload) => ({
  type: HTTP_PANTRY_SUCCESS,
  payload
});

const setStatePantryToFetching = () => ({
  type: HTTP_PANTRY_FETCHING
});

const setStatePantryToFailed = () => ({
  type: HTTP_PANTRY_FAILED
});

export const getPantrys = () => {
  return async (dispatch) => {
    dispatch(setStatePantryToFetching());
    await doGetPantrys(dispatch);
  };
};

const doGetPantrys = async (dispatch) => {
  try {
    let result = await httpClient.get(server.PANTRY_URL);
    //console.log(' *** doGetPlants >> ', result);
    dispatch(setStatePantryToSuccess(result.data));
  } catch (error) {
    alert(JSON.stringify(error));
    dispatch(setStatePantryToFailed());
  }
};

export const deletePantrys = (id) => {
  return async (dispatch) => {
    dispatch(setStatePantryToFetching());
    await httpClient.delete(`${server.PANTRY_URL}/${id}`);
    await doGetPantrys(dispatch);
  };
};

export const getPantryById = (id) => {
  return (dispatch) => {
    // dispatch(finishInitialization(false))
    dispatch(setStatePantryToFetching());
    httpClient
      .get(`${server.PANTRY_URL}/${id}`)
      .then((result) => {
        dispatch(setStatePantryToSuccess(result.data));
      })
      .catch((err) => {
        console.log(err);
        dispatch(setStatePantryToFailed());
      });
  };
};

export const getPantryByPlantNo = (pno) => {
  return (dispatch) => {
    if (pno && pno != '') {
      if (pno == '%') pno = '';
      dispatch(setStatePantryToFetching());
      httpClient
        .get(`${server.PANTRY_URL}/plant/${pno}`)
        .then((result) => {
          // console.log(result.data);
          dispatch(setStatePantryToSuccess(result.data));
        })
        .catch((err) => {
          // console.log(err);
          dispatch(setStatePantryToFailed());
        });
    }
  };
};

export const postPantrys = (navigate, data) => {
  return async (dispatch) => {
    dispatch(setStatePantryToFetching());
    let result = await httpClient.post(server.PANTRY_URL, data);
    console.log(result);

    if (result.data.result == OK) {
      navigate('/dashboard/pantry', { replace: true });
      dispatch(setStatePantryToSuccess(result));
    } else {
      dispatch(setStatePantryToFailed());
    }
  };
};

export const putPantrys = (navigate, data) => {
  return async (dispatch) => {
    dispatch(setStatePantryToFetching());
    let result = await httpClient.put(server.PANTRY_URL, data);
    if (result.data.result == OK) {
      navigate('/dashboard/pantry', { replace: true });
      dispatch(setStatePantryToSuccess(result));
    } else {
      dispatch(setStatePantryToFailed());
    }
  };
};
