/* eslint-disable */
import * as React from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { filter, isArray } from 'lodash';
import { Icon } from '@iconify/react';
import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import * as actions from '../actions/order.action';
import * as PlantAction from '../actions/plant.action';
import * as PantryAction from '../actions/pantry.action';
import { server, selQTY, imageUrlReceived } from '../constants';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import {
  Box,
  Table,
  Grid,
  Card,
  Paper,
  Button,
  Checkbox,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Toolbar,
  TextField,
  MenuItem,
  FormControl,
  OutlinedInput,
  Stack
} from '@mui/material';
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import { UserMoreMenu } from '../components/_dashboard/user';
import { CheckVendorListHead } from '../components/_dashboard/checkvendor';
import CheckVendorList from '../_mocks_/checkvendor';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import Swal from 'sweetalert2';
import { useConfirm } from '../hooks/useConfirm';
import { fCurrency } from '../utils/formatNumber';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'product_name', label: 'รายการสินค้า', alignRight: false },
  { id: 'product_unit', label: 'หน่วย', alignRight: false },
  { id: 'qty', label: 'จำนวน', alignRight: false },
  { id: 'product_price', label: 'ราคา/หน่วย', alignRight: false },
  { id: 'product_total', label: 'รวม', alignRight: false }
];

const TABLE_HEAD_NO_PRICE = [
  { id: 'product_code', label: 'รหัสสินค้า', alignRight: false },
  { id: 'product_name', label: 'ชื่อสินค้า', alignRight: false },
  { id: 'product_unit', label: 'หน่วย', alignRight: false },
  // { id: 'balance', label: 'คงเหลือ', alignRight: false },
  { id: 'qty', label: 'จำนวน', alignRight: false }
  // { id: '' }
];

// ----------------------------------------------------------------------

// function descendingComparator(a, b, orderBy) {
//   if (b[orderBy] < a[orderBy]) {
//     return -1;
//   }
//   if (b[orderBy] > a[orderBy]) {
//     return 1;
//   }
//   return 0;
// }

// function getComparator(order, orderBy) {
//   return order === 'desc'
//     ? (a, b) => descendingComparator(a, b, orderBy)
//     : (a, b) => -descendingComparator(a, b, orderBy);
// }

// function applySortFilter(array, comparator, query) {
//   const stabilizedThis = dataFilter.map((el, index) => [el, index]);
//   stabilizedThis.sort((a, b) => {
//     const order = comparator(a[0], b[0]);
//     if (order !== 0) return order;
//     return a[1] - b[1];
//   });
//   if (query) {
//     dataFilter = filter(dataFilter, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
//   }
//   return stabilizedThis.map((el) => el[0]);
// }

export function CheckVendor(props) {
  const pageDatas = useConfirm();
  const [plantList, setPlantList] = useState(null);
  const [pantryList, setPantryList] = useState(null);
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(
    parseInt(localStorage.getItem(server.ROWS_PER_PAGE))
  );

  const showPlant = localStorage.getItem(server.LOGIN_PLANT);
  const showPantry = localStorage.getItem(server.LOGIN_PANTRY);

  const [imageShow, setImageShow] = useState(null);
  const [signatureImage, setSignatureImage] = useState(null);

  useEffect(() => {
    props.getPlants();
  }, []);

  useEffect(() => {
    props.getPantryByPlantNo(pageDatas.plantNo);
  }, [pageDatas.plantNo]);

  useEffect(() => {
    props.getPantrys();
  }, []);

  useEffect(() => {
    const { result } = props.plantReducer;
    if (result) setPlantList(result);
  }, [props.plantReducer]);

  useEffect(() => {
    const { result } = props.pantryReducer;
    if (result) setPantryList(result);
  }, [props.pantryReducer]);

  const { id } = useParams();

  if (id) {
    // alert(id);

    useEffect(() => {
      if (pageDatas.isPerState == '') props.getOrderById(id);
    }, []);

    useEffect(() => {
      if (pageDatas.isPerState == '') {
        const { result } = props.orderReducer;

        if (result) {
          pageDatas.setPlantNo(result.plant_no);
          pageDatas.setPantryNo(result.pantry_no);
          pageDatas.setOrderNumber(result.order_number);
          pageDatas.setOrderDate(result.order_date);
          pageDatas.setOrderBys(result.order_by);
          pageDatas.setStatus(result.status);
          setSignatureImage(result.signature_image);
          pageDatas.setDeliverVerifyDate(result.deliver_verify_date);
        }
      }
    }, [props.orderReducer]);

    useEffect(() => {
      if (pageDatas.isPerState == '') props.getOrderDTById(id);
    }, []);

    useEffect(() => {
      if (pageDatas.isPerState == '') {
        const { result } = props.orderdtReducer;

        if (result && isArray(result)) {
          pageDatas.setOrderDT(result);
        }
      }
    }, [props.orderdtReducer]);

    useEffect(() => {
      if (signatureImage) {
        if (signatureImage != '-' && signatureImage != '') {
          setImageShow(`${imageUrlReceived}/${signatureImage}`);
        }
      }
    }, [signatureImage]);
  }

  pageDatas.setIsPerState('');

  const handleChangePlantNo = (event) => {
    pageDatas.setPlantNo(event.target.value);
    pageDatas.setPantryNo('');
  };

  const handleChangePantryNo = (event) => {
    pageDatas.setPantryNo(event.target.value);
  };

  const handleChangeOrderNumber = (event) => {
    pageDatas.setOrderNumber(event.target.value);
  };

  const handleChangeOrderDate = (event) => {
    pageDatas.setOrderDate(event.target.value);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = CheckVendorList.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  // const handleChangeQty = (event, id) => {
  //   let objIndex = orderDT.findIndex((obj) => obj.id == id);
  //   // Edit Object in Array
  //   let arrProductList = [...orderDT];
  //   arrProductList[objIndex] = { ...arrProductList[objIndex], qty: event.target.value };
  //   setOrderDT(arrProductList);
  // };

  // const handleClick = (event, name) => {
  //   const selectedIndex = selected.indexOf(name);
  //   let newSelected = [];
  //   if (selectedIndex === -1) {
  //     newSelected = newSelected.concat(selected, name);
  //   } else if (selectedIndex === 0) {
  //     newSelected = newSelected.concat(selected.slice(1));
  //   } else if (selectedIndex === selected.length - 1) {
  //     newSelected = newSelected.concat(selected.slice(0, -1));
  //   } else if (selectedIndex > 0) {
  //     newSelected = newSelected.concat(
  //       selected.slice(0, selectedIndex),
  //       selected.slice(selectedIndex + 1)
  //     );
  //   }
  //   setSelected(newSelected);
  // };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    localStorage.setItem(server.ROWS_PER_PAGE, event.target.value);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - pageDatas.orderDT.length) : 0;

  // const filteredUsers = applySortFilter(CheckVendorList, getComparator(order, orderBy), filterName);

  // const isUserNotFound = filteredUsers.length === 0;

  const [DateDocumentvalue, setDateDocumentValue] = React.useState();

  const handleDateDocumentChange = (newValue) => {
    setDateDocumentValue(newValue);
  };

  // console.log(pageDatas.orderDT);

  return (
    <Page title="Checkorder | JConnect">
      <Container maxWidth="false">
        {/* <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}> */}
        <Stack mb={5}>
          <Grid container>
            <Grid item lg={4} md={4} xs={6}>
              <Typography variant="h4" gutterBottom>
                รับใบสั่งสินค้า
              </Typography>
            </Grid>
            <Grid item lg={4} md={4} xs={0}></Grid>
            {pageDatas.status !== 'S' && (
              <Grid item container lg={4} md={4} xs={6} spacing={2}>
                <Grid item lg={6} md={6} xs={12}>
                  <Button
                    variant="contained"
                    sx={{ width: '100%' }}
                    startIcon={<Icon icon={plusFill} />}
                    onClick={() => {
                      if (pageDatas.plantNo != '' && pageDatas.pantryNo != '') {
                        pageDatas.setIsPerState(id);
                        //   // pageDatas.setIsState('P');
                        navigate('/dashboard/checkvendorpopup', { replace: true });
                      }
                    }}
                  >
                    เพิ่มและแก้ไขสินค้า
                  </Button>
                </Grid>

                <Grid item lg={6} md={6} xs={12}>
                  <Button
                    variant="contained"
                    sx={{ width: '100%' }}
                    onClick={() => {
                      let data = [];

                      data.push({
                        id: id,
                        plant_no: pageDatas.plantNo,
                        pantry_no: pageDatas.pantryNo,
                        order_number: pageDatas.orderNumber,
                        status: 'V',
                        order_verify_by: localStorage.getItem(server.LOGIN_USERNAME),
                        order_verify_date: Date()
                      });

                      props.putCheckVendors(navigate, data, pageDatas.orderDT);

                      Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: 'Save',
                        showConfirmButton: false,
                        timer: 1500
                      });
                    }}
                  >
                    ยืนยันคำสั่งสินค้า
                  </Button>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Stack>

        <Grid container spacing={2} sx={{ mt: 3 }}>
          <Grid item lg={4} md={6} xs={12} container spacing={2}>
            <Grid item lg={12} md={12} xs={12}>
              <Box
                sx={{
                  borderRadius: 2,
                  border: 1,
                  borderColor: 'text.primary',
                  p: 3
                }}
              >
                {pageDatas.plantNo && (
                  <TextField
                    id="plantNo"
                    select
                    // InputProps={{ readOnly: true }}
                    label="พื้นที่"
                    value={pageDatas.plantNo}
                    onChange={handleChangePlantNo}
                    sx={{ width: '100%', mt: 2, mb: 2 }}
                  >
                    {plantList &&
                      plantList.map((option) => {
                        if (showPlant.indexOf(option.plant_no) >= 0 || showPlant.length === 0) {
                          return (
                            <MenuItem key={option.id} value={option.plant_no}>
                              {option.plant_no}
                            </MenuItem>
                          );
                        }
                      })}
                  </TextField>
                )}
                {pageDatas.pantryNo && (
                  <TextField
                    id="pantryNo"
                    select
                    // InputProps={{ readOnly: true }}
                    label="ตึก"
                    value={pageDatas.pantryNo}
                    onChange={handleChangePantryNo}
                    sx={{ width: '100%', mt: 2, mb: 2 }}
                    error={pageDatas.pantryNo == ''}
                    helperText={pageDatas.pantryNo == '' ? 'กรุณาเลือก ตึก' : ''}
                  >
                    {pantryList &&
                      pantryList.map((option) => {
                        if (showPantry.length === 0 || showPantry.indexOf(option.pantry_no) >= 0)
                          return (
                            <MenuItem key={option.id} value={option.pantry_no}>
                              {option.pantry_no}
                            </MenuItem>
                          );
                      })}
                  </TextField>
                )}
              </Box>

              <Box
                sx={{
                  borderRadius: 2,
                  border: 1,
                  borderColor: 'text.primary',
                  p: 3,
                  mt: 2
                }}
              >
                {pageDatas.orderNumber && (
                  <TextField
                    id="orderNumber"
                    label="เลขที่เอกสาร"
                    sx={{ width: '100%', mt: 2, mb: 2 }}
                    value={pageDatas.orderNumber}
                    onChange={handleChangeOrderNumber}
                    InputProps={{ readOnly: true }}
                  ></TextField>
                )}
                {pageDatas.orderDate && (
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Stack>
                      <DatePicker
                        label="วันที่เอกสาร"
                        inputFormat="DD/MM/YYYY"
                        readOnly={true}
                        value={(DateDocumentvalue, pageDatas.orderDate)}
                        onChange={(handleDateDocumentChange, handleChangeOrderDate)}
                        renderInput={(params) => (
                          <TextField {...params} InputProps={{ readOnly: true }} />
                        )}
                      />
                    </Stack>
                  </LocalizationProvider>
                )}
                {/* <TextField
                  id="outlined-select-currency"
                  select
                  label="วันที่เอกสาร"
                  sx={{ width: '100%', mt: 2, mb: 2 }}
                ></TextField> */}
              </Box>
            </Grid>

            {imageShow && signatureImage && (
              <Grid item lg={12} md={12} xs={12}>
                <Box
                  sx={{
                    borderRadius: 2,
                    border: 1,
                    borderColor: 'text.primary',
                    p: 3
                  }}
                >
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Stack sx={{ width: '100%', mt: 2, mb: 2 }}>
                      <DatePicker
                        label="วันที่รับสินค้า"
                        inputFormat="DD/MM/YYYY"
                        readOnly={true}
                        value={(DateDocumentvalue, pageDatas.DeliverVerifyDate)}
                        // onChange={(handleDateDocumentChange, handleChangeOrderDate)}
                        renderInput={(params) => (
                          <TextField {...params} InputProps={{ readOnly: true }} />
                        )}
                      />
                    </Stack>
                  </LocalizationProvider>
                  <img src={imageShow} alt={signatureImage.name} />
                </Box>
              </Grid>
            )}
          </Grid>

          <Grid item lg={8} md={6} xs={12}>
            {localStorage.getItem(server.LOGIN_SHOW_PRICE) === 'A' && (
              <Grid container spacing={2}>
                <Grid item lg={6} md={6} xs={6}>
                  <TextField
                    type="text"
                    label="ยอดรวมรายการ :"
                    value={pageDatas.orderDT.length}
                    variant="outlined"
                    sx={{ width: '100%', mb: 2 }}
                    inputProps={{ readOnly: true }}
                  />
                </Grid>

                <Grid item lg={6} md={6} xs={6}>
                  <TextField
                    type="text"
                    label="ยอดรวมทั้งหมด :"
                    value={fCurrency(
                      isArray(pageDatas.orderDT) &&
                        pageDatas.orderDT.reduce(function (prev, current) {
                          return prev + +(current.product_price * current.qty);
                        }, 0)
                    )}
                    variant="outlined"
                    sx={{ width: '100%', mb: 2 }}
                    inputProps={{ readOnly: true }}
                  />
                </Grid>
              </Grid>
            )}

            <Box
              sx={{
                border: 1,
                borderRadius: 2,
                borderColor: 'text.primary',
                p: 3
              }}
            >
              {pageDatas.orderDT && isArray(pageDatas.orderDT) && (
                <>
                  <Scrollbar>
                    <TableContainer sx={{ minWidth: 350 }}>
                      <Table>
                        <CheckVendorListHead
                          order={order}
                          orderBy={orderBy}
                          headLabel={
                            localStorage.getItem(server.LOGIN_SHOW_PRICE) === 'A'
                              ? TABLE_HEAD
                              : TABLE_HEAD_NO_PRICE
                          }
                          rowCount={pageDatas.orderDT.length}
                          numSelected={selected.length}
                          onRequestSort={handleRequestSort}
                          onSelectAllClick={handleSelectAllClick}
                        />
                        <TableBody>
                          {pageDatas.orderDT // filteredUsers
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row) => {
                              const { id, product_name, product_unit, qty, product_price } = row;
                              const isItemSelected = selected.indexOf(id) !== -1;

                              return (
                                <TableRow
                                  hover
                                  key={id}
                                  tabIndex={-1}
                                  role="checkbox"
                                  selected={isItemSelected}
                                  aria-checked={isItemSelected}
                                >
                                  <TableCell align="left">{product_name}</TableCell>
                                  <TableCell align="left">{product_unit}</TableCell>
                                  {localStorage.getItem(server.LOGIN_SHOW_PRICE) === 'A' && (
                                    <TableCell align="left">{fCurrency(product_price)}</TableCell>
                                  )}
                                  <TableCell align="left">{qty}</TableCell>
                                  {localStorage.getItem(server.LOGIN_SHOW_PRICE) === 'A' && (
                                    <TableCell align="left">
                                      {fCurrency(product_price * qty)}
                                    </TableCell>
                                  )}

                                  {/* <TableCell align="left">
                                  <TextField
                                    inputProps={{
                                      // step: 1,
                                      min: 1,
                                      max: 99999,
                                      type: 'number',
                                      style: { textAlign: 'center' }
                                    }}
                                    id={id}
                                    value={qty}
                                    onChange={(e) => handleChangeQty(e, id)}
                                    align="right"
                                    onWheel={(e) => e.target.blur()}
                                    select={product_name.indexOf('ถุงขยะ') != -1}
                                    sx={{ minWidth: 90 }}
                                  >
                                    {selQTY.map((option) => {
                                      return (
                                        <MenuItem value={option.value}>{option.value}</MenuItem>
                                      );
                                    })}
                                  </TextField>
                                </TableCell> */}
                                </TableRow>
                              );
                            })}
                          {emptyRows > 0 && (
                            <TableRow style={{ height: 53 * emptyRows }}>
                              <TableCell colSpan={6} />
                            </TableRow>
                          )}
                        </TableBody>
                        {/* {isUserNotFound && (
                        <TableBody>
                          <TableRow>
                            <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                              <SearchNotFound searchQuery={filterName} />
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      )} */}
                      </Table>
                    </TableContainer>
                  </Scrollbar>

                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={pageDatas.orderDT.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </>
              )}
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row-reverse',
                  //   p: 1,
                  mt: 2,
                  bgcolor: 'background.paper'
                }}
              >
                {pageDatas.status !== 'S' && (
                  <>
                    <Button
                      variant="contained"
                      component={RouterLink}
                      to={`/dashboard/checkorder/${pageDatas.plantNo}/${pageDatas.orderNumber}/${pageDatas.status}`}
                    >
                      ยกเลิก
                    </Button>
                    <Button
                      variant="contained"
                      sx={{ mr: 2 }}
                      onClick={() => {
                        let data = [];

                        data.push({
                          id: id,
                          plant_no: pageDatas.plantNo,
                          pantry_no: pageDatas.pantryNo,
                          order_number: pageDatas.orderNumber,
                          status: pageDatas.status
                        });

                        props.putCheckVendors(navigate, data, pageDatas.orderDT);

                        Swal.fire({
                          position: 'center',
                          icon: 'success',
                          title: 'Save',
                          showConfirmButton: false,
                          timer: 1500
                        });
                      }}
                    >
                      บันทึก
                    </Button>
                  </>
                )}
                {pageDatas.status == 'S' && (
                  <Button
                    variant="contained"
                    component={RouterLink}
                    to={`/dashboard/checkorder/${pageDatas.plantNo}/${pageDatas.orderNumber}/${pageDatas.status}`}
                  >
                    ปิด
                  </Button>
                )}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}

const mapStateToProps = ({ orderReducer, orderdtReducer, pantryReducer, plantReducer }) => ({
  orderReducer,
  orderdtReducer,
  pantryReducer,
  plantReducer
});

const mapDispatchToProps = {
  ...actions,
  ...PlantAction,
  ...PantryAction
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckVendor);
