/* eslint-disable */
// Login Page
export const APP_INIT = 'APP_INIT';

// Login Page
export const HTTP_LOGIN_FETCHING = 'HTTP_LOGIN_FETCHING';
export const HTTP_LOGIN_SUCCESS = 'HTTP_LOGIN_SUCCESS';
export const HTTP_LOGIN_FAILED = 'HTTP_LOGIN_FAILED';
export const HTTP_LOGIN_CLEAR = 'HTTP_LOGIN_CLEAR';

// Plant Page
export const HTTP_PLANT_FETCHING = 'HTTP_PLANT_FETCHING';
export const HTTP_PLANT_SUCCESS = 'HTTP_PLANT_SUCCESS';
export const HTTP_PLANT_FAILED = 'HTTP_PLANT_FAILED';

// Pantry Page
export const HTTP_PANTRY_FETCHING = 'HTTP_PANTRY_FETCHING';
export const HTTP_PANTRY_SUCCESS = 'HTTP_PANTRY_SUCCESS';
export const HTTP_PANTRY_FAILED = 'HTTP_PANTRY_FAILED';

// Admin Page
export const HTTP_ADMIN_FETCHING = 'HTTP_ADMIN_FETCHING';
export const HTTP_ADMIN_SUCCESS = 'HTTP_ADMIN_SUCCESS';
export const HTTP_ADMIN_FAILED = 'HTTP_ADMIN_FAILED';

// Usergroup Page
export const HTTP_USERGROUP_FETCHING = 'HTTP_USERGROUP_FETCHING';
export const HTTP_USERGROUP_SUCCESS = 'HTTP_USERGROUP_SUCCESS';
export const HTTP_USERGROUP_FAILED = 'HTTP_USERGROUP_FAILED';

// Order Page
export const HTTP_ORDER_FETCHING = 'HTTP_ORDER_FETCHING';
export const HTTP_ORDER_SUCCESS = 'HTTP_ORDER_SUCCESS';
export const HTTP_ORDER_FAILED = 'HTTP_ORDER_FAILED';
export const HTTP_ORDERDT_FETCHING = 'HTTP_ORDERDT_FETCHING';
export const HTTP_ORDERDT_SUCCESS = 'HTTP_ORDERDT_SUCCESS';
export const HTTP_ORDERDT_FAILED = 'HTTP_ORDERDT_FAILED';

// product Page
export const HTTP_PRODUCT_FETCHING = 'HTTP_PRODUCT_FETCHING';
export const HTTP_PRODUCT_SUCCESS = 'HTTP_PRODUCT_SUCCESS';
export const HTTP_PRODUCT_FAILED = 'HTTP_PRODUCT_FAILED';

// deliver Page
export const HTTP_DELIVER_FETCHING = 'HTTP_DELIVER_FETCHING';
export const HTTP_DELIVER_SUCCESS = 'HTTP_DELIVER_SUCCESS';
export const HTTP_DELIVER_FAILED = 'HTTP_DELIVER_FAILED';
export const HTTP_DELIVERDT_FETCHING = 'HTTP_DELIVERDT_FETCHING';
export const HTTP_DELIVERDT_SUCCESS = 'HTTP_DELIVERDT_SUCCESS';
export const HTTP_DELIVERDT_FAILED = 'HTTP_DELIVERDT_FAILED';

// quotation Page
export const HTTP_QUOTATION_FETCHING = 'HTTP_QUOTATION_FETCHING';
export const HTTP_QUOTATION_SUCCESS = 'HTTP_QUOTATION_SUCCESS';
export const HTTP_QUOTATION_FAILED = 'HTTP_QUOTATION_FAILED';
export const HTTP_QUOTATIONDT_FETCHING = 'HTTP_QUOTATIONDT_FETCHING';
export const HTTP_QUOTATIONDT_SUCCESS = 'HTTP_QUOTATIONDT_SUCCESS';
export const HTTP_QUOTATIONDT_FAILED = 'HTTP_QUOTATIONDT_FAILED';

// newpost Page
export const HTTP_NEWPOST_FETCHING = 'HTTP_NEWPOST_FETCHING';
export const HTTP_NEWPOST_SUCCESS = 'HTTP_NEWPOST_SUCCESS';
export const HTTP_NEWPOST_FAILED = 'HTTP_NEWPOST_FAILED';

// Stock Edit Page
export const HTTP_STOCK_EDIT_FETCHING = 'HTTP_STOCK_EDIT_FETCHING';
export const HTTP_STOCK_EDIT_SUCCESS = 'HTTP_STOCK_EDIT_SUCCESS';
export const HTTP_STOCK_EDIT_FAILED = 'HTTP_STOCK_EDIT_FAILED';

// Transaction Edit Page
export const HTTP_TRANSACTION_FETCHING = 'HTTP_TRANSACTION_FETCHING';
export const HTTP_TRANSACTION_SUCCESS = 'HTTP_TRANSACTION_SUCCESS';
export const HTTP_TRANSACTION_FAILED = 'HTTP_TRANSACTION_FAILED';

// Shop Page
// export const HTTP_SHOP_FETCHING = 'HTTP_SHOP_FETCHING';
// export const HTTP_SHOP_SUCCESS = 'HTTP_SHOP_SUCCESS';
// export const HTTP_SHOP_FAILED = 'HTTP_SHOP_FAILED';

// export const SHOP_UPDATE_ORDER = 'SHOP_UPDATE_ORDER';
// export const SHOP_UPDATE_PAYMENT = 'SHOP_UPDATE_PAYMENT';

// Error Code
export const E_PICKER_CANCELLED = 'E_PICKER_CANCELLED';
export const E_PICKER_CANNOT_RUN_CAMERA_ON_SIMULATOR = 'E_PICKER_CANNOT_RUN_CAMERA_ON_SIMULATOR';
export const E_PERMISSION_MISSING = 'E_PERMISSION_MISSING';
export const E_PICKER_NO_CAMERA_PERMISSION = 'E_PICKER_NO_CAMERA_PERMISSION';
export const E_USER_CANCELLED = 'E_USER_CANCELLED';
export const E_UNKNOWN = 'E_UNKNOWN';
export const E_DEVELOPER_ERROR = 'E_DEVELOPER_ERROR';
export const TIMEOUT_NETWORK = 'ECONNABORTED'; // request service timeout
export const NOT_CONNECT_NETWORK = 'NOT_CONNECT_NETWORK';

// //////////////// Localization Begin ////////////////
export const NETWORK_CONNECTION_MESSAGE = 'Cannot connect to server, Please try again.';
export const NETWORK_TIMEOUT_MESSAGE = 'A network timeout has occurred, Please try again.';
export const UPLOAD_PHOTO_FAIL_MESSAGE = 'An error has occurred. The photo was unable to upload.';

import { REACT_APP_DEV_API_URL, REACT_APP_PROD_API_URL } from '../config';
const { REACT_APP_ENV } = process.env;

export const apiUrl = `${
  REACT_APP_ENV == 'production' ? REACT_APP_PROD_API_URL : REACT_APP_DEV_API_URL
}/api/v1`;
export const imageUrl = `${
  REACT_APP_ENV == 'production' ? REACT_APP_PROD_API_URL : REACT_APP_DEV_API_URL
}`;
export const imageUrlPost = `${
  REACT_APP_ENV == 'production' ? REACT_APP_PROD_API_URL : REACT_APP_DEV_API_URL
}/post`;
export const imageUrlReceived = `${
  REACT_APP_ENV == 'production' ? REACT_APP_PROD_API_URL : REACT_APP_DEV_API_URL
}/received`;
export const manualUrl = `${
  REACT_APP_ENV == 'production' ? REACT_APP_PROD_API_URL : REACT_APP_DEV_API_URL
}/manual`;

//
// export const apiUrl = 'http://localhost:8085/api/v1';
// export const imageUrl = 'http://localhost:8085';
// export const imageUrlPost = 'http://localhost:8085/post';
// export const imageUrlReceived = 'http://localhost:8085/received';
// export const manualUrl = 'http://localhost:8085/manual';

export const YES = 'YES';
export const NO = 'NO';
export const OK = 'ok';
export const NOK = 'nok';

export const server = {
  LOGIN_URL: `authen/login`,
  REGISTER_URL: `authen/register`,
  PLANT_URL: `plant/plant`,
  PLANT_DETAIL_URL: `plant/detail`,
  PANTRY_URL: `pantry/pantry`,
  ADMIN_URL: `admin/admin`,
  USERGROUP_URL: `usergroup/usergroup`,
  ORDER_URL: `order/order`,
  ORDER_RESET_URL: `order/reset`,
  ORDER_CONFIRM_URL: `order/confirm`,
  ORDER_CONFIRM_GROUP_PLANT_URL: `order/confirm/plant`,
  ORDER_APPROVE_URL: `order/approve`,
  ORDER_APPROVE_GROUP_PLANT_URL: `order/approveby/plant`,
  ORDER_RECEIVED_URL: `order/received`,
  ORDER_RECEIVED_GROUP_PLANT_URL: `order/received/plant`,
  ORDER_EMAIL_URL: `order/email`,
  ORDER_BILLING_URL: `order/billing`,
  ORDERST_URL: `order/orderST`,
  ORDERDT_URL: `order/orderdt`,
  PRODUCT_URL: `product/product`,
  PRODUCT_CHECKDUP_URL: `product/checkdup`,
  DELIVER_URL: `deliver/deliver`,
  DELIVERDT_URL: `deliver/deliverdt`,
  QUOTATION_URL: `quotation/quotation`,
  QUOTATION__LIST_URL: `quotation/list`,
  QUOTATIONDT_URL: `quotation/quotationdt`,
  NEWPOST_URL: `newpost/newpost`,
  STOCK_URL: `stock/bal`,
  TRANSACTION_URL: `transaction`,
  REPORT_URL: `stock/report`,
  REPORT_BILLING_URL: `report/data/billing`,

  DB_RESTORE_URL: `db/restore`,
  DB_FILE_RESTORE_URL: `db/file_restore`,

  LOGIN_PASSED: `NO`,
  LOGIN_USERNAME: 'LOGIN_USERNAME',
  LOGIN_MENU: 'LOGIN_MENU',

  LOGIN_PLANT: 'LOGIN_PLANT',
  LOGIN_PANTRY: 'LOGIN_PANTRY',
  LOGIN_SHOW_PRICE: 'LOGIN_SHOW_PRICE',

  ROWS_PER_PAGE: 'ROWS_PER_PAGE'
};

export const orderStatus = [
  {
    value: 'A',
    label: 'สั่งสินค้า'
  },
  {
    value: 'P',
    label: 'อนุมัติสั่งสินค้า'
  },
  {
    value: 'V',
    label: 'ยืนยันคำสั่งสินค้า'
  },
  {
    value: 'D',
    label: 'กำลังดำเนินการจัดส่ง'
  },
  // {
  //   value: 'C',
  //   label: 'ส่งสินค้า'
  // },
  {
    value: 'S',
    label: 'จัดส่งสำเร็จ'
  }
];

export const selShowPrice = [
  {
    value: 'A',
    label: 'แสดง '
  },
  {
    value: 'I',
    label: 'ไม่แสดง'
  }
];

export const selType = [
  {
    value: 'GC',
    label: 'GC '
  },
  {
    value: 'JD',
    label: 'Jindapro'
  }
];

const QTY = () => {
  let listQTY = [];
  let i = 0;

  while (i <= 700) {
    listQTY.push({ value: i });
    i = i + 5;
  }

  return listQTY;
};

export const selQTY = QTY();

// [
//   { value: '0' },
//   { value: '5' },
//   { value: '10' },
//   { value: '15' },
//   { value: '20' },
//   { value: '25' },
//   { value: '30' }
// ];
