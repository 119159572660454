/* eslint-disable */
import { filter } from 'lodash';
import { Icon } from '@iconify/react';
import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { useNavigate } from 'react-router-dom';
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination
} from '@mui/material';
// components
import { connect } from 'react-redux';
import * as actions from '../actions/order.action';
import * as PlantAction from '../actions/plant.action';
import * as PantryAction from '../actions/pantry.action';
import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import { UserListHead } from '../components/_dashboard/user';
import { ApproveByMoreMenu, ApproveByListToolbar } from '../components/_dashboard/approveby';
import { fDate, fDateTime } from '../utils/formatTime';

import { orderStatus, server } from '../constants';

import Swal from 'sweetalert2';

import { useApprove } from '../hooks/useApprove';

//
// import ApproveList from '../_mocks_/approve';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'plant_no', label: 'พื้นที่', alignRight: false },
  // { id: 'pantry_no', label: 'ตึก', alignRight: false },
  { id: 'order_number', label: 'เลขที่เอกสาร', alignRight: false },
  { id: 'order_date', label: 'วันที่เอกสาร', alignRight: false },
  // { id: 'order_by', label: 'ผู้สั่งสินค้า', alignRight: false },
  // { id: 'order_approve_by', label: 'ผู้อนุมัติสั่งสินค้า', alignRight: false },
  // { id: 'order_approve_date', label: 'วันที่อนุมัติสั่งสินค้า', alignRight: false },
  { id: 'status', label: 'สถานะ', alignRight: false },
  { id: '' }
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query, plant, status) {
  if (Array.isArray(array)) {
    let dataFilter = [...array];

    console.log(plant);
    console.log(status);

    if (query || plant || status) {
      dataFilter = filter(
        dataFilter,
        (_approve) =>
          (_approve.plant_no.toLowerCase() == plant.toLowerCase() || plant == '' || plant == '%') &&
          (_approve.status.toLowerCase() == status.toLowerCase() || status == '' || status == '%')
      );
    }

    const stabilizedThis = dataFilter.map((el, index) => [el, index]);

    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });

    return stabilizedThis.map((el) => el[0]);
  } else return [];
}

export function Approve(props) {
  const pageDatas = useApprove();
  const navigate = useNavigate();

  const [plantList, setPlantList] = useState(null);
  const [pantryList, setPantryList] = useState([]);
  const [orderList, setOrderList] = useState([]);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(
    parseInt(localStorage.getItem(server.ROWS_PER_PAGE))
  );

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = filteredUsers.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    localStorage.setItem(server.ROWS_PER_PAGE, event.target.value);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const handleplantFilterByName = (event) => {
    pageDatas.setFilterPlant(event.target.value);
    // setStatusFilterName('');
  };

  const handlestatusFilterByName = (event) => {
    pageDatas.setFilterStatus(event.target.value);
  };

  useEffect(() => {
    //fetchData();
    props.getPlants();
  }, []);

  useEffect(() => {
    props.getPantryByPlantNo(pageDatas.filterPlant);
  }, [pageDatas.filterPlant]);

  useEffect(() => {
    const { result } = props.plantReducer;
    // console.log(result);
    if (result) setPlantList(result);
  }, [props.plantReducer]);

  useEffect(() => {
    //fetchData();
    // props.getOrders();
    props.getOrdersApproveGroupPlant();
  }, []);

  useEffect(() => {
    const { result, isPage } = props.orderReducer;

    if (result && isPage == 'APPROVE_LIST') setOrderList(result);
  }, [props.orderReducer]);

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - orderList.length) : 0;

  const filteredUsers =
    orderList != null
      ? applySortFilter(
          orderList,
          getComparator(order, orderBy),
          filterName,
          pageDatas.filterPlant,
          pageDatas.filterStatus
        )
      : [];

  const isUserNotFound = filteredUsers.length === 0;

  return (
    <Page title="Approve | JConnect">
      <Container maxWidth="false">
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            อนุมัติสั่งสินค้า
          </Typography>
          <Button
            variant="contained"
            onClick={async () => {
              let data = [];

              filteredUsers.map((row) => {
                const { id, plant_no, order_number, status } = row;

                if (selected.indexOf(id) !== -1 && status == 'A') {
                  data.push({
                    plant_no: plant_no,
                    order_number: order_number,
                    status: 'P',
                    order_approve_by: localStorage.getItem(server.LOGIN_USERNAME),
                    order_approve_date: Date()
                  });
                }
              });
              if (data.length) {
                await props.putApprove(navigate, data);

                setSelected([]);

                Swal.fire({
                  position: 'center',
                  icon: 'success',
                  title: 'Save',
                  showConfirmButton: false,
                  timer: 1500
                });
              } else {
                Swal.fire('กรุณาเลือกรายการ', '', 'error');
              }

              // let data = new FormData();
              // data.append('status', 'P');
              // data.append('order_approve_by', localStorage.getItem(server.LOGIN_USERNAME));
              // data.append('order_approve_date', Date());
              // data.append('order_number', orderNumber);
              // data.append('plant_no', plantNo);
              // data.append('pantry_no', pantryNo);
              // if (id) {
              //   data.append('id', id);
              //   props.putApprove(navigate, data);
              //   Swal.fire({
              //     position: 'center',
              //     icon: 'success',
              //     title: 'Save',
              //     showConfirmButton: false,
              //     timer: 1500
              //   });
              // }
            }}
          >
            อนุมัติสั่งสินค้า
          </Button>
        </Stack>

        <Card>
          {orderList && plantList && pantryList && (
            <>
              <ApproveByListToolbar
                numSelected={selected.length}
                filterName={filterName}
                onFilterName={handleFilterByName}
                plantfilterName={pageDatas.filterPlant}
                plantonFilterName={handleplantFilterByName}
                plant={plantList}
                pantry={pantryList}
                statusfilterName={pageDatas.filterStatus}
                statusonFilterName={handlestatusFilterByName}
              />

              <Scrollbar>
                <TableContainer sx={{ minWidth: 1100 }}>
                  <Table>
                    <UserListHead
                      order={order}
                      orderBy={orderBy}
                      headLabel={TABLE_HEAD}
                      rowCount={filteredUsers.length}
                      numSelected={selected.length}
                      onRequestSort={handleRequestSort}
                      onSelectAllClick={handleSelectAllClick}
                    />
                    <TableBody>
                      {filteredUsers
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row) => {
                          const {
                            id,
                            plant_no,
                            // pantry_no,
                            order_number,
                            order_date,
                            // order_by,
                            // order_approve_by,
                            // order_approve_date,
                            status
                          } = row;
                          const isItemSelected = selected.indexOf(id) !== -1;

                          return (
                            <TableRow
                              hover
                              key={id}
                              tabIndex={-1}
                              role="checkbox"
                              selected={isItemSelected}
                              aria-checked={isItemSelected}
                            >
                              <TableCell padding="checkbox">
                                <Checkbox
                                  checked={isItemSelected}
                                  onChange={(event) => handleClick(event, id)}
                                />
                              </TableCell>
                              <TableCell align="left">{plant_no}</TableCell>
                              {/* <TableCell align="left">{pantry_no}</TableCell> */}
                              <TableCell align="left">{order_number}</TableCell>
                              <TableCell align="left">{fDate(order_date)}</TableCell>
                              {/* <TableCell align="left">{order_by}</TableCell>
                              <TableCell align="left">{order_approve_by}</TableCell>
                              <TableCell align="left">{fDateTime(order_approve_date)}</TableCell> */}
                              <TableCell align="left">
                                {
                                  orderStatus[orderStatus.findIndex((obj) => obj.value == status)]
                                    .label
                                }
                              </TableCell>

                              <TableCell align="right">
                                <ApproveByMoreMenu
                                  props={props}
                                  plant={plant_no}
                                  order={order_number}
                                  status={status}
                                />
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>
                    {isUserNotFound && (
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                            <SearchNotFound searchQuery={filterName} />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
              </Scrollbar>

              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={filteredUsers.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </>
          )}
        </Card>
      </Container>
    </Page>
  );
}

const mapStateToProps = ({ orderReducer, pantryReducer, plantReducer }) => ({
  orderReducer,
  pantryReducer,
  plantReducer
});

const mapDispatchToProps = {
  ...actions,
  ...PlantAction,
  ...PantryAction
};

export default connect(mapStateToProps, mapDispatchToProps)(Approve);
