import { Icon } from '@iconify/react';
import { useRef, useState } from 'react';
import editFill from '@iconify/icons-eva/edit-fill';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import trash2Outline from '@iconify/icons-eva/trash-2-outline';
import printerfill from '@iconify/icons-eva/printer-fill';
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill';
// material
import { Menu, MenuItem, IconButton, ListItemIcon, ListItemText } from '@mui/material';

import Swal from 'sweetalert2';
import { useQuotation } from '../../../hooks/useQuotation';
import { apiUrl } from '../../../constants';
// ----------------------------------------------------------------------

export default function QuotationMoreMenu({ props, id }) {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  const pageDatas = useQuotation();
  const navigate = useNavigate();

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Icon icon={moreVerticalFill} width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' }
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem
          sx={{ color: 'text.secondary' }}
          onClick={() => {
            setIsOpen(false);
            Swal.fire({
              title: 'Are you sure?',
              text: "You won't be able to Delete!",
              icon: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
              if (result.isConfirmed) {
                // console.log(id);

                props.deleteQuotations(id);

                // Swal.fire('Deleted!', 'Your file has been deleted.', 'success');
              }
            });
          }}
        >
          <ListItemIcon>
            <Icon icon={trash2Outline} width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Delete" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>

        <MenuItem
          // component={RouterLink}
          // to={`/dashboard/quotationproduct/${id}`}
          sx={{ color: 'text.secondary' }}
          onClick={() => {
            pageDatas.setIsState('E');
            navigate(`/dashboard/quotationproduct/${id}`, { replace: true });
          }}
        >
          <ListItemIcon>
            <Icon icon={editFill} width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Edit" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>

        <MenuItem
          sx={{ color: 'text.secondary' }}
          onClick={async () => {
            // const openPDF = () => {
            const pdfWindow = window.open('quotation-report');
            const title = 'PDF';
            const URI = 'quotation/report';
            const html = `
              <html>
                <head>
                  <title>${title}</title>
                </head>
                <body>
                  <iframe src="${apiUrl}/quotation/report?pQuotationID=${id}" width="100%" height="99.70%">
                </body>
              </html>
            `;
            pdfWindow.document.write(html);
            pdfWindow.document.close();
            pdfWindow.history.pushState(null, null, URI);
            // };
          }}
        >
          <ListItemIcon>
            <Icon icon={printerfill} width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Print" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>
      </Menu>
    </>
  );
}
