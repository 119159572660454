/* eslint-disable */
import * as React from 'react';
import { Container, Stack, Typography, Card } from '@mui/material';
import Page from '../components/Page';

import { manualUrl } from '../constants';

export default function ManualUser() {
  var cardStyle = {
    display: 'block',
    transitionDuration: '0.3s',
    height: '39.3vw'
  };
  return (
    <Page title="User Manual | JConnect">
      <Container maxWidth="false">
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            คู่มือการใช้งานระบบสาหรับเจ้าหน้าที่ประจาพื้นที่ และ เจ้าหน้าที่ส่วนกลาง
          </Typography>
        </Stack>
        <Card style={cardStyle} sx={{ mt: 2 }}>
          <iframe
            width="100%"
            height="100%"
            frameBorder="0"
            src={`${manualUrl}/user_manual.pdf`}
          ></iframe>
        </Card>
      </Container>
    </Page>
  );
}
