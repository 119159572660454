/* eslint-disable */
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// material
import { Box, Card, Link, Typography, Stack, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import { connect } from 'react-redux';
import * as actions from '../../../actions/product.action';
// utils
import { fCurrency } from '../../../utils/formatNumber';
//
import Label from '../../Label';
import ColorPreview from '../../ColorPreview';
import { ProductListMoreMenu } from '../../../components/_dashboard/Productlist';
import { imageUrl, server } from '../../../constants';
import { isNull } from 'lodash';

// ----------------------------------------------------------------------

const ProductImgStyle = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute'
});

// ----------------------------------------------------------------------

ProductlistCard.propTypes = {
  product: PropTypes.object
};

console.log('LOGIN_SHOW_PRICE = ' + localStorage.getItem(server.LOGIN_SHOW_PRICE));

export function ProductlistCard({ product, props, type }) {
  const { product_image, product_code, product_price, product_unit, product_name } = product;

  return (
    <Card>
      <Box sx={{ pt: '100%', position: 'relative' }}>
        {type == 'edit' && <ProductListMoreMenu props={props} id={product.id} />}
        <ProductImgStyle
          alt={product_name}
          src={`${imageUrl}/${product_image}?dummy=${Math.random()}`}
        />
      </Box>

      <Stack spacing={2} sx={{ p: 3 }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="subtitle1">{product_code}</Typography>
        </Stack>

        <Link to="#" color="inherit" underline="hover" component={RouterLink}>
          {/* <Typography variant="subtitle2" noWrap> */}
          <Typography variant="subtitle2">{product_name}</Typography>
        </Link>

        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="subtitle1">{product_unit}</Typography>
          {localStorage.getItem(server.LOGIN_SHOW_PRICE) === 'A' && (
            <Typography variant="subtitle1">{fCurrency(product_price)}</Typography>
          )}
        </Stack>
      </Stack>
    </Card>
  );
}
const mapStateToProps = ({ productReducer }) => ({ productReducer });

const mapDispatchToProps = {
  ...actions
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductlistCard);
