const report = [
  {
    id: 1,
    ลำดับ: 1,
    วันที่สั่งสินค้า: '24/11/2021',
    User: 'นาย A',
    รหัสสินค้า: 'K010499',
    รายการสินค้า: 'กาแฟผง',
    ยอดคงเหลือ: '10',
    จำนวนที่เบิก: '20',
    หน่วย: 'ถุง',
    ราคา: '300',
    ราคาไม่รวมVat: '1800',
    บริษัท: 'AR01',
    พื้นที่: 'Admin',
    เลขที่ใบแจ้งหนี้: '2400288580'
  }
];

export default report;
