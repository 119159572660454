/* eslint-disable */
import {
  HTTP_ORDER_FETCHING,
  HTTP_ORDER_SUCCESS,
  HTTP_ORDER_FAILED,
  HTTP_ORDERDT_FETCHING,
  HTTP_ORDERDT_SUCCESS,
  HTTP_ORDERDT_FAILED,
  server,
  OK,
  YES
} from '../constants';
import { httpClient } from '../utils/HttpClient';
import axios from 'axios';

const { REACT_APP_ENV } = process.env;

const setStateOrderToSuccess = (payload, page) => ({
  type: HTTP_ORDER_SUCCESS,
  payload,
  page
});

const setStateOrderToFetching = () => ({
  type: HTTP_ORDER_FETCHING
});

const setStateOrderToFailed = () => ({
  type: HTTP_ORDER_FAILED
});

const setStateOrderDTToSuccess = (payload) => ({
  type: HTTP_ORDERDT_SUCCESS,
  payload
});

const setStateOrderDTToFetching = () => ({
  type: HTTP_ORDERDT_FETCHING
});

const setStateOrderDTToFailed = () => ({
  type: HTTP_ORDERDT_FAILED
});

export const getOrders = () => {
  return async (dispatch) => {
    dispatch(setStateOrderToFetching());
    await doGetOrders(dispatch);
  };
};

const doGetOrders = async (dispatch) => {
  try {
    let result = await httpClient.get(server.ORDER_URL);
    //console.log(' *** doGetPlants >> ', result);
    dispatch(setStateOrderToSuccess(result.data));
  } catch (error) {
    alert(JSON.stringify(error));
    dispatch(setStateOrderToFailed());
  }
};

export const getOrdersConfirm = (plant, order, status) => {
  return async (dispatch) => {
    dispatch(setStateOrderToFetching());
    await doGetOrdersConfirm(dispatch, plant, order, status);
  };
};

const doGetOrdersConfirm = async (dispatch, plant, order, status) => {
  try {
    let result = await httpClient.get(
      `${server.ORDER_CONFIRM_GROUP_PLANT_URL}/${localStorage.getItem(
        server.LOGIN_USERNAME
      )}/${plant}/${order}/${status}`
    );
    //console.log(' *** doGetPlants >> ', result);
    dispatch(setStateOrderToSuccess(result.data, 'CONFIRM'));
  } catch (error) {
    alert(JSON.stringify(error));
    dispatch(setStateOrderToFailed());
  }
};

export const getOrdersConfirmGroupPlant = () => {
  return async (dispatch) => {
    dispatch(setStateOrderToFetching());
    await dogetOrdersConfirmGroupPlant(dispatch);
  };
};

const dogetOrdersConfirmGroupPlant = async (dispatch) => {
  try {
    let result = await httpClient.get(
      `${server.ORDER_CONFIRM_GROUP_PLANT_URL}/${localStorage.getItem(server.LOGIN_USERNAME)}`
    );
    //console.log(' *** doGetPlants >> ', result);
    dispatch(setStateOrderToSuccess(result.data, 'CONFIRM_LIST'));
  } catch (error) {
    alert(JSON.stringify(error));
    dispatch(setStateOrderToFailed());
  }
};

export const getOrderST = () => {
  return async (dispatch) => {
    dispatch(setStateOrderToFetching());
    await dogetOrderST(dispatch);
  };
};

const dogetOrderST = async (dispatch) => {
  try {
    let result = await httpClient.get(
      `${server.ORDERST_URL}/${localStorage.getItem(server.LOGIN_USERNAME)}`
    );
    //console.log(' *** doGetPlants >> ', result);
    dispatch(setStateOrderToSuccess(result.data, 'ORDERST'));
  } catch (error) {
    alert(JSON.stringify(error));
    dispatch(setStateOrderToFailed());
  }
};

export const getOrdersReceived = (plant) => {
  return async (dispatch) => {
    dispatch(setStateOrderToFetching());
    await doGetOrdersReceived(dispatch, plant);
  };
};

const doGetOrdersReceived = async (dispatch, plant) => {
  try {
    let result = await httpClient.get(
      `${server.ORDER_RECEIVED_GROUP_PLANT_URL}/${localStorage.getItem(
        server.LOGIN_USERNAME
      )}/${plant}`
    );
    console.log(' *** doGetPlants >> ', result);
    dispatch(setStateOrderToSuccess(result.data, 'RECEIVED'));
  } catch (error) {
    alert(JSON.stringify(error));
    dispatch(setStateOrderToFailed());
  }
};

export const getOrdersReceivedGroupPlant = () => {
  return async (dispatch) => {
    dispatch(setStateOrderToFetching());
    await doGetOrdersReceivedGroupPlant(dispatch);
  };
};

const doGetOrdersReceivedGroupPlant = async (dispatch) => {
  try {
    let result = await httpClient.get(
      `${server.ORDER_RECEIVED_GROUP_PLANT_URL}/${localStorage.getItem(server.LOGIN_USERNAME)}`
    );
    //console.log(' *** doGetPlants >> ', result);
    dispatch(setStateOrderToSuccess(result.data));
  } catch (error) {
    alert(JSON.stringify(error));
    dispatch(setStateOrderToFailed());
  }
};

export const deleteOrders = (id) => {
  return async (dispatch) => {
    dispatch(setStateOrderToFetching());
    await httpClient.delete(`${server.ORDER_URL}/${id}`);
    await dogetOrderST(dispatch);
  };
};

export const deleteOrdersConfirm = (id, plant, order, status) => {
  return async (dispatch) => {
    dispatch(setStateOrderToFetching());
    await httpClient.delete(`${server.ORDER_URL}/${id}`);
    await doGetOrdersConfirm(dispatch, plant, order, status);
  };
};

export const getOrderById = (id) => {
  return (dispatch) => {
    // dispatch(finishInitialization(false))
    dispatch(setStateOrderToFetching());
    httpClient
      .get(`${server.ORDER_URL}/${id}`)
      .then((result) => {
        dispatch(setStateOrderToSuccess(result.data));
      })
      .catch((err) => {
        console.log(err);
        dispatch(setStateOrderToFailed());
      });
  };
};

export const getOrderDTById = (id) => {
  return (dispatch) => {
    // dispatch(finishInitialization(false))
    dispatch(setStateOrderDTToFetching());
    httpClient
      .get(`${server.ORDERDT_URL}/${id}`)
      .then((result) => {
        dispatch(setStateOrderDTToSuccess(result.data));
      })
      .catch((err) => {
        console.log(err);
        dispatch(setStateOrderDTToFailed());
      });
  };
};

export const postOrders = (navigate, data, datadt) => {
  return async (dispatch) => {
    dispatch(setStateOrderToFetching());
    let result = await httpClient.post(server.ORDER_URL, data);
    // console.log(result);

    const id = JSON.parse(result.data.message).id;

    result = await httpClient.post(`${server.ORDERDT_URL}/${id}`, datadt);

    if (result.data.result == OK) {
      navigate('/dashboard/order', { replace: true });
      dispatch(setStateOrderToSuccess(result));
    } else {
      dispatch(setStateOrderToFailed());
    }
  };
};

export const putOrders = (navigate, data, datadt) => {
  return async (dispatch) => {
    dispatch(setStateOrderToFetching());
    let result = await httpClient.put(server.ORDER_URL, data);

    const id = data.get('id');

    // console.log(data.get('id'));

    result = await httpClient.post(`${server.ORDERDT_URL}/${id}`, datadt);

    if (result.data.result == OK) {
      navigate('/dashboard/order', { replace: true });
      dispatch(setStateOrderToSuccess(result));
    } else {
      dispatch(setStateOrderToFailed());
    }
  };
};

export const putApprove = (navigate, data) => {
  return async (dispatch) => {
    dispatch(setStateOrderToFetching());
    let result = await httpClient.put(server.ORDER_APPROVE_URL, data);
    if (result.data.result == OK) {
      if (data.length == 1 && data[0].pantry_no) {
        navigate(
          `/dashboard/approve/${data[0].plant_no}/${data[0].order_number}/${data[0].status}`,
          { replace: true }
        );
        dispatch(setStateOrderToSuccess(result));
      } else {
        // console.log('dogetOrdersApproveGroupPlant');
        await dogetOrdersApproveGroupPlant(dispatch);
      }

      await data.forEach(async (odt) => {
        let dataEmail = {
          service_id: 'service_ntcf576',
          template_id: 'template_6sxzani',
          user_id: 'user_z77aU5c0h8EJ2OegUv45i',
          template_params: {
            head_mail: `พื้นที่ ${odt.plant_no}${odt.pantry_no ? ' ตึก ' + odt.pantry_no : ''}`,
            from_name: 'jconnect.jindapro.com',
            to_name: 'Jindafood',
            message: `${REACT_APP_ENV == 'production' ? '' : 'DEVELOPMENT'} ${odt.order_number}`
          }
        };

        await axios
          .post('https://api.emailjs.com/api/v1.0/email/send', dataEmail)
          // .then((response) => {
          //   console.log(response.data.url);
          //   console.log(response.data.explanation);
          // })
          .catch((error) => {
            console.log(error);
          });
      });
    } else {
      dispatch(setStateOrderToFailed());
    }
  };
};

export const putCheckVendors = (navigate, data, datadt) => {
  return async (dispatch) => {
    dispatch(setStateOrderToFetching());
    console.log(data);
    let result = await httpClient.put(server.ORDER_CONFIRM_URL, data);

    if (result.data.result == OK) {
      if (data.length == 1 && data[0].pantry_no) {
        const id = data[0].id;
        result = await httpClient.post(`${server.ORDERDT_URL}/${id}`, datadt);
        if (result.data.result == OK) {
          // console.log(`/dashboard/checkorder/${data.get('plant_no')}`);
          navigate(
            `/dashboard/checkorder/${data[0].plant_no}/${data[0].order_number}/${data[0].status}`,
            { replace: true }
          );
          dispatch(setStateOrderToSuccess(result));
        } else {
          dispatch(setStateOrderToFailed());
        }
      } else {
        await dogetOrdersConfirmGroupPlant(dispatch);
      }
    } else {
      dispatch(setStateOrderToFailed());
    }
  };
};

export const putCheckPickups = (navigate, data, datadt) => {
  return async (dispatch) => {
    // dispatch(setStateOrderToFetching());
    let result = await httpClient.put(server.ORDER_URL, data);

    const id = data.get('id');
    result = await httpClient.put(`${server.ORDERDT_URL}/${id}`, datadt);
    if (result.data.result == OK) {
      // navigate(`/dashboard/checkproduct/${data.get('plant_no')}`, { replace: true });
      // dispatch(setStateOrderToSuccess(result));
    } else {
      // dispatch(setStateOrderToFailed());
    }
  };
};

export const putCheckOrder = (data) => {
  return async (dispatch) => {
    dispatch(setStateOrderToFetching());
    let result = await httpClient.put(server.ORDER_URL, data);
    console.log(result);
    if (result) await doGetOrdersConfirm(dispatch);
  };
};

export const getOrdersApprove = (plant, order, status) => {
  return async (dispatch) => {
    dispatch(setStateOrderToFetching());
    await doGetOrdersApprove(dispatch, plant, order, status);
  };
};

const doGetOrdersApprove = async (dispatch, plant, order, status) => {
  try {
    let result = await httpClient.get(
      `${server.ORDER_APPROVE_GROUP_PLANT_URL}/${localStorage.getItem(
        server.LOGIN_USERNAME
      )}/${plant}/${order}/${status}`
    );
    //console.log(' *** doGetPlants >> ', result);
    dispatch(setStateOrderToSuccess(result.data, 'APPROVE'));
  } catch (error) {
    alert(JSON.stringify(error));
    dispatch(setStateOrderToFailed());
  }
};

export const getOrdersApproveGroupPlant = () => {
  return async (dispatch) => {
    dispatch(setStateOrderToFetching());
    await dogetOrdersApproveGroupPlant(dispatch);
  };
};

const dogetOrdersApproveGroupPlant = async (dispatch) => {
  try {
    let result = await httpClient.get(
      `${server.ORDER_APPROVE_GROUP_PLANT_URL}/${localStorage.getItem(server.LOGIN_USERNAME)}`
    );
    //console.log(' *** doGetPlants >> ', result);
    dispatch(setStateOrderToSuccess(result.data, 'APPROVE_LIST'));
  } catch (error) {
    alert(JSON.stringify(error));
    dispatch(setStateOrderToFailed());
  }
};
