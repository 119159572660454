/* eslint-disable */
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
//import { fRequireAuth } from './utils/checkMenu';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//
import Login from './pages/Login';
import Register from './pages/Register';
import DashboardApp from './pages/DashboardApp';
import Products from './pages/Products';
import Blog from './pages/Blog';
import User from './pages/User';
import NotFound from './pages/Page404';
import Order from './pages/Order';
import Approve from './pages/Approve';
import Checkorder from './pages/Checkorder';
import Deliver from './pages/Deliver';
import Checkproduct from './pages/Checkproduct';
import Plant from './pages/Plant';
import Pantry from './pages/Pantry';
import Admin from './pages/Admin';
import Usergroup from './pages/Usergroup';
import OrderProduct from './pages/OrderProduct';
import DeliverProduct from './pages/DeliverProduct';
import ApproveProduct from './pages/ApproveProduct';
import CheckVendor from './pages/CheckVendor';
import CheckPickup from './pages/CheckPickup';
import PlantProduct from './pages/PlantProduct';
import PantrytProduct from './pages/PantryProduct';
import AdminProduct from './pages/AdminProduct';
import UserGroupProduct from './pages/UserGroupProduct';
import OrderPopup from './pages/OrderPopup';
import Stock from './pages/Stock';
import Product from './pages/Product';
import Contact from './pages/Contact';
import Billing from './pages/Billing';
import Picking from './pages/Picking';
import Post from './pages/Post';
import Newpost from './pages/Newpost';
import Report from './pages/Report';
import BackLog from './pages/BackLog';
import Example from './pages/Example';
import CreateProduct from './pages/CreateProduct';
import Quotation from './pages/Quotation';
import QuotationProduct from './pages/QuotationProduct';
import QuotationPopup from './pages/QuotationPopup';
import DeliverPopup from './pages/DeliverPopup';
import CheckorderBy from './pages/CheckorderBy';
import { YES, server } from './constants';
import CheckproductBy from './pages/CheckproductBy';
import ApproveBy from './pages/ApproveBy';
import CheckVendorPopup from './pages/CheckVendorPopup';
import ResetData from './pages/ResetData';
import RecoveryData from './pages/RecoveryData';
import ManualUser from './pages/ManualUser';
import ManualAdmin from './pages/ManualAdmin';

import sidebarConfig from './layouts/dashboard/SidebarConfig';

export default function Router(props) {
  const loginPassed = localStorage.getItem(server.LOGIN_PASSED);
  var showMenu = [];
  if (localStorage.getItem(server.LOGIN_MENU)) {
    showMenu = localStorage.getItem(server.LOGIN_MENU).split(',');
  }
  const menuIndex = sidebarConfig.findIndex((obj) => useLocation().pathname.includes(obj.path));
  const menuName = menuIndex >= 0 ? sidebarConfig[menuIndex].name : '';
  // console.log(showMenu.indexOf(menuName));

  return useRoutes([
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/login" replace /> },
        {
          path: 'app',
          element:
            loginPassed == YES ? (
              showMenu.indexOf(menuName) > -1 ? (
                <DashboardApp />
              ) : (
                <Navigate to="/404" />
              )
            ) : (
              <Navigate to="/login" replace />
            )
        },
        {
          path: 'quotation',
          element:
            loginPassed == YES ? (
              showMenu.indexOf(menuName) > -1 ? (
                <Quotation props />
              ) : (
                <Navigate to="/404" />
              )
            ) : (
              <Navigate to="/login" replace />
            )
        },
        {
          path: 'quotationProduct',
          element:
            loginPassed == YES ? <QuotationProduct props /> : <Navigate to="/login" replace />
        },
        {
          path: 'quotationProduct/:id',
          element:
            loginPassed == YES ? <QuotationProduct props /> : <Navigate to="/login" replace />
        },
        {
          path: 'quotationpopup',
          element: loginPassed == YES ? <QuotationPopup props /> : <Navigate to="/login" replace />
        },
        {
          path: 'user',
          element:
            loginPassed == YES ? (
              showMenu.indexOf(menuName) > -1 ? (
                <User />
              ) : (
                <Navigate to="/404" />
              )
            ) : (
              <Navigate to="/login" replace />
            )
        },
        {
          path: 'products',
          element:
            loginPassed == YES ? (
              showMenu.indexOf(menuName) > -1 ? (
                <Products />
              ) : (
                <Navigate to="/404" />
              )
            ) : (
              <Navigate to="/login" replace />
            )
        },
        {
          path: 'blog',
          element:
            loginPassed == YES ? (
              showMenu.indexOf(menuName) > -1 ? (
                <Blog />
              ) : (
                <Navigate to="/404" />
              )
            ) : (
              <Navigate to="/login" replace />
            )
        },
        {
          path: 'order',
          element:
            loginPassed == YES ? (
              showMenu.indexOf(menuName) > -1 ? (
                <Order props />
              ) : (
                <Navigate to="/404" />
              )
            ) : (
              <Navigate to="/login" replace />
            )
        },
        {
          path: 'approve/:plant/:order/:status',
          element: loginPassed == YES ? <Approve props /> : <Navigate to="/login" replace />
        },
        {
          path: 'approveby',
          element:
            loginPassed == YES ? (
              showMenu.indexOf(menuName) > -1 ? (
                <ApproveBy props />
              ) : (
                <Navigate to="/404" />
              )
            ) : (
              <Navigate to="/login" replace />
            )
        },
        {
          path: 'checkorder/:plant/:order/:status',
          element: loginPassed == YES ? <Checkorder props /> : <Navigate to="/login" replace />
        },
        {
          path: 'checkorderby',
          element:
            loginPassed == YES ? (
              showMenu.indexOf(menuName) > -1 ? (
                <CheckorderBy props />
              ) : (
                <Navigate to="/404" />
              )
            ) : (
              <Navigate to="/login" replace />
            )
        },
        {
          path: 'deliver',
          element:
            loginPassed == YES ? (
              showMenu.indexOf(menuName) > -1 ? (
                <Deliver props />
              ) : (
                <Navigate to="/404" />
              )
            ) : (
              <Navigate to="/login" replace />
            )
        },
        {
          path: 'checkproduct/:plant',
          element: loginPassed == YES ? <Checkproduct props /> : <Navigate to="/login" replace />
        },
        {
          path: 'checkproductby',
          element:
            loginPassed == YES ? (
              showMenu.indexOf(menuName) > -1 ? (
                <CheckproductBy props />
              ) : (
                <Navigate to="/404" />
              )
            ) : (
              <Navigate to="/login" replace />
            )
        },
        {
          path: 'plant',
          element:
            loginPassed == YES ? (
              showMenu.indexOf(menuName) > -1 ? (
                <Plant props />
              ) : (
                <Navigate to="/404" />
              )
            ) : (
              <Navigate to="/login" replace />
            )
        },
        {
          path: 'pantry',
          element:
            loginPassed == YES ? (
              showMenu.indexOf(menuName) > -1 ? (
                <Pantry props />
              ) : (
                <Navigate to="/404" />
              )
            ) : (
              <Navigate to="/login" replace />
            )
        },
        {
          path: 'admin/:type',
          element:
            loginPassed == YES ? (
              showMenu.indexOf(menuName) > -1 ? (
                <Admin props />
              ) : (
                <Navigate to="/404" />
              )
            ) : (
              <Navigate to="/login" replace />
            )
        },
        {
          path: 'adminproduct/:type',
          element: loginPassed == YES ? <AdminProduct props /> : <Navigate to="/login" replace />
        },
        {
          path: 'adminproduct/:type/:id',
          element: loginPassed == YES ? <AdminProduct props /> : <Navigate to="/login" replace />
        },
        {
          path: 'usergroup',
          element:
            loginPassed == YES ? (
              showMenu.indexOf(menuName) > -1 ? (
                <Usergroup props />
              ) : (
                <Navigate to="/404" />
              )
            ) : (
              <Navigate to="/login" replace />
            )
        },
        {
          path: 'orderproduct',
          element: loginPassed == YES ? <OrderProduct props /> : <Navigate to="/login" replace />
        },
        {
          path: 'orderproduct/:id',
          element: loginPassed == YES ? <OrderProduct props /> : <Navigate to="/login" replace />
        },
        {
          path: 'deliverproduct',
          element: loginPassed == YES ? <DeliverProduct props /> : <Navigate to="/login" replace />
        },
        {
          path: 'deliverpopup',
          element: loginPassed == YES ? <DeliverPopup props /> : <Navigate to="/login" replace />
        },
        {
          path: 'deliverproduct/:id',
          element: loginPassed == YES ? <DeliverProduct props /> : <Navigate to="/login" replace />
        },
        {
          path: 'approveproduct',
          element: loginPassed == YES ? <ApproveProduct props /> : <Navigate to="/login" replace />
        },
        {
          path: 'approveproduct/:id',
          element: loginPassed == YES ? <ApproveProduct props /> : <Navigate to="/login" replace />
        },
        {
          path: 'checkvendor',
          element: loginPassed == YES ? <CheckVendor props /> : <Navigate to="/login" replace />
        },
        {
          path: 'checkvendor/:id',
          element: loginPassed == YES ? <CheckVendor props /> : <Navigate to="/login" replace />
        },
        {
          path: 'checkvendorpopup',
          element:
            loginPassed == YES ? <CheckVendorPopup props /> : <Navigate to="/login" replace />
        },
        {
          path: 'checkpickup',
          element: loginPassed == YES ? <CheckPickup props /> : <Navigate to="/login" replace />
        },

        {
          path: 'checkpickup/:id',
          element: loginPassed == YES ? <CheckPickup props /> : <Navigate to="/login" replace />
        },
        {
          path: 'plantproduct',
          element: loginPassed == YES ? <PlantProduct props /> : <Navigate to="/login" replace />
        },
        {
          path: 'plantproduct/:id',
          element: loginPassed == YES ? <PlantProduct props /> : <Navigate to="/login" replace />
        },
        {
          path: 'pantryproduct',
          element: loginPassed == YES ? <PantrytProduct props /> : <Navigate to="/login" replace />
        },
        {
          path: 'pantryproduct/:id',
          element: loginPassed == YES ? <PantrytProduct props /> : <Navigate to="/login" replace />
        },

        {
          path: 'usergroupproduct',
          element:
            loginPassed == YES ? <UserGroupProduct props /> : <Navigate to="/login" replace />
        },
        {
          path: 'usergroupproduct/:id',
          element:
            loginPassed == YES ? <UserGroupProduct props /> : <Navigate to="/login" replace />
        },
        {
          path: 'orderpopup',
          element: loginPassed == YES ? <OrderPopup props /> : <Navigate to="/login" replace />
        },
        {
          path: 'stock',
          element:
            loginPassed == YES ? (
              showMenu.indexOf(menuName) > -1 ? (
                <Stock props />
              ) : (
                <Navigate to="/404" />
              )
            ) : (
              <Navigate to="/login" replace />
            )
        },
        {
          path: 'product/:type',
          element:
            loginPassed == YES ? (
              showMenu.indexOf(menuName) > -1 ? (
                <Product props />
              ) : (
                <Navigate to="/404" />
              )
            ) : (
              <Navigate to="/login" replace />
            )
        },
        {
          path: 'createproduct',
          element: loginPassed == YES ? <CreateProduct props /> : <Navigate to="/login" replace />
        },
        {
          path: 'createproduct/:id',
          element: loginPassed == YES ? <CreateProduct props /> : <Navigate to="/login" replace />
        },
        {
          path: 'contact',
          element:
            loginPassed == YES ? (
              showMenu.indexOf(menuName) > -1 ? (
                <Contact props />
              ) : (
                <Navigate to="/404" />
              )
            ) : (
              <Navigate to="/login" replace />
            )
        },
        {
          path: 'reportbilling',
          element:
            loginPassed == YES ? (
              showMenu.indexOf(menuName) > -1 ? (
                <Billing props />
              ) : (
                <Navigate to="/404" />
              )
            ) : (
              <Navigate to="/login" replace />
            )
        },
        {
          path: 'picking',
          element:
            loginPassed == YES ? (
              showMenu.indexOf(menuName) > -1 ? (
                <Picking props />
              ) : (
                <Navigate to="/404" />
              )
            ) : (
              <Navigate to="/login" replace />
            )
        },
        {
          path: 'newpost',
          element: loginPassed == YES ? <Newpost props /> : <Navigate to="/login" replace />
        },
        {
          path: 'newpost/:type',
          element:
            loginPassed == YES ? (
              showMenu.indexOf(menuName) > -1 ? (
                <Newpost props />
              ) : (
                <Navigate to="/404" />
              )
            ) : (
              <Navigate to="/login" replace />
            )
        },
        {
          path: 'post/:type',
          element: loginPassed == YES ? <Post props /> : <Navigate to="/login" replace />
        },
        {
          path: 'post/:type/:id',
          element: loginPassed == YES ? <Post props /> : <Navigate to="/login" replace />
        },
        {
          path: 'reportsummary',
          element:
            loginPassed == YES ? (
              showMenu.indexOf(menuName) > -1 ? (
                <Report props />
              ) : (
                <Navigate to="/404" />
              )
            ) : (
              <Navigate to="/login" replace />
            )
        },
        {
          path: 'backlog',
          element:
            loginPassed == YES ? (
              showMenu.indexOf(menuName) > -1 ? (
                <BackLog props />
              ) : (
                <Navigate to="/404" />
              )
            ) : (
              <Navigate to="/login" replace />
            )
        },
        {
          path: 'reportorder',
          element:
            loginPassed == YES ? (
              showMenu.indexOf(menuName) > -1 ? (
                <Example props />
              ) : (
                <Navigate to="/404" />
              )
            ) : (
              <Navigate to="/login" replace />
            )
        }
      ]
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'login', element: <Login /> },
        { path: 'register', element: <Register /> },
        { path: '404', element: <NotFound /> },
        { path: '/', element: <Navigate to="/login" /> },
        { path: '*', element: <Navigate to="/404" /> },
        {
          path: 'reset',
          element: <DashboardLayout />,
          children: [
            {
              element:
                loginPassed == YES ? (
                  showMenu.indexOf(menuName) > -1 ? (
                    <ResetData props />
                  ) : (
                    <Navigate to="/404" />
                  )
                ) : (
                  <Navigate to="/login" replace />
                )
            }
          ]
        },
        {
          path: 'recovery',
          element: <DashboardLayout />,
          children: [
            {
              element:
                loginPassed == YES ? (
                  showMenu.indexOf(menuName) > -1 ? (
                    <RecoveryData props />
                  ) : (
                    <Navigate to="/404" />
                  )
                ) : (
                  <Navigate to="/login" replace />
                )
            }
          ]
        },
        {
          path: 'manual_user',
          element: <DashboardLayout />,
          children: [
            {
              element:
                loginPassed == YES ? (
                  showMenu.indexOf(menuName) > -1 ? (
                    <ManualUser props />
                  ) : (
                    <Navigate to="/404" />
                  )
                ) : (
                  <Navigate to="/login" replace />
                )
            }
          ]
        },
        {
          path: 'manual_admin',
          element: <DashboardLayout />,
          children: [
            {
              element:
                loginPassed == YES ? (
                  showMenu.indexOf(menuName) > -1 ? (
                    <ManualAdmin props />
                  ) : (
                    <Navigate to="/404" />
                  )
                ) : (
                  <Navigate to="/login" replace />
                )
            }
          ]
        }
      ]
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}
