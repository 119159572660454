/* eslint-disable */
import { filter } from 'lodash';
import { Icon } from '@iconify/react';
import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// material
import {
  Card,
  Table,
  Stack,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Box,
  Grid
} from '@mui/material';
import { connect } from 'react-redux';
import * as actions from '../actions/deliver.action';
import * as PlantAction from '../actions/plant.action';
import * as PantryAction from '../actions/pantry.action';
// components
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import {
  DeliverListToolbar,
  DeliverMoreMenu,
  DeliverListHead
} from '../components/_dashboard/deliver';
import { fDate, fDateTime } from '../utils/formatTime';
import { useDeliver } from '../hooks/useDeliver';
import { orderStatus, server } from '../constants';
//
// import DeliverList from '../_mocks_/deliver';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'plant_no', label: 'พื้นที่', alignRight: false },
  { id: 'pantry_no', label: 'ตึก', alignRight: false },
  { id: 'deliver_number', label: 'เลขที่เอกสาร', alignRight: false },
  { id: 'deliver_date', label: 'วันที่เอกสาร', alignRight: false },
  { id: 'deliver_purchase', label: 'ผู้สั่งสินค้า', alignRight: false },
  { id: 'deliver_approve', label: 'ผู้รับสินค้า', alignRight: false },
  { id: 'deliver_approve_date', label: 'วันที่รับสินค้า', alignRight: false },
  { id: 'status', label: 'สถานะ', alignRight: false },
  { id: '' }
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query, plant, pantry) {
  if (Array.isArray(array)) {
    let dataFilter = [...array];

    if (query || plant || pantry) {
      dataFilter = filter(
        dataFilter,
        (_deliver) =>
          _deliver.deliver_number.toLowerCase().indexOf(query.toLowerCase()) !== -1 &&
          (_deliver.plant_no.toLowerCase() == plant.toLowerCase() || plant == '') &&
          (_deliver.pantry_no.toLowerCase() == pantry.toLowerCase() || pantry == '')
      );
    }

    const stabilizedThis = dataFilter.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });

    return stabilizedThis.map((el) => el[0]);
  } else return [];
}

export function Deliver(props) {
  const pageDatas = useDeliver();
  const navigate = useNavigate();
  const [plantList, setPlantList] = useState(null);
  const [pantryList, setPantryList] = useState([]);
  const [deliverList, setDeliverList] = useState([]);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [plantfilterName, setPlantFilterName] = useState('');
  const [pantryfilterName, setPantryFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(
    parseInt(localStorage.getItem(server.ROWS_PER_PAGE))
  );

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = deliverList.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    localStorage.setItem(server.ROWS_PER_PAGE, event.target.value);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const handleplantFilterByName = (event) => {
    setPlantFilterName(event.target.value);
    setPantryFilterName('');
  };

  const handlepantryFilterByName = (event) => {
    setPantryFilterName(event.target.value);
  };

  useEffect(() => {
    //fetchData();
    props.getPlants();
  }, []);

  useEffect(() => {
    props.getPantryByPlantNo(plantfilterName);
  }, [plantfilterName]);

  useEffect(() => {
    const { result } = props.pantryReducer;
    if (result && plantfilterName != '') setPantryList(result);
  }, [props.pantryReducer]);

  useEffect(() => {
    const { result } = props.plantReducer;
    if (result) setPlantList(result);
  }, [props.plantReducer]);

  useEffect(() => {
    props.getDelivers();
  }, []);

  useEffect(() => {
    const { result } = props.deliverReducer;

    if (result) setDeliverList(result);
  }, [props.deliverReducer]);

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - deliverList.length) : 0;

  const filteredUsers =
    deliverList != null
      ? applySortFilter(
          deliverList,
          getComparator(order, orderBy),
          filterName,
          plantfilterName,
          pantryfilterName
        )
      : [];

  const isUserNotFound = filteredUsers.length === 0;

  return (
    <Page title="Deliver | JConnect">
      <Container maxWidth="false">
        <Stack mb={5}>
          <Grid container>
            <Grid item lg={4} md={4} xs={6}>
              <Typography variant="h4" gutterBottom>
                ใบส่งสินค้า
              </Typography>
            </Grid>
            <Grid item lg={4} md={4} xs={0}></Grid>
            <Grid item container lg={4} md={4} xs={6} spacing={1}>
              <Grid item lg={6} md={6} xs={12}>
                <Button variant="contained" component={RouterLink} to="#" sx={{ width: '100%' }}>
                  พิมพ์ใบส่งสินค้า
                </Button>
              </Grid>
              <Grid item lg={6} md={6} xs={12}>
                <Button
                  variant="contained"
                  // component={RouterLink}
                  // to="/dashboard/deliverproduct"
                  startIcon={<Icon icon={plusFill} />}
                  onClick={() => {
                    pageDatas.setIsState('A');
                    navigate('/dashboard/deliverproduct', { replace: true });
                  }}
                  sx={{ width: '100%' }}
                >
                  เพิ่มใบส่งสินค้า
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Stack>
        <Card>
          {deliverList && plantList && pantryList && (
            <>
              <DeliverListToolbar
                numSelected={selected.length}
                filterName={filterName}
                onFilterName={handleFilterByName}
                plantfilterName={plantfilterName}
                plantonFilterName={handleplantFilterByName}
                pantryfilterName={pantryfilterName}
                pantryonFilterName={handlepantryFilterByName}
                plant={plantList}
                pantry={pantryList}
              />

              <Scrollbar>
                <TableContainer sx={{ minWidth: 800 }}>
                  <Table>
                    <DeliverListHead
                      order={order}
                      orderBy={orderBy}
                      headLabel={TABLE_HEAD}
                      rowCount={deliverList.length}
                      numSelected={selected.length}
                      onRequestSort={handleRequestSort}
                      onSelectAllClick={handleSelectAllClick}
                    />
                    <TableBody>
                      {filteredUsers
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row) => {
                          const {
                            id,
                            plant_no,
                            pantry_no,
                            deliver_number,
                            deliver_date,
                            deliver_purchase,
                            deliver_approve,
                            deliver_approve_date,
                            status
                          } = row;
                          const isItemSelected = selected.indexOf(id) !== -1;

                          return (
                            <TableRow
                              hover
                              key={id}
                              tabIndex={-1}
                              role="checkbox"
                              selected={isItemSelected}
                              aria-checked={isItemSelected}
                            >
                              <TableCell padding="checkbox">
                                <Checkbox
                                  checked={isItemSelected}
                                  onChange={(event) => handleClick(event, id)}
                                />
                              </TableCell>
                              <TableCell align="left">{plant_no}</TableCell>
                              <TableCell align="left">{pantry_no}</TableCell>
                              <TableCell align="left">{deliver_number}</TableCell>
                              <TableCell align="left">{fDate(deliver_date)}</TableCell>
                              <TableCell align="left">{deliver_purchase}</TableCell>
                              <TableCell align="left">{deliver_approve}</TableCell>
                              <TableCell align="left">{fDateTime(deliver_approve_date)}</TableCell>
                              <TableCell align="left">
                                {
                                  orderStatus[orderStatus.findIndex((obj) => obj.value == status)]
                                    .label
                                }
                              </TableCell>

                              <TableCell align="right">
                                <DeliverMoreMenu props={props} id={id} />
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>
                    {isUserNotFound && (
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                            <SearchNotFound searchQuery={filterName} />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
              </Scrollbar>

              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={deliverList.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </>
          )}
        </Card>
      </Container>
    </Page>
  );
}

const mapStateToProps = ({ deliverReducer, pantryReducer, plantReducer }) => ({
  deliverReducer,
  pantryReducer,
  plantReducer
});

const mapDispatchToProps = {
  ...actions,
  ...PlantAction,
  ...PantryAction
};

export default connect(mapStateToProps, mapDispatchToProps)(Deliver);
