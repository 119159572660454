/* eslint-disable */
import * as React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import searchFill from '@iconify/icons-eva/search-fill';
import trash2Fill from '@iconify/icons-eva/trash-2-fill';
import roundFilterList from '@iconify/icons-ic/round-filter-list';
// material
import { styled } from '@mui/material/styles';
import {
  Box,
  Toolbar,
  Tooltip,
  IconButton,
  Typography,
  OutlinedInput,
  InputAdornment,
  TextField,
  Grid,
  MenuItem
} from '@mui/material';
// ----------------------------------------------------------------------

const RootStyle = styled(Toolbar)(({ theme }) => ({
  // height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(3, 1, 0, 3)
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`
  }
}));

// ----------------------------------------------------------------------

ExampleListToolbar.propTypes = {
  numSelected: PropTypes.number,
  filterName: PropTypes.string,
  onFilterName: PropTypes.func,
  plantfilterName: PropTypes.string,
  plantonFilterName: PropTypes.func,
  pantryfilterName: PropTypes.string,
  pantryonFilterName: PropTypes.func,
  plant: PropTypes.array,
  pantry: PropTypes.array
};

export default function ExampleListToolbar({
  numSelected,
  filterName,
  onFilterName,
  plant,
  pantry,
  plantfilterName,
  plantonFilterName,
  pantryfilterName,
  pantryonFilterName
}) {
  const [DateFromvalue, setDateFromValue] = React.useState();

  const handleDateFromChange = (newValue) => {
    setDateFromValue(newValue);
  };

  const [DateToValue, setDateToValue] = React.useState();

  const handleDateToChange = (newValue) => {
    setDateToValue(newValue);
  };

  return (
    <RootStyle
      sx={{
        ...(numSelected > 0 && {
          color: 'primary.main',
          bgcolor: 'primary.lighter'
        })
      }}
    >
      {numSelected > 0 ? (
        <Typography component="div" variant="subtitle1">
          {numSelected} selected
        </Typography>
      ) : (
        <>
          <Grid container spacing={2}>
            <Grid item container spacing={2} lg={6} md={6} xs={12}>
              <Grid item lg={5.5} md={5.5} xs={5.5}>
                <TextField sx={{ width: '100%' }} select label="รหัสสินค้า"></TextField>
              </Grid>
              <Grid item lg={1} md={1} xs={1}>
                <Typography variant="h6" textAlign="center">
                  ถึง
                </Typography>
              </Grid>
              <Grid item lg={5.5} md={5.5} xs={5.5}>
                <TextField sx={{ width: '100%' }} select label="รหัสสินค้า"></TextField>
              </Grid>
            </Grid>

            <Grid item container spacing={2} lg={6} md={6} xs={12}>
              <Grid item lg={5.5} md={5.5} xs={5.5}>
                <TextField
                  sx={{ width: '100%' }}
                  select
                  label="พื้นที่"
                  value={plantfilterName}
                  onChange={plantonFilterName}
                >
                  {plant.map((option) => (
                    <MenuItem key={option.id} value={option.plant_no}>
                      {option.plant_no}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              <Grid item lg={1} md={1} xs={1}>
                <Typography variant="h6" textAlign="center">
                  ถึง
                </Typography>
              </Grid>

              <Grid item lg={5.5} md={5.5} xs={5.5}>
                <TextField
                  sx={{ width: '100%' }}
                  select
                  label="พื้นที่"
                  value={plantfilterName}
                  onChange={plantonFilterName}
                >
                  {plant.map((option) => (
                    <MenuItem key={option.id} value={option.plant_no}>
                      {option.plant_no}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>

            <Grid item container spacing={2} lg={6} md={6} xs={12}>
              <Grid item lg={5.5} md={5.5} xs={5.5}>
                <TextField
                  sx={{ width: '100%' }}
                  select
                  label="ตึก"
                  value={pantryfilterName}
                  onChange={pantryonFilterName}
                >
                  {pantry &&
                    pantry.length &&
                    pantry.map((option) => (
                      <MenuItem key={option.id} value={option.pantry_no}>
                        {option.pantry_no}
                      </MenuItem>
                    ))}
                </TextField>
              </Grid>

              <Grid item lg={1} md={1} xs={1}>
                <Typography variant="h6" textAlign="center">
                  ถึง
                </Typography>
              </Grid>

              <Grid item lg={5.5} md={5.5} xs={5.5}>
                <TextField
                  sx={{ width: '100%' }}
                  select
                  label="ตึก"
                  value={pantryfilterName}
                  onChange={pantryonFilterName}
                >
                  {pantry &&
                    pantry.length &&
                    pantry.map((option) => (
                      <MenuItem key={option.id} value={option.pantry_no}>
                        {option.pantry_no}
                      </MenuItem>
                    ))}
                </TextField>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </RootStyle>
  );
}
