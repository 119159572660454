/* eslint-disable */
import { filter } from 'lodash';
import { Icon } from '@iconify/react';
import { sentenceCase } from 'change-case';
import { useState } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink } from 'react-router-dom';
// material
import {
  Card,
  Table,
  Stack,
  Grid,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination
} from '@mui/material';
// components
import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import { BackLogListToolbar, BackLogListHead } from '../components/_dashboard/backlog';
//
import BacklogList from '../_mocks_/backlog';
import { orderStatus, server } from '../constants';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'ลำดับ', label: 'ลำดับ', alignRight: false },
  { id: 'วันที่สั่งสินค้า', label: 'วันที่สั่งสินค้า', alignRight: false },
  { id: 'User', label: 'User', alignRight: false },
  { id: 'รหัสสินค้า', label: 'รหัสสินค้า', alignRight: false },
  { id: 'รายการสินค้า', label: 'รายการสินค้า', alignRight: false },
  { id: 'ยอดคงเหลือ', label: 'ยอดคงเหลือ', alignRight: false },
  { id: 'จำนวนที่เบิก', label: 'จำนวนที่เบิก', alignRight: false },
  { id: 'หน่วย', label: 'หน่วย', alignRight: false },
  { id: 'ราคา/หน่วย', label: 'ราคา/หน่วย', alignRight: false },
  { id: 'ราคา(ไม่รวมVat)', label: 'ราคา(ไม่รวมVat)', alignRight: false },
  { id: 'บริษัท', label: 'บริษัท', alignRight: false },
  { id: 'พื้นที่', label: 'พื้นที่', alignRight: false },
  { id: 'เลขที่ใบแจ้งหนี้', label: 'เลขที่ใบแจ้งหนี้', alignRight: false }
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  let dataFilter = [...array];

  if (query) {
    dataFilter = filter(
      dataFilter,
      (_report) => _report.เลขที่เอกสาร.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }

  const stabilizedThis = dataFilter.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  return stabilizedThis.map((el) => el[0]);
}

export default function Checkproduct() {
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(
    parseInt(localStorage.getItem(server.ROWS_PER_PAGE))
  );

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = BacklogList.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    localStorage.setItem(server.ROWS_PER_PAGE, event.target.value);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - BacklogList.length) : 0;

  const filteredUsers = applySortFilter(BacklogList, getComparator(order, orderBy), filterName);

  const isUserNotFound = filteredUsers.length === 0;

  return (
    <Page title="BackLog | JConnect">
      <Container maxWidth="false">
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            รายงาน
          </Typography>
        </Stack>

        <Card>
          <BackLogListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
          />
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <BackLogListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={BacklogList.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredUsers
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      const {
                        id,
                        ลำดับ,
                        วันที่สั่งสินค้า,
                        User,
                        รหัสสินค้า,
                        รายการสินค้า,
                        ยอดคงเหลือ,
                        จำนวนที่เบิก,
                        หน่วย,
                        ราคา,
                        ราคาไม่รวมVat,
                        บริษัท,
                        พื้นที่,
                        เลขที่ใบแจ้งหนี้
                      } = row;
                      const isItemSelected = selected.indexOf(id) !== -1;

                      return (
                        <TableRow
                          hover
                          key={id}
                          tabIndex={-1}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                        >
                          <TableCell align="left">{ลำดับ}</TableCell>
                          <TableCell align="left">{วันที่สั่งสินค้า}</TableCell>
                          <TableCell align="left">{User}</TableCell>
                          <TableCell align="left">{รหัสสินค้า}</TableCell>
                          <TableCell align="left">{รายการสินค้า}</TableCell>
                          <TableCell align="left">{ยอดคงเหลือ}</TableCell>
                          <TableCell align="left">{จำนวนที่เบิก}</TableCell>
                          <TableCell align="left">{หน่วย}</TableCell>
                          <TableCell align="left">{ราคา}</TableCell>
                          <TableCell align="left">{ราคาไม่รวมVat}</TableCell>
                          <TableCell align="left">{บริษัท}</TableCell>
                          <TableCell align="left">{พื้นที่}</TableCell>
                          <TableCell align="left">{เลขที่ใบแจ้งหนี้}</TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={filteredUsers.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </Page>
  );
}
