/* eslint-disable */
import * as React from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { filter, isArray } from 'lodash';
import { Icon } from '@iconify/react';
import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import * as actions from '../actions/order.action';
import * as PlantAction from '../actions/plant.action';
import * as PantryAction from '../actions/pantry.action';
import { server, imageUrlReceived } from '../constants';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import {
  Box,
  Table,
  Grid,
  Card,
  Paper,
  Button,
  Checkbox,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Toolbar,
  TextField,
  MenuItem,
  Stack
} from '@mui/material';
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import { UserMoreMenu } from '../components/_dashboard/user';
import { ApproveProductListHead } from '../components/_dashboard/approveproduct';
// import ApproveproductList from '../_mocks_/approveproduct';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Swal from 'sweetalert2';
import { fCurrency } from '../utils/formatNumber';
import { useConfirm } from '../hooks/useConfirm';
// import emailjs from '@emailjs/browser';
// ----------------------------------------------------------------------

// const { REACT_APP_ENV } = process.env;

const TABLE_HEAD = [
  { id: 'product_name', label: 'รายการสินค้า', alignRight: false },
  { id: 'product_unit', label: 'หน่วย', alignRight: false },
  { id: 'qty', label: 'จำนวน', alignRight: false },
  { id: 'product_price', label: 'ราคา/หน่วย', alignRight: false },
  { id: 'product_total', label: 'รวม', alignRight: false }
  // { id: '' }
];

const TABLE_HEAD_NO_PRICE = [
  { id: 'product_name', label: 'รายการสินค้า', alignRight: false },
  { id: 'product_unit', label: 'หน่วย', alignRight: false },
  { id: 'qty', label: 'จำนวน', alignRight: false }
  // { id: '' }
];

// ----------------------------------------------------------------------

// function descendingComparator(a, b, orderBy) {
//   if (b[orderBy] < a[orderBy]) {
//     return -1;
//   }
//   if (b[orderBy] > a[orderBy]) {
//     return 1;
//   }
//   return 0;
// }

// function getComparator(order, orderBy) {
//   return order === 'desc'
//     ? (a, b) => descendingComparator(a, b, orderBy)
//     : (a, b) => -descendingComparator(a, b, orderBy);
// }

// function applySortFilter(array, comparator, query) {
//   const stabilizedThis = dataFilter.map((el, index) => [el, index]);
//   stabilizedThis.sort((a, b) => {
//     const order = comparator(a[0], b[0]);
//     if (order !== 0) return order;
//     return a[1] - b[1];
//   });
//   if (query) {
//     dataFilter = filter(dataFilter, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
//   }
//   return stabilizedThis.map((el) => el[0]);
// }

export function ApproveProduct(props) {
  const pageDatas = useConfirm();
  const [plantList, setPlantList] = useState(null);
  const [pantryList, setPantryList] = useState(null);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(
    parseInt(localStorage.getItem(server.ROWS_PER_PAGE))
  );
  const [plantNo, setPlantNo] = React.useState('');
  const [pantryNo, setPantryNo] = React.useState('');
  const [orderNumber, setOrderNumber] = React.useState('');
  const [orderDate, setOrderDate] = React.useState('');
  const [orderDT, setOrderDT] = useState([]);
  const [imageShow, setImageShow] = useState(null);
  const [signatureImage, setSignatureImage] = useState(null);
  const [status, setStatus] = React.useState('');

  const navigate = useNavigate();
  const showPlant = localStorage.getItem(server.LOGIN_PLANT);
  const showPantry = localStorage.getItem(server.LOGIN_PANTRY);

  useEffect(() => {
    props.getPlants();
  }, []);

  useEffect(() => {
    if (plantNo && plantNo != '') {
      props.getPantryByPlantNo(plantNo);
    }
  }, [plantNo]);

  useEffect(() => {
    const { result } = props.plantReducer;
    if (result) setPlantList(result);
  }, [props.plantReducer]);

  useEffect(() => {
    const { result } = props.pantryReducer;
    if (result) setPantryList(result);
  }, [props.pantryReducer]);

  const { id } = useParams();

  if (id) {
    // alert(id);

    useEffect(() => {
      props.getOrderById(id);
    }, []);

    useEffect(() => {
      const { result } = props.orderReducer;

      if (result) {
        setPlantNo(result.plant_no);
        setPantryNo(result.pantry_no);
        setOrderNumber(result.order_number);
        setOrderDate(result.order_date);
        setSignatureImage(result.signature_image);
        setStatus(result.status);
      }
    }, [props.orderReducer]);

    useEffect(() => {
      props.getOrderDTById(id);
    }, []);

    useEffect(() => {
      const { result } = props.orderdtReducer;

      if (result && isArray(result)) {
        setOrderDT(result);
      }
    }, [props.orderdtReducer]);

    useEffect(() => {
      if (signatureImage) {
        if (signatureImage != '-' && signatureImage != '') {
          setImageShow(`${imageUrlReceived}/${signatureImage}`);
        }
      }
    }, [signatureImage]);
  }

  const handleChangePlantNo = (event) => {
    setPlantNo(event.target.value);
    setPantryNo('');
  };

  const handleChangePantryNo = (event) => {
    setPantryNo(event.target.value);
  };

  const handleChangeOrderNumber = (event) => {
    setOrderNumber(event.target.value);
  };

  const handleChangeOrderDate = (event) => {
    setOrderDate(event.target.value);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = ApproveproductList.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    localStorage.setItem(server.ROWS_PER_PAGE, event.target.value);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - ApproveproductList.length) : 0;

  // const filteredUsers = applySortFilter(
  //   ApproveproductList,
  //   getComparator(order, orderBy),
  //   filterName
  // );

  // const isUserNotFound = filteredUsers.length === 0;

  const [DateDocumentvalue, setDateDocumentValue] = React.useState();

  const handleDateDocumentChange = (newValue) => {
    setDateDocumentValue(newValue);
  };
  return (
    <Page title="Approve | JConnect">
      <Container maxWidth="false">
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            ใบสั่งสินค้า
          </Typography>
          {status == 'A' && (
            <Button
              variant="contained"
              onClick={() => {
                let data = [];

                data.push({
                  id: id,
                  plant_no: plantNo,
                  pantry_no: pantryNo,
                  order_number: orderNumber,
                  status: 'P',
                  order_approve_by: localStorage.getItem(server.LOGIN_USERNAME),
                  order_approve_date: Date()
                });

                props.putApprove(navigate, data);

                // let data = new FormData();
                // data.append('status', 'P');
                // data.append('order_approve_by', localStorage.getItem(server.LOGIN_USERNAME));
                // data.append('order_approve_date', Date());
                // data.append('order_number', orderNumber);
                // data.append('plant_no', plantNo);
                // data.append('pantry_no', pantryNo);

                // if (id) {
                //   data.append('id', id);
                //   props.putApprove(navigate, data);

                Swal.fire({
                  position: 'center',
                  icon: 'success',
                  title: 'Save',
                  showConfirmButton: false,
                  timer: 1500
                });
                // }
              }}
            >
              อนุมัติสั่งสินค้า
            </Button>
          )}
        </Stack>

        <Grid container spacing={2} sx={{ mt: 3 }}>
          <Grid item lg={4} md={6} xs={12} container spacing={2}>
            <Grid item lg={12} md={12} xs={12}>
              <Box
                sx={{
                  borderRadius: 2,
                  border: 1,
                  borderColor: 'text.primary',
                  p: 3
                }}
              >
                <TextField
                  id="outlined-select-currency"
                  // select
                  label="พื้นที่"
                  value={plantNo}
                  onChange={handleChangePlantNo}
                  sx={{ width: '100%', mt: 2, mb: 2 }}
                  InputProps={{ readOnly: true }}
                >
                  {plantList &&
                    plantList.map((option) => {
                      if (showPlant.indexOf(option.plant_no) >= 0 || showPlant.length === 0) {
                        return (
                          <MenuItem key={option.id} value={option.plant_no}>
                            {option.plant_no}
                          </MenuItem>
                        );
                      }
                    })}
                </TextField>
                <TextField
                  id="outlined-select-currency"
                  // select
                  label="ตึก"
                  value={pantryNo}
                  onChange={handleChangePantryNo}
                  sx={{ width: '100%', mt: 2, mb: 2 }}
                  InputProps={{ readOnly: true }}
                >
                  {pantryList &&
                    pantryList.map((option) => {
                      if (showPantry.length === 0 || showPantry.indexOf(option.pantry_no) >= 0)
                        return (
                          <MenuItem key={option.id} value={option.pantry_no}>
                            {option.pantry_no}
                          </MenuItem>
                        );
                    })}
                </TextField>
              </Box>

              <Box
                sx={{
                  borderRadius: 2,
                  border: 1,
                  borderColor: 'text.primary',
                  p: 3,
                  mt: 2
                }}
              >
                <TextField
                  id="outlined-select-currency"
                  label="เลขที่เอกสาร"
                  sx={{ width: '100%', mt: 2, mb: 2 }}
                  value={orderNumber}
                  onChange={handleChangeOrderNumber}
                  InputProps={{ readOnly: true }}
                ></TextField>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Stack>
                    <DatePicker
                      label="วันที่เอกสาร"
                      inputFormat="DD/MM/YYYY"
                      value={(DateDocumentvalue, orderDate)}
                      onChange={(handleDateDocumentChange, handleChangeOrderDate)}
                      renderInput={(params) => (
                        <TextField {...params} InputProps={{ readOnly: true }} />
                      )}
                      readOnly={true}
                    />
                  </Stack>
                </LocalizationProvider>
              </Box>
            </Grid>
            {/* <Grid item lg={12} md={12} xs={12}>
              <Box
                sx={{
                  borderRadius: 2,
                  border: 1,
                  borderColor: 'text.primary',
                  p: 3
                }}
              >
                <TextField
                  id="outlined-select-currency"
                  label="เลขที่เอกสาร"
                  sx={{ width: '100%', mt: 2, mb: 2 }}
                  value={orderNumber}
                  onChange={handleChangeOrderNumber}
                  InputProps={{ readOnly: true }}
                ></TextField>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Stack>
                    <DatePicker
                      label="วันที่เอกสาร"
                      inputFormat="DD/MM/YYYY"
                      value={(DateDocumentvalue, orderDate)}
                      onChange={(handleDateDocumentChange, handleChangeOrderDate)}
                      renderInput={(params) => (
                        <TextField {...params} InputProps={{ readOnly: true }} />
                      )}
                      readOnly={true}
                    />
                  </Stack>
                </LocalizationProvider>
              </Box>
            </Grid> */}

            {imageShow && signatureImage && (
              <Grid item lg={12} md={12} xs={12}>
                <Box
                  sx={{
                    borderRadius: 2,
                    border: 1,
                    borderColor: 'text.primary',
                    p: 3
                  }}
                >
                  <img src={imageShow} alt={signatureImage.name} />
                </Box>
              </Grid>
            )}
          </Grid>

          <Grid item lg={8} md={6} xs={12}>
            <Box
              sx={{
                border: 1,
                borderRadius: 2,
                borderColor: 'text.primary',
                p: 3
              }}
            >
              {localStorage.getItem(server.LOGIN_SHOW_PRICE) === 'A' && (
                <Grid container spacing={2}>
                  <Grid item lg={6} md={6} xs={6}>
                    <TextField
                      type="text"
                      label="ยอดรวมรายการ :"
                      value={orderDT.length}
                      variant="outlined"
                      sx={{ width: '100%', mb: 2 }}
                      inputProps={{ readOnly: true }}
                    />
                  </Grid>

                  <Grid item lg={6} md={6} xs={6}>
                    <TextField
                      type="text"
                      label="ยอดรวมทั้งหมด :"
                      value={fCurrency(
                        isArray(orderDT) &&
                          orderDT.reduce(function (prev, current) {
                            return prev + +(current.product_price * current.qty);
                          }, 0)
                      )}
                      variant="outlined"
                      sx={{ width: '100%', mb: 2 }}
                      inputProps={{ readOnly: true }}
                    />
                  </Grid>
                </Grid>
              )}
              <>
                <Scrollbar>
                  <TableContainer sx={{ minWidth: 350 }}>
                    <Table>
                      <ApproveProductListHead
                        order={order}
                        orderBy={orderBy}
                        headLabel={
                          localStorage.getItem(server.LOGIN_SHOW_PRICE) === 'A'
                            ? TABLE_HEAD
                            : TABLE_HEAD_NO_PRICE
                        }
                        rowCount={orderDT.length}
                        numSelected={selected.length}
                        onRequestSort={handleRequestSort}
                        onSelectAllClick={handleSelectAllClick}
                      />
                      <TableBody>
                        {orderDT
                          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                          .map((row) => {
                            const { id, product_name, product_unit, qty, product_price } = row;
                            const isItemSelected = selected.indexOf(id) !== -1;

                            return (
                              <TableRow
                                hover
                                key={id}
                                tabIndex={-1}
                                role="checkbox"
                                selected={isItemSelected}
                                aria-checked={isItemSelected}
                              >
                                <TableCell align="left">{product_name}</TableCell>
                                <TableCell align="left">{product_unit}</TableCell>
                                {localStorage.getItem(server.LOGIN_SHOW_PRICE) === 'A' && (
                                  <TableCell align="left">{fCurrency(product_price)}</TableCell>
                                )}
                                <TableCell align="left">{qty}</TableCell>
                                {localStorage.getItem(server.LOGIN_SHOW_PRICE) === 'A' && (
                                  <TableCell align="left">
                                    {fCurrency(product_price * qty)}
                                  </TableCell>
                                )}

                                {/* <TableCell align="right">
                                  <UserMoreMenu />
                                </TableCell> */}
                              </TableRow>
                            );
                          })}
                        {emptyRows > 0 && (
                          <TableRow style={{ height: 53 * emptyRows }}>
                            <TableCell colSpan={6} />
                          </TableRow>
                        )}
                      </TableBody>
                      {/* {isUserNotFound && (
                        <TableBody>
                          <TableRow>
                            <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                              <SearchNotFound searchQuery={filterName} />
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      )} */}
                    </Table>
                  </TableContainer>
                </Scrollbar>

                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={orderDT.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </>

              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row-reverse',
                  //   p: 1,
                  mt: 2,
                  bgcolor: 'background.paper'
                }}
              >
                <Button
                  variant="contained"
                  component={RouterLink}
                  to={`/dashboard/approve/${plantNo}/${orderNumber}/${status}`}
                >
                  ปิด
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}

const mapStateToProps = ({ orderReducer, orderdtReducer, pantryReducer, plantReducer }) => ({
  orderReducer,
  orderdtReducer,
  pantryReducer,
  plantReducer
});

const mapDispatchToProps = {
  ...actions,
  ...PlantAction,
  ...PantryAction
};

export default connect(mapStateToProps, mapDispatchToProps)(ApproveProduct);
