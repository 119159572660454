/* eslint-disable */
import * as React from 'react';
import { useEffect } from 'react';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';

import { connect } from 'react-redux';
import * as actions from '../actions/plant.action';

import {
  Grid,
  Card,
  Button,
  Divider,
  Container,
  Typography,
  TextField,
  MenuItem,
  Stack
} from '@mui/material';
import Page from '../components/Page';

import Swal from 'sweetalert2';

// ----------------------------------------------------------------------

export function PlantProduct(props) {
  const navigate = useNavigate();
  const aStatus = [
    {
      value: 'A',
      label: 'ใช้งาน '
    },
    {
      value: 'I',
      label: ' ไม่ใช้งาน'
    }
  ];

  const [status, setStatus] = React.useState('A');
  const [plantNo, setPlantNo] = React.useState('');
  const [plantDetails, setPlantDetails] = React.useState('');

  const { id } = useParams();

  if (id) {
    // alert(id);

    useEffect(() => {
      props.getPlantById(id);
    }, []);

    useEffect(() => {
      const { result } = props.plantReducer;

      if (result) {
        setPlantNo(result.plant_no);
        setPlantDetails(result.plant_details);
        setStatus(result.status);
      }
    }, [props.plantReducer]);
  }
  const handleChangeStatus = (event) => {
    setStatus(event.target.value);
  };

  const handleChangePlantNo = (event) => {
    setPlantNo(event.target.value);
  };

  const handleChangePlantDetails = (event) => {
    setPlantDetails(event.target.value);
  };

  return (
    <Page title="Plant | JConnect">
      <Container>
        <Card>
          <Grid container spacing={2} sx={{ p: 3 }}>
            <Grid item container spacing={2} lg={12} md={12} xs={12}>
              <Grid item lg={12} md={12} xs={12}>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  sx={{ mb: 1 }}
                >
                  <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                    เพิ่มพื้นที่
                  </Typography>
                </Stack>
              </Grid>
              <Grid item lg={12} md={12} xs={12}>
                <Divider />
              </Grid>
            </Grid>

            <Grid item container spacing={2} lg={12} md={12} xs={12}>
              <Grid item lg={12} md={12} xs={12}>
                <TextField
                  id="plantNo"
                  label="พื้นที่"
                  sx={{ width: '100%' }}
                  value={plantNo}
                  onChange={handleChangePlantNo}
                ></TextField>
              </Grid>
              <Grid item lg={12} md={12} xs={12}>
                <TextField
                  id="plantDetails"
                  label="รายละเอียด"
                  sx={{ width: '100%' }}
                  value={plantDetails}
                  onChange={handleChangePlantDetails}
                />
              </Grid>
              <Grid item lg={6} md={6} xs={12}>
                <TextField
                  id="status"
                  select
                  label="สถานะ"
                  value={status}
                  onChange={handleChangeStatus}
                  sx={{ width: '100%' }}
                >
                  {aStatus.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>

            <Grid
              item
              container
              spacing={2}
              lg={12}
              md={12}
              xs={12}
              sx={{
                display: 'flex',
                flexDirection: 'row-reverse'
              }}
            >
              <Button
                variant="contained"
                component={RouterLink}
                to="/dashboard/plant"
                sx={{ mt: 2 }}
              >
                ยกเลิก
              </Button>
              <Button
                variant="contained"
                sx={{ mr: 2, mt: 2 }}
                onClick={() => {
                  let data = new FormData();
                  data.append('plant_no', plantNo);
                  data.append('plant_details', plantDetails);
                  data.append('status', status);

                  if (id) {
                    data.append('id', id);
                    props.putPlants(navigate, data);
                  } else {
                    props.postPlants(navigate, data);
                  }

                  // const postplant = props.postPlants(navigate, data);
                  // postplant(dispatch);

                  Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: 'Save',
                    showConfirmButton: false,
                    timer: 1500
                  });
                }}
              >
                บันทึก
              </Button>
            </Grid>
          </Grid>
        </Card>
      </Container>
    </Page>
  );
}

const mapStateToProps = ({ plantReducer }) => ({ plantReducer });

const mapDispatchToProps = {
  ...actions
};

export default connect(mapStateToProps, mapDispatchToProps)(PlantProduct);
