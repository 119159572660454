import {
  HTTP_STOCK_EDIT_FETCHING,
  HTTP_STOCK_EDIT_SUCCESS,
  HTTP_STOCK_EDIT_FAILED
} from '../constants';

const initialState = {
  result: null,
  isFatching: false,
  isError: false
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case HTTP_STOCK_EDIT_FETCHING:
      return { ...state, result: [], isFetching: true, isError: false };
    case HTTP_STOCK_EDIT_SUCCESS:
      return { ...state, result: payload, isFetching: false, isError: false };
    case HTTP_STOCK_EDIT_FAILED:
      return { ...state, result: [], isFetching: false, isError: true };

    default:
      return state;
  }
};
