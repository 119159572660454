/* eslint-disable */
import { useEffect, useState } from 'react';
import { server } from '../constants';
import { useRecoilState, atom } from 'recoil';

export { useLogin };

const baseApi = 'login';

const plantAtom = atom({
  key: `${baseApi}plantAtom`,
  default: []
});

const pantryAtom = atom({
  key: `${baseApi}pantryAtom`,
  default: []
});

function useLogin() {
  const [plant, setPlant] = useRecoilState(plantAtom);
  const [pantry, setPantry] = useRecoilState(pantryAtom);

  return { plant, setPlant, pantry, setPantry };
}
