/* eslint-disable */
import * as React from 'react';
import { useState, useEffect } from 'react';
import { Container, Stack, Typography, TextField, Button, MenuItem } from '@mui/material';
import Page from '../components/Page';
import AddToDriveOutlinedIcon from '@mui/icons-material/AddToDriveOutlined';
import { httpClient } from '../utils/HttpClient';
import { server, OK } from '../constants';
import Swal from 'sweetalert2';
import { fDate } from '../utils/formatTime';
import { isArray } from 'lodash';

export default function RecoveryData() {
  const [listFile, setListFile] = useState(null);
  const [file, setFile] = useState('');

  useEffect(async () => {
    let result = await httpClient.get(server.DB_FILE_RESTORE_URL);
    if ((result.data.result = OK)) {
      //   console.log(result.data.message);
      setListFile(JSON.parse(result.data.message));
    }
  }, []);

  const handleFile = (event) => {
    setFile(event.target.value);
  };

  return (
    <Page title="Reset Data | JConnect">
      <Container maxWidth="false">
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Recovery Data
          </Typography>
        </Stack>
        <Stack>
          <TextField
            sx={{ width: '375px' }}
            select
            label="ไฟล์สำรองข้อมูล"
            value={file}
            onChange={handleFile}
          >
            {/* {console.log(listFile)} */}
            {listFile &&
              isArray(listFile) &&
              listFile.map((item) => {
                // console.log(item);
                return <MenuItem value={item}>{item}</MenuItem>;
              })}
          </TextField>
        </Stack>
        <Button
          variant="contained"
          startIcon={<AddToDriveOutlinedIcon />}
          sx={{ mt: '10px', width: '375px', height: '50px' }}
          onClick={async () => {
            Swal.fire({
              title: `คืนข้อมูลตามไฟล์ ${file} ใช่หรือไม่`,
              showDenyButton: true,
              confirmButtonText: 'คืนข้อมูล',
              denyButtonText: `ยกเลิก`
            }).then(async (result) => {
              /* Read more about isConfirmed, isDenied below */
              if (result.isConfirmed) {
                Swal.fire({
                  title: 'หลังจากคืนข้อมูลแล้วไม่สามารถเรียกข้อมูลปัจจุบันกลับคืนได้',
                  showDenyButton: true,
                  confirmButtonText: 'ยืนยัน',
                  denyButtonText: `ยกเลิก`
                }).then(async (result) => {
                  if (result.isConfirmed) {
                    const resultRestore = await httpClient.post(server.DB_RESTORE_URL, {
                      file: file
                    });
                    // console.log(resultRestore);
                    if (resultRestore.data.result == OK) {
                      Swal.fire('คืนข้อมูลสำเร็จ', '', 'success');
                    }
                  }
                });
              }
              // else if (result.isDenied) {
              //   // Swal.fire('Changes are not saved', '', 'info');
              // }
            });
          }}
        >
          คืนข้อมูลตามไฟล์ที่เลือก
        </Button>
      </Container>
    </Page>
  );
}
