import { combineReducers } from 'redux';
import loginReducer from './login.reducer';
import plantReducer from './plant.reducer';
import pantryReducer from './pantry.reducer';
import adminReducer from './admin.reducer';
import usergroupReducer from './usergroup.reducer';
import orderReducer from './order.reducer';
import orderdtReducer from './orderdt.reducer';
import productReducer from './product.reducer';
import deliverReducer from './deliver.reducer';
import deliverdtReducer from './deliverdt.reducer';
import quotationReducer from './quotation.reducer';
import quotationdtReducer from './quotationdt.reducer';
import newpostReducer from './newpost.reducer';
import stockReducer from './stock.reducer';

export default combineReducers({
  loginReducer,
  plantReducer,
  pantryReducer,
  adminReducer,
  usergroupReducer,
  orderReducer,
  orderdtReducer,
  productReducer,
  deliverReducer,
  deliverdtReducer,
  quotationReducer,
  quotationdtReducer,
  newpostReducer,
  stockReducer
});
