/* eslint-disable */
import {
  HTTP_STOCK_EDIT_FETCHING,
  HTTP_STOCK_EDIT_SUCCESS,
  HTTP_STOCK_EDIT_FAILED,
  server,
  OK,
  YES
} from '../constants';
import { httpClient } from '../utils/HttpClient';

const setStateStockToSuccess = (payload) => ({
  type: HTTP_STOCK_EDIT_SUCCESS,
  payload
});

const setStateStockToFetching = () => ({
  type: HTTP_STOCK_EDIT_FETCHING
});

const setStateStockToFailed = () => ({
  type: HTTP_STOCK_EDIT_FAILED
});

export const getStockByPantry = (plant, pantry) => {
  return (dispatch) => {
    if (plant && plant != '' && pantry && pantry != '') {
      dispatch(setStateStockToFetching());
      httpClient
        .get(`${server.STOCK_URL}/${encodeURIComponent(plant)}/${encodeURIComponent(pantry)}`)
        .then((result) => {
          dispatch(setStateStockToSuccess(result.data));
        })
        .catch((err) => {
          console.log(err);
          dispatch(setStateStockToFailed());
        });
    }
  };
};

export const postStock = (plant, pantry, data) => {
  return async (dispatch) => {
    dispatch(setStateStockToFetching());

    let result = await httpClient.post(
      `${server.STOCK_URL}/${encodeURIComponent(plant)}/${encodeURIComponent(pantry)}`,
      data
    );

    if (result.data.result == OK) {
      dispatch(setStateStockToSuccess(result));
    } else {
      dispatch(setStateStockToFailed());
    }
  };
};
