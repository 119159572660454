/* eslint-disable */
import * as Yup from 'yup';
import * as React from 'react';
import { useState, useEffect } from 'react';
import { Link as RouterLink, Route, useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
// material
import {
  Link,
  Stack,
  Checkbox,
  TextField,
  IconButton,
  InputAdornment,
  FormControlLabel
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import axios from 'axios';
import { connect } from 'react-redux';
import * as actions from './../../../actions/login.action';
import { getUsergroupByCode } from './../../../actions/usergroup.action';
import { httpClient } from '../../../utils/HttpClient';
import { YES, NO, NOK, server } from '../../../constants';
import { isArray, isObject } from 'lodash';
import { useLogin } from '../../../hooks/useLogin';
// ----------------------------------------------------------------------

export function LoginForm(props) {
  const hookLogin = useLogin();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  // const [user, setUser] = useState('');

  // props.autoLogin(navigate);

  const LoginSchema = Yup.object().shape({
    username: Yup.string()
      // .email('Email must be a valid email address')
      .required('Username is required'),
    password: Yup.string().required('Password is required')
  });

  useEffect(() => {
    const { result, isError, isFatching } = props.loginReducer;
    if (result && isError) {
      if (result.data.result == NOK) {
        alert(result.data.message);
      }
    } // if (result && !isError && !isFatching)
    else {
      // console.log(localStorage.getItem(server.LOGIN_PASSED));
      // console.log(localStorage.getItem(server.LOGIN_MENU));
      // if (localStorage.getItem(server.LOGIN_PASSED) == NO) {
      //console.log(JSON.parse(result.data.message).admin_username);
      //localStorage.setItem(server.LOGIN_USER, JSON.parse(result.data.message).admin_username);
      // setUser(JSON.parse(result.data.message).admin_username);
      // props.getUsergroupByCode(localStorage.getItem(server.LOGIN_MENU));
      // console.log(result);
      // hookLogin.setPlant(JSON.parse(result.data.message).plant_no.split(','));
      // hookLogin.setPlant(JSON.parse(result.data.message).pantry_no.split(','));
      // }
    }
  }, [props.loginReducer]);

  // useEffect(() => {
  //   const { result, isError, isFatching } = props.usergroupReducer;
  //   if (result && !isFatching && !isError) {
  //     if (isObject(result) && !isArray(result) && localStorage.getItem(server.LOGIN_PASSED) == NO) {
  //       if (result.permission_code == localStorage.getItem(server.LOGIN_MENU)) {
  //         console.log(result.permission_menu);

  //         localStorage.setItem(server.LOGIN_MENU, result.permission_menu);
  //         // localStorage.setItem(server.LOGIN_USERNAME, user);
  //         localStorage.setItem(server.LOGIN_PASSED, YES);
  //         navigate('/dashboard/newpost', { replace: true });
  //       }
  //     } else {
  //       localStorage.setItem(server.LOGIN_PASSED, NO);
  //     }
  //   }
  // }, [props.usergroupReducer]);

  const formik = useFormik({
    initialValues: {
      username: '', // 'admin', // 'admin@sysoft.com',
      password: '', // '1234',
      remember: true
    },

    validationSchema: LoginSchema,
    onSubmit: (values, { setSubmitting }) => {
      // alert(formik.values);

      //let data = { username: 'admin@sysoft.com', password: '1234' };

      // axios.post('http://localhost:8085/api/v1/authen/login', data).then((response) => {
      //   alert(JSON.stringify(response.data));
      // });

      // httpClient.post(server.LOGIN_URL, data).then((response) => {
      //   alert(JSON.stringify(response.data));
      // });

      //navigate('/dashboard/newpost', { replace: true });

      // props.login(navigate, values);

      localStorage.setItem(server.LOGIN_PASSED, NO);
      props.login(navigate, values);
      setSubmitting(false);
    }
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <TextField
            fullWidth
            autoComplete="username"
            type="username"
            // label="Email address"
            label="Username"
            {...getFieldProps('username')}
            error={Boolean(touched.username && errors.username)}
            helperText={touched.username && errors.username}
          />

          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            label="Password"
            {...getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
          {/* <FormControlLabel
            control={<Checkbox {...getFieldProps('remember')} checked={values.remember} />}
            label="Remember me"
          />

          <Link component={RouterLink} variant="subtitle2" to="#">
            Forgot password?
          </Link> */}
        </Stack>

        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
        >
          Login
        </LoadingButton>
      </Form>
    </FormikProvider>
  );
}

const mapStateToProps = ({ loginReducer, usergroupReducer }) => ({
  loginReducer,
  usergroupReducer
});

const mapDispatchToProps = {
  ...actions,
  getUsergroupByCode
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
