const picking = [
  {
    id: 1,
    รหัสสินค้า: '7067',
    รายการสินค้า: 'SCOTCH BRITE 3""X5""',
    date1: '12',
    date2: '4',
    date3: '7',
    date4: '',
    total: '23',
    price: '10.00',
    amount: '230.00'
  },
  {
    id: 2,
    รหัสสินค้า: '03719',
    รายการสินค้า: 'กระดาษชำระคิมซอฟ',
    date1: '2',
    date2: '2',
    date3: '2',
    date4: '3',
    total: '9',
    price: '630.00',
    amount: '5,670.00'
  },
  {
    id: 3,
    รหัสสินค้า: '86220',
    รายการสินค้า: 'กระดาษสก๊อตเช็ดมือ (HRT 6)',
    date1: '6',
    date2: '8',
    date3: '5',
    date4: '8',
    total: '27',
    price: '1,120.00',
    amount: '30,240.00'
  },
  {
    id: 4,
    รหัสสินค้า: '8109509',
    รายการสินค้า: 'คอฟฟี่เมต',
    date1: '14',
    date2: '26',
    date3: '13',
    date4: '16',
    total: '69',
    price: '55.00',
    amount: '3,795.00'
  },
  {
    id: 5,
    รหัสสินค้า: '7041',
    รายการสินค้า: 'ชาจัสมิน (กลิ่นมะลิ/กล่องสีส้ม)',
    date1: '1',
    date2: '1',
    date3: '',
    date4: '1',
    total: '3',
    price: '199.00',
    amount: '597.00'
  },
  {
    id: 6,
    รหัสสินค้า: '7040',
    รายการสินค้า: 'ชาลิปตัน',
    date1: '',
    date2: '',
    date3: '',
    date4: '2',
    total: '2',
    price: '160.00',
    amount: '320.00'
  },
  {
    id: 7,
    รหัสสินค้า: '7511',
    รายการสินค้า: 'ช้อนคนเครื่องดื่มพลาสติก',
    date1: '10',
    date2: '10',
    date3: '9',
    date4: '9',
    total: '37',
    price: '18.00',
    amount: '666.00'
  },
  {
    id: 8,
    รหัสสินค้า: '7649',
    รายการสินค้า: 'ถุงขยะขาวขุ่น 12""x20""(เกรดA)',
    date1: '14',
    date2: '15',
    date3: '10',
    date4: '12',
    total: '51',
    price: '46.00',
    amount: '2,346.00'
  },
  {
    id: 9,
    รหัสสินค้า: '7673',
    รายการสินค้า: 'ถุงขยะขาวขุ่น 24""x30""(เกรดA)',
    date1: '38',
    date2: '33',
    date3: '27',
    date4: '31',
    total: '129',
    price: '46.00',
    amount: '5,934.00'
  },
  {
    id: 10,
    รหัสสินค้า: '7600',
    รายการสินค้า: 'ถุงขยะขาวขุ่น 30""x40""(เกรดA)',
    date1: '',
    date2: '32',
    date3: '20',
    date4: '31',
    total: '83',
    price: '46.00',
    amount: '3,818.00'
  },
  {
    id: 11,
    รหัสสินค้า: '7483',
    รายการสินค้า: 'ถุงใส่ด้วยผ้าอนามัย(อย่างดี)',
    date1: '1',
    date2: '1',
    date3: '1',
    date4: '',
    total: '3',
    price: '28.00',
    amount: '84.00'
  },
  {
    id: 12,
    รหัสสินค้า: '1200722',
    รายการสินค้า: 'นมข้นหวาน',
    date1: '43',
    date2: '40',
    date3: '35',
    date4: '26',
    total: '144',
    price: '25.00',
    amount: '3,600.00'
  },
  {
    id: 13,
    รหัสสินค้า: '7041',
    รายการสินค้า: 'ชาจัสมิน (กลิ่นมะลิ/กล่องสีส้ม)',
    date1: '1',
    date2: '1',
    date3: '',
    date4: '1',
    total: '3',
    price: '199.00',
    amount: '597.00'
  },
  {
    id: 14,
    รหัสสินค้า: '7006',
    รายการสินค้า: 'น้ำตาลทราย(สีขาว)',
    date1: '4',
    date2: '16',
    date3: '13',
    date4: '6',
    total: '39',
    price: '22.00',
    amount: '858.00'
  },
  {
    id: 15,
    รหัสสินค้า: '7767',
    รายการสินค้า: 'น้ำยาล้างจานซันไลด์',
    date1: '6',
    date2: '2',
    date3: '3',
    date4: '4',
    total: '15',
    price: '147.00',
    amount: '2,205.00'
  },
  {
    id: 16,
    รหัสสินค้า: '7068',
    รายการสินค้า: 'ฟองน้ำ SCOTCH BRITH ',
    date1: '9',
    date2: '4',
    date3: '7',
    date4: '',
    total: '20',
    price: '12.00',
    amount: '240.00'
  },
  {
    id: 17,
    รหัสสินค้า: '7141',
    รายการสินค้า: 'ฟองน้ำล้างจานแบบตาข่าย',
    date1: '',
    date2: '',
    date3: '',
    date4: '7',
    total: '7',
    price: '5.00',
    amount: '35.00'
  },
  {
    id: 18,
    รหัสสินค้า: '7069',
    รายการสินค้า: 'ฟองน้ำล้างแก้วแบบมีด้าม',
    date1: '',
    date2: '',
    date3: '',
    date4: '7',
    total: '7',
    price: '39.00',
    amount: '273.00'
  },
  {
    id: 19,
    รหัสสินค้า: '8000',
    รายการสินค้า: 'สบู่เหลวล้างคราบน้ำมันอุตสากรรม',
    date1: '',
    date2: '',
    date3: '1',
    date4: '',
    total: '1',
    price: '940.00',
    amount: '940.00'
  },
  {
    id: 20,
    รหัสสินค้า: '96311',
    รายการสินค้า: 'สบู่เหลวล้างมือชนิดขวด Kimcare',
    date1: '',
    date2: '',
    date3: '20',
    date4: '9',
    total: '29',
    price: '127.00',
    amount: '3,683.00'
  },
  {
    id: 21,
    รหัสสินค้า: '4277075',
    รายการสินค้า: 'สบู่เหลวล้างมือชนิดเติม',
    date1: '1',
    date2: '16',
    date3: '',
    date4: '',
    total: '17',
    price: '329.00',
    amount: '5,593.00'
  },
  {
    id: 22,
    รหัสสินค้า: '7676',
    รายการสินค้า: 'สเปรย์ปรับอากาศบับเบิ้ล',
    date1: '10',
    date2: '1',
    date3: '',
    date4: '2',
    total: '13',
    price: '45.00',
    amount: '585.00'
  },
  {
    id: 23,
    รหัสสินค้า: '7207',
    รายการสินค้า: 'สเปรย์ปรับอากาศส้ม',
    date1: '',
    date2: '1',
    date3: '',
    date4: '',
    total: '1',
    price: '45.00',
    amount: '45.00'
  },
  {
    id: 24,
    รหัสสินค้า: '7580',
    รายการสินค้า: 'หลอดงอสีขาว หุ้มกระดาษ',
    date1: '',
    date2: '6',
    date3: '',
    date4: '',
    total: '6',
    price: '13.00',
    amount: '78.00'
  },
  {
    id: 25,
    รหัสสินค้า: '8140334',
    รายการสินค้า: 'เนสกาแฟ(เรดคัพ)',
    date1: '',
    date2: '15',
    date3: '12',
    date4: '11',
    total: '38',
    price: '218.00',
    amount: '8,284.00'
  },
  {
    id: 26,
    รหัสสินค้า: '1204856',
    รายการสินค้า: 'ไมโล (แอคทีพ-บี 1000 กรัม)',
    date1: '',
    date2: '9',
    date3: '6',
    date4: '9',
    total: '24',
    price: '148.00',
    amount: '3,552.00'
  },
  {
    id: 27,
    รหัสสินค้า: '7078',
    รายการสินค้า: 'กระดาษเช็ดปากเน็ปกิ้น (500 แผ่น)',
    date1: '',
    date2: '15',
    date3: '12',
    date4: '11',
    total: '38',
    price: '218.00',
    amount: '8,284.00'
  }
];

export default picking;
