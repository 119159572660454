/* eslint-disable */
import {
  HTTP_USERGROUP_SUCCESS,
  HTTP_USERGROUP_FETCHING,
  HTTP_USERGROUP_FAILED,
  server,
  OK,
  YES
} from '../constants';
import { httpClient } from '../utils/HttpClient';

const setStateUsergroupToSuccess = (payload) => ({
  type: HTTP_USERGROUP_SUCCESS,
  payload
});

const setStateUsergroupToFetching = () => ({
  type: HTTP_USERGROUP_FETCHING
});

const setStateUsergroupToFailed = () => ({
  type: HTTP_USERGROUP_FAILED
});

export const getUsergroups = () => {
  return async (dispatch) => {
    dispatch(setStateUsergroupToFetching());
    await doGetUsergroups(dispatch);
  };
};

const doGetUsergroups = async (dispatch) => {
  try {
    let result = await httpClient.get(server.USERGROUP_URL);
    //console.log(' *** doGetPlants >> ', result);
    dispatch(setStateUsergroupToSuccess(result.data));
  } catch (error) {
    alert(JSON.stringify(error));
    dispatch(setStateUsergroupToFailed());
  }
};

export const deleteUsergroups = (id) => {
  return async (dispatch) => {
    dispatch(setStateUsergroupToFetching());
    await httpClient.delete(`${server.USERGROUP_URL}/${id}`);
    await doGetUsergroups(dispatch);
  };
};

export const getUsergroupById = (id) => {
  return (dispatch) => {
    // dispatch(finishInitialization(false))
    dispatch(setStateUsergroupToFetching());
    httpClient
      .get(`${server.USERGROUP_URL}/${id}`)
      .then((result) => {
        dispatch(setStateUsergroupToSuccess(result.data));
      })
      .catch((err) => {
        console.log(err);
        dispatch(setStateUsergroupToFailed());
      });
  };
};

export const getUsergroupByCode = (code) => {
  return (dispatch) => {
    // dispatch(finishInitialization(false))
    dispatch(setStateUsergroupToFetching());
    httpClient
      .get(`${server.USERGROUP_URL}/code/${code}`)
      .then((result) => {
        dispatch(setStateUsergroupToSuccess(result.data));
      })
      .catch((err) => {
        console.log(err);
        dispatch(setStateUsergroupToFailed());
      });
  };
};

export const getUsergroupByType = (type) => {
  return (dispatch) => {
    // dispatch(finishInitialization(false))
    dispatch(setStateUsergroupToFetching());
    httpClient
      .get(`${server.USERGROUP_URL}/type/${type}`)
      .then((result) => {
        dispatch(setStateUsergroupToSuccess(result.data));
      })
      .catch((err) => {
        console.log(err);
        dispatch(setStateUsergroupToFailed());
      });
  };
};

export const postUsergroups = (navigate, data) => {
  return async (dispatch) => {
    dispatch(setStateUsergroupToFetching());
    let result = await httpClient.post(server.USERGROUP_URL, data);
    console.log(result);

    if (result.data.result == OK) {
      navigate('/dashboard/usergroup', { replace: true });
      dispatch(setStateUsergroupToSuccess(result));
    } else {
      dispatch(setStateUsergroupToFailed());
    }
  };
};

export const putUsergroups = (navigate, data) => {
  return async (dispatch) => {
    dispatch(setStateUsergroupToFetching());
    let result = await httpClient.put(server.USERGROUP_URL, data);
    if (result.data.result == OK) {
      navigate('/dashboard/usergroup', { replace: true });
      dispatch(setStateUsergroupToSuccess(result));
    } else {
      dispatch(setStateUsergroupToFailed());
    }
  };
};
