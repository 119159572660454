/* eslint-disable */
import * as React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import { useEffect } from 'react';
import searchFill from '@iconify/icons-eva/search-fill';
import trash2Fill from '@iconify/icons-eva/trash-2-fill';
import roundFilterList from '@iconify/icons-ic/round-filter-list';

import { connect } from 'react-redux';
import * as actions from '../../../actions/plant.action';

// material
import { styled } from '@mui/material/styles';
import {
  Box,
  Toolbar,
  Tooltip,
  MenuItem,
  Typography,
  OutlinedInput,
  InputAdornment,
  TextField,
  Grid
} from '@mui/material';
// ----------------------------------------------------------------------

const RootStyle = styled(Toolbar)(({ theme }) => ({
  // height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(3, 1, 0, 3)
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`
  }
}));

// ----------------------------------------------------------------------

PantryListToolbar.propTypes = {
  numSelected: PropTypes.number,
  pantryfilterName: PropTypes.string,
  pantryonFilterName: PropTypes.func,
  plantfilterName: PropTypes.string,
  plantonFilterName: PropTypes.func,
  plant: PropTypes.array
};

export default function PantryListToolbar({
  numSelected,
  pantryfilterName,
  pantryonFilterName,
  plantfilterName,
  plantonFilterName,
  plant
}) {
  return (
    <RootStyle
      sx={{
        ...(numSelected > 0 && {
          color: 'primary.main',
          bgcolor: 'primary.lighter'
        })
      }}
    >
      {numSelected > 0 ? (
        <Typography component="div" variant="subtitle1">
          {numSelected} selected
        </Typography>
      ) : (
        <>
          <Grid container spacing={2}>
            <Grid item lg={2} md={2} xs={12}>
              <TextField
                sx={{ width: '100%' }}
                select
                label="พื้นที่"
                value={plantfilterName}
                onChange={plantonFilterName}
              >
                <MenuItem value="%">ทั้งหมด</MenuItem>
                {plant.map((option) => (
                  <MenuItem key={option.id} value={option.plant_no}>
                    {option.plant_no}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item lg={10} md={10} xs={12}>
              <SearchStyle
                value={pantryfilterName}
                onChange={pantryonFilterName}
                placeholder=" ค้นหา ตึก "
                startAdornment={
                  <InputAdornment position="start">
                    <Box component={Icon} icon={searchFill} sx={{ color: 'text.disabled' }} />
                  </InputAdornment>
                }
              />
            </Grid>
          </Grid>
        </>
      )}

      {/* {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <Icon icon={trash2Fill} />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <Icon icon={roundFilterList} />
          </IconButton>
        </Tooltip>
      )} */}
    </RootStyle>
  );
}
