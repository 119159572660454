/* eslint-disable */
import * as React from 'react';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import * as actions from '../actions/admin.action';
import * as PlantAction from '../actions/plant.action';
import * as PantryAction from '../actions/pantry.action';
import * as UserGroupAction from '../actions/usergroup.action';
import {
  Box,
  Grid,
  Button,
  Container,
  Typography,
  TextField,
  MenuItem,
  Stack,
  Card,
  Divider
} from '@mui/material';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import Page from '../components/Page';

import Swal from 'sweetalert2';
import pantry from 'src/_mocks_/pantry';
import pl from 'date-fns/locale/pl';

import { isArray, isObject } from 'lodash';
import { server } from '../constants';
// ----------------------------------------------------------------------

const ITEM_HEIGHT_PLANT = 48;
const ITEM_PADDING_TOP_PLANT = 8;
const MenuPropsPlant = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT_PLANT * 4.5 + ITEM_PADDING_TOP_PLANT,
      width: 250
    }
  }
};

const ITEM_HEIGHT_PANTRY = 48;
const ITEM_PADDING_TOP_PANTRY = 8;
const MenuPropsPantry = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT_PANTRY * 4.5 + ITEM_PADDING_TOP_PANTRY,
      width: 250
    }
  }
};

export function AdminProduct(props) {
  const [plantList, setPlantList] = useState(null);
  const [pantryList, setPantryList] = useState(null);
  const [usergroupList, setUserGroupList] = useState(null);
  // const [personPantry, setPersonPantry] = React.useState([]);
  const navigate = useNavigate();

  const aStatus = [
    {
      value: 'A',
      label: 'ใช้งาน '
    },
    {
      value: 'I',
      label: ' ไม่ใช้งาน'
    }
  ];

  const [status, setStatus] = React.useState('A');
  const [plantNo, setPlantNo] = React.useState([]);
  const [pantryNo, setPantryNo] = React.useState([]);
  const [adminName, setAdminName] = React.useState('');
  const [adminUsername, setAdminUsername] = React.useState('');
  const [adminPassword, setAdminPassword] = React.useState('');
  const [adminPermission, setAdminPermission] = React.useState('');
  const [adminTel, setAdminTel] = React.useState('');

  const showPlant = localStorage.getItem(server.LOGIN_PLANT);
  const showPantry = localStorage.getItem(server.LOGIN_PANTRY);

  useEffect(() => {
    props.getPlants();
  }, []);

  useEffect(() => {
    props.getPantryByPlantNo(plantNo);
  }, [plantNo]);

  useEffect(() => {
    props.getUsergroupByType(type);
  }, []);

  useEffect(() => {
    const { result } = props.plantReducer;
    if (result) setPlantList(result);
  }, [props.plantReducer]);

  useEffect(() => {
    const { result } = props.pantryReducer;
    if (result) setPantryList(result);
  }, [props.pantryReducer]);

  useEffect(() => {
    const { result } = props.usergroupReducer;
    // console.log(result);
    if (result) setUserGroupList(result);
  }, [props.usergroupReducer]);

  const { type, id } = useParams();

  if (id) {
    // alert(id);

    useEffect(() => {
      props.getAdminById(id);
    }, []);

    useEffect(() => {
      const { result } = props.adminReducer;

      if (result) {
        setAdminUsername(result.admin_username);
        setAdminPassword(result.admin_password);
        setAdminName(result.admin_name);
        setAdminTel(result.admin_tel);

        setAdminPermission(result.admin_permission);
        if (result.plant_no) setPlantNo(result.plant_no.split(','));
        if (result.pantry_no) setPantryNo(result.pantry_no.split(','));

        setStatus(result.status);
      }
    }, [props.adminReducer]);
  }

  const handleChangeStatus = (event) => {
    setStatus(event.target.value);
  };

  const handleChangeAdminTel = (event) => {
    setAdminTel(event.target.value);
  };

  const handleChangeAdminUsername = (event) => {
    setAdminUsername(event.target.value);
  };

  const handleChangeAdminPassword = (event) => {
    setAdminPassword(event.target.value);
  };

  const handleChangeAdminPermission = (event) => {
    setAdminPermission(event.target.value);
  };

  const handleChangeAdminName = (event) => {
    setAdminName(event.target.value);
  };

  const handleChangePantry = (event) => {
    const {
      target: { value }
    } = event;

    // setPantryNo(
    //   // On autofill we get a stringified value.
    //   typeof value === 'string' ? value.split(',') : value
    // );

    typeof value === 'string' ? value.split(',') : value;

    value.forEach(function (item, index, object) {
      if (pantryList.findIndex((pl) => pl.pantry_no === item) == -1) {
        object.splice(index, 1);
        // console.log(item);
      }
    });

    setPantryNo(value);
  };

  const handleChangePlant = (event) => {
    const {
      target: { value }
    } = event;
    // setPlantNo(
    //   // On autofill we get a stringified value.
    //   typeof value === 'string' ? value.split(',') : value
    // );

    typeof value === 'string' ? value.split(',') : value;

    value.forEach(function (item, index, object) {
      if (plantList.findIndex((pl) => pl.plant_no === item) == -1) {
        object.splice(index, 1);
        // console.log(item);
      }
    });

    setPlantNo(value);

    if (value.length > 1) setPantryNo([]);
  };

  return (
    <Page title="Admin | JConnect">
      <Container>
        <Card>
          <Grid container spacing={2} sx={{ p: 3 }}>
            <Grid item container spacing={2} lg={12} md={12} xs={12}>
              <Grid item lg={12} md={12} xs={12}>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  sx={{ mb: 1 }}
                >
                  <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                    เพิ่มผู้ใช้งาน
                  </Typography>
                </Stack>
              </Grid>
              <Grid item lg={12} md={12} xs={12}>
                <Divider />
              </Grid>
            </Grid>

            <Grid item xs={6}>
              <TextField
                id="adminUsername"
                label="Username"
                value={adminUsername}
                onChange={handleChangeAdminUsername}
                sx={{ width: '100%' }}
              ></TextField>
            </Grid>
            <Grid item xs={6}>
              <TextField
                sx={{ width: '100%' }}
                label="Password"
                id="adminPassword"
                value={adminPassword}
                onChange={handleChangeAdminPassword}
                type="password"
                autoComplete="new-password"
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                sx={{ width: '100%' }}
                label="ชื่อ-สกุล"
                id="adminName"
                value={adminName}
                onChange={handleChangeAdminName}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                sx={{ width: '100%' }}
                label="เบอร์โทร"
                id="adminTel"
                value={adminTel}
                onChange={handleChangeAdminTel}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                id="adminPermission"
                select
                label="ระดับสิทธิ"
                value={adminPermission}
                onChange={handleChangeAdminPermission}
                sx={{ width: '100%' }}
              >
                {/* {console.log(usergroupList)} */}
                {usergroupList &&
                  usergroupList.map((option) => (
                    <MenuItem key={option.id} value={option.permission_code}>
                      {option.permission_name}
                    </MenuItem>
                  ))}
              </TextField>
            </Grid>

            <Grid item xs={6}>
              <FormControl sx={{ width: '100%' }}>
                <InputLabel id="plantNo">พื้นที่</InputLabel>
                <Select
                  labelId="plantNo"
                  id="plantNo"
                  multiple
                  value={plantNo}
                  onChange={handleChangePlant}
                  input={<OutlinedInput label="Plant" />}
                  renderValue={(selected) => selected.join(', ')}
                  MenuProps={MenuPropsPlant}
                >
                  {plantList &&
                    plantList.map((option) => {
                      // if (showPlant.indexOf(option.plant_no) >= 0) {
                      return (
                        <MenuItem key={option.id} value={option.plant_no}>
                          <Checkbox checked={plantNo.indexOf(option.plant_no) > -1} />
                          {/* {option.pantry_no} */}
                          <ListItemText primary={option.plant_no} />
                        </MenuItem>
                      );
                      // }
                    })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl sx={{ width: '100%' }}>
                <InputLabel id="pantryNo">ตึก</InputLabel>
                <Select
                  labelId="pantryNo"
                  id="pantryNo"
                  multiple
                  value={pantryNo}
                  onChange={handleChangePantry}
                  input={<OutlinedInput label="Pantry" />}
                  renderValue={(selected) => selected.join(', ')}
                  MenuProps={MenuPropsPantry}
                >
                  {pantryList &&
                    pantryList.map((option) => {
                      // if (!isArray(showPlant) || showPantry.indexOf(option.pantry_no) >= 0)
                      return (
                        <MenuItem key={option.id} value={option.pantry_no}>
                          <Checkbox checked={pantryNo.indexOf(option.pantry_no) > -1} />
                          {/* {option.pantry_no} */}
                          <ListItemText primary={option.pantry_no} />
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="status"
                select
                label="สถานะ"
                value={status}
                onChange={handleChangeStatus}
                sx={{ width: '100%' }}
              >
                {aStatus.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid item xs={12}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row-reverse',
                  //   p: 1,
                  mt: 2,
                  bgcolor: 'background.paper'
                }}
              >
                <Button variant="contained" component={RouterLink} to={`/dashboard/admin/${type}`}>
                  ยกเลิก
                </Button>
                <Button
                  variant="contained"
                  sx={{ mr: 2 }}
                  onClick={() => {
                    let data = new FormData();
                    data.append('admin_username', adminUsername);
                    data.append('admin_password', adminPassword);
                    data.append('admin_name', adminName);
                    data.append('admin_tel', adminTel);
                    data.append('admin_permission', adminPermission);
                    data.append('plant_no', plantNo);
                    data.append('pantry_no', pantryNo);
                    data.append('status', status);
                    data.append('admin_type', type);

                    if (id) {
                      data.append('id', id);
                      props.putAdmins(navigate, data);
                    } else props.postAdmins(navigate, data);

                    // const postplant = props.postPlants(navigate, data);
                    // postplant(dispatch);

                    Swal.fire({
                      position: 'center',
                      icon: 'success',
                      title: 'Save',
                      showConfirmButton: false,
                      timer: 1500
                    });
                  }}
                >
                  บันทึก
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Card>
      </Container>
    </Page>
  );
}

const mapStateToProps = ({ adminReducer, pantryReducer, plantReducer, usergroupReducer }) => ({
  adminReducer,
  pantryReducer,
  plantReducer,
  usergroupReducer
});

const mapDispatchToProps = {
  ...actions,
  ...PlantAction,
  ...PantryAction,
  ...UserGroupAction
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminProduct);
