const CheckVendor = [
  {
    id: 1,
    รายการสินค้า: 'น้ำยาล้างจาน ',
    หน่วย: 'ขวด',
    จำนวน: ' 1 '
  },
  {
    id: 2,
    รายการสินค้า: 'น้ำยาล้างจาน ',
    หน่วย: 'ขวด',
    จำนวน: ' 1 '
  },
  {
    id: 3,
    รายการสินค้า: 'น้ำยาล้างจาน ',
    หน่วย: 'ขวด',
    จำนวน: ' 1 '
  }
];

export default CheckVendor;
