import { format, formatDistanceToNow } from 'date-fns';

// ----------------------------------------------------------------------

export function fDate(date) {
  return date ? format(new Date(date), 'dd/MM/yyyy') : date;
}

export function fDateTime(date) {
  return date ? format(new Date(date), 'dd/MM/yyyy HH:mm:ss') : date;
}

export function fDateTimeSuffix(date) {
  return format(new Date(date), 'dd/MM/yyyy hh:mm p');
}

export function fToNow(date) {
  return formatDistanceToNow(new Date(date), {
    addSuffix: true
  });
}
