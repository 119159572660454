/* eslint-disable */
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import searchFill from '@iconify/icons-eva/search-fill';
import trash2Fill from '@iconify/icons-eva/trash-2-fill';
import roundFilterList from '@iconify/icons-ic/round-filter-list';
// material
import { styled } from '@mui/material/styles';
import {
  Box,
  Toolbar,
  Tooltip,
  IconButton,
  Typography,
  OutlinedInput,
  InputAdornment,
  TextField,
  MenuItem,
  Grid
} from '@mui/material';

import Swal from 'sweetalert2';
import { server, orderStatus } from '../../../constants';
import { isArray } from 'lodash';

// ----------------------------------------------------------------------

const RootStyle = styled(Toolbar)(({ theme }) => ({
  // height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(3, 1, 0, 3)
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: '100%',
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter
  }),
  '&.Mui-focused': { boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`
  }
}));

// ----------------------------------------------------------------------

ApproveListToolbar.propTypes = {
  numSelected: PropTypes.number,
  filterName: PropTypes.string,
  onFilterName: PropTypes.func,
  plantfilterName: PropTypes.string,
  plantonFilterName: PropTypes.func,
  pantryfilterName: PropTypes.string,
  pantryonFilterName: PropTypes.func,
  plant: PropTypes.array,
  pantry: PropTypes.array,
  statusfilterName: PropTypes.string,
  statusonFilterName: PropTypes.func
};

export default function ApproveListToolbar({
  numSelected,
  filterName,
  onFilterName,
  plantfilterName,
  plantonFilterName,
  pantryfilterName,
  pantryonFilterName,
  plant,
  pantry,
  props,
  id,
  statusfilterName,
  statusonFilterName
}) {
  const showPlant = localStorage.getItem(server.LOGIN_PLANT);
  const showPantry = localStorage.getItem(server.LOGIN_PANTRY);

  return (
    <RootStyle
      sx={{
        ...(numSelected > 0 && {
          color: 'primary.main',
          bgcolor: 'primary.lighter'
        })
      }}
    >
      {numSelected > 0 ? (
        <Typography component="div" variant="subtitle1">
          {numSelected} selected
        </Typography>
      ) : (
        <>
          <Grid container spacing={2}>
            {/* <Grid item lg={2} md={2} xs={6}>
              <TextField
                sx={{ width: '100%' }}
                select
                label="พื้นที่"
                value={plantfilterName}
                onChange={plantonFilterName}
              >
                {plant.map((option) => {
                  if (showPlant.indexOf(option.plant_no) >= 0 || showPlant.length === 0) {
                    return (
                      <MenuItem key={option.id} value={option.plant_no}>
                        {option.plant_no}
                      </MenuItem>
                    );
                  }
                })}
              </TextField>
            </Grid> */}
            <Grid item lg={2} md={2} xs={6}>
              <TextField
                sx={{ width: '100%' }}
                select
                label="ตึก"
                value={pantryfilterName}
                onChange={pantryonFilterName}
              >
                <MenuItem value="%">ทั้งหมด</MenuItem>
                {pantry &&
                  pantry.length &&
                  pantry.map((option) => {
                    if (showPantry.length === 0 || showPantry.indexOf(option.pantry_no) >= 0)
                      return (
                        <MenuItem key={option.id} value={option.pantry_no}>
                          {option.pantry_no}
                        </MenuItem>
                      );
                  })}
              </TextField>
            </Grid>
            {/* <Grid item lg={2} md={2} xs={6}>
              <TextField
                sx={{ width: '100%' }}
                select
                // SelectProps={{ MenuProps: { disableScrollLock: true } }}
                label="สถานะ"
                value={statusfilterName}
                onChange={statusonFilterName}
              >
                {orderStatus.map((option) => {
                  // if (showPlant.indexOf(option.plant_no) >= 0) {
                  return (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  );
                  // }
                })}
              </TextField>
            </Grid> */}
            <Grid item lg={2} md={2} xs={6}>
              <SearchStyle
                value={filterName}
                onChange={onFilterName}
                placeholder="ค้นหาเลขที่เอกสาร"
                startAdornment={
                  <InputAdornment position="start">
                    <Box component={Icon} icon={searchFill} sx={{ color: 'text.disabled' }} />
                  </InputAdornment>
                }
              />
            </Grid>
          </Grid>
        </>
      )}
    </RootStyle>
  );
}
