/* eslint-disable */
import {
  HTTP_PLANT_SUCCESS,
  HTTP_PLANT_FETCHING,
  HTTP_PLANT_FAILED,
  server,
  OK,
  YES
} from '../constants';
import { httpClient } from '../utils/HttpClient';

const setStatePlantToSuccess = (payload) => ({
  type: HTTP_PLANT_SUCCESS,
  payload
});

const setStatePlantToFetching = () => ({
  type: HTTP_PLANT_FETCHING
});

const setStatePlantToFailed = () => ({
  type: HTTP_PLANT_FAILED
});

export const getPlants = () => {
  return async (dispatch) => {
    dispatch(setStatePlantToFetching());
    await doGetPlants(dispatch);
  };
};

const doGetPlants = async (dispatch) => {
  try {
    let result = await httpClient.get(server.PLANT_URL);
    //console.log(' *** doGetPlants >> ', result);
    dispatch(setStatePlantToSuccess(result.data));
  } catch (error) {
    alert(JSON.stringify(error));
    dispatch(setStatePlantToFailed());
  }
};

export const deletePlants = (id) => {
  return async (dispatch) => {
    dispatch(setStatePlantToFetching());
    await httpClient.delete(`${server.PLANT_URL}/${id}`);
    await doGetPlants(dispatch);
  };
};

export const getPlantById = (id) => {
  return (dispatch) => {
    // dispatch(finishInitialization(false))
    dispatch(setStatePlantToFetching());
    httpClient
      .get(`${server.PLANT_URL}/${id}`)
      .then((result) => {
        dispatch(setStatePlantToSuccess(result.data));
      })
      .catch((err) => {
        console.log(err);
        dispatch(setStatePlantToFailed());
      });
  };
};

export const postPlants = (navigate, data) => {
  return async (dispatch) => {
    dispatch(setStatePlantToFetching());
    let result = await httpClient.post(server.PLANT_URL, data);
    console.log(result);

    if (result.data.result == OK) {
      navigate('/dashboard/plant', { replace: true });
      dispatch(setStatePlantToSuccess(result));
    } else {
      dispatch(setStatePlantToFailed());
    }
  };
};

export const putPlants = (navigate, data) => {
  return async (dispatch) => {
    dispatch(setStatePlantToFetching());
    let result = await httpClient.put(server.PLANT_URL, data);
    if (result.data.result == OK) {
      navigate('/dashboard/plant', { replace: true });
      dispatch(setStatePlantToSuccess(result));
    } else {
      dispatch(setStatePlantToFailed());
    }
  };
};

export const getPlantDetail = () => {
  return async (dispatch) => {
    dispatch(setStatePlantToFetching());
    try {
      let result = await httpClient.get(server.PLANT_DETAIL_URL);
      dispatch(setStatePlantToSuccess(result.data));
    } catch (error) {
      dispatch(setStatePlantToFailed());
    }
  };
};
