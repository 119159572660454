/* eslint-disable */
import { filter } from 'lodash';
import { Icon } from '@iconify/react';
import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';
import microsoftExcel from '@iconify/icons-file-icons/microsoft-excel';
import { Link as RouterLink } from 'react-router-dom';
// material
import {
  Card,
  Table,
  Stack,
  Grid,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination
} from '@mui/material';
// components
import { connect } from 'react-redux';
import * as PlantAction from '../actions/plant.action';
import * as PantryAction from '../actions/pantry.action';
import * as ProductAction from '../actions/product.action';
import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import {
  ExampleListToolbar,
  ExampleListHead,
  ExampleToolbar
} from '../components/_dashboard/example';
//
// import ExampleList from '../_mocks_/example';
import { server, apiUrl } from '../constants';
import printerfill from '@iconify/icons-eva/printer-fill';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'ลำดับ', label: 'ลำดับ', alignRight: false },
  { id: 'วันที่สั่งสินค้า', label: 'วันที่สั่งสินค้า', alignRight: false },
  { id: 'User', label: 'User', alignRight: false },
  { id: 'รหัสสินค้า', label: 'รหัสสินค้า', alignRight: false },
  { id: 'รายการสินค้า', label: 'รายการสินค้า', alignRight: false },
  { id: 'ยอดคงเหลือ', label: 'ยอดคงเหลือ', alignRight: false },
  { id: 'จำนวนที่เบิก', label: 'จำนวนที่เบิก', alignRight: false },
  { id: 'หน่วย', label: 'หน่วย', alignRight: false },
  { id: 'ราคา/หน่วย', label: 'ราคา/หน่วย', alignRight: false },
  { id: 'ราคา(ไม่รวมVat)', label: 'ราคา(ไม่รวมVat)', alignRight: false },
  { id: 'บริษัท', label: 'บริษัท', alignRight: false },
  { id: 'พื้นที่', label: 'พื้นที่', alignRight: false },
  { id: 'เลขที่ใบแจ้งหนี้', label: 'เลขที่ใบแจ้งหนี้', alignRight: false }
];

// ----------------------------------------------------------------------

export function Exanple(props) {
  const [plantList, setPlantList] = useState(null);
  const [plantNoFrom, setPlantNoFrom] = useState([]);
  const [plantNoTo, setPlantNoTo] = useState('%');

  const [pantryList, setPantryList] = useState(null);
  const [pantryNoFrom, setPantryNoFrom] = useState([]);
  const [pantryNoTo, setPantryNoTo] = useState('%');

  const [productList, setProductList] = useState(null);
  const [productNoFrom, setProductNoFrom] = useState('%');
  const [productNoTo, setProductNoTo] = useState('%');

  const [dateFrom, setDateFrom] = useState(new Date());
  const [dateTo, setDateTo] = useState(new Date());

  const [showReport, setShowReport] = useState();

  const onClickPlantAll = () => {
    let value = [];

    plantList.map((list) => {
      console.log(list.plant_no);
      value.push(list.plant_no);
    });

    setPlantNoFrom(value);
  };

  const onClickPlantCLEAR = () => {
    setPlantNoFrom([]);

    console.log('clear');
  };

  const onClickPantryAll = () => {
    let value = [];

    pantryList.map((list) => {
      console.log(list.pantry_no);
      value.push(list.pantry_no);
    });

    setPantryNoFrom(value);
  };

  const onClickPantryCLEAR = () => {
    setPantryNoFrom([]);
  };

  const handlePlantNoFrom = (event) => {
    const {
      target: { value }
    } = event;

    typeof value === 'string' ? value.split(',') : value;

    value.forEach(function (item, index, object) {
      if (plantList.findIndex((pl) => pl.plant_no === item) == -1) {
        object.splice(index, 1);
        // console.log(item);
      }
    });

    setPlantNoFrom(value);

    if (value.length > 1) setPantryNoFrom([]);

    // setPlantNoFrom(event.target.value);
    // setPlantNoTo(event.target.value);
  };

  const handlePlantNoTo = (event) => {
    setPlantNoTo(event.target.value);
  };

  const handleProductNoFrom = (event) => {
    setProductNoFrom(event.target.value);
    setProductNoTo(event.target.value);
  };

  const handleProductNoTo = (event) => {
    setProductNoTo(event.target.value);
  };

  const handleDateFrom = (newValue) => {
    setDateFrom(newValue.toDate());
    setDateTo(newValue.toDate());
  };

  const handleDateTo = (newValue) => {
    setDateTo(newValue.toDate());
  };

  const handlePantryNoFrom = (event) => {
    const {
      target: { value }
    } = event;

    // setPantryNo(
    //   // On autofill we get a stringified value.
    //   typeof value === 'string' ? value.split(',') : value
    // );

    typeof value === 'string' ? value.split(',') : value;

    value.forEach(function (item, index, object) {
      if (pantryList.findIndex((pl) => pl.pantry_no === item) == -1) {
        object.splice(index, 1);
        // console.log(item);
      }
    });

    setPantryNoFrom(value);

    // setPantryNoFrom(event.target.value);
    // setPantryNoTo(event.target.value);
  };

  const handlePantryNoTo = (event) => {
    setPantryNoTo(event.target.value);
  };

  useEffect(() => {
    props.getPlants();
  }, []);

  useEffect(() => {
    const { result } = props.plantReducer;
    if (result) setPlantList(result);
  }, [props.plantReducer]);

  useEffect(() => {
    // setPantryNoFrom('%');
    // setPantryNoTo('%');
    // if (plantNoFrom == plantNoTo) {
    props.getPantryByPlantNo(plantNoFrom);
    // } else {
    //   props.getPantryByPlantNo('-xxx-');
    // }
  }, [plantNoFrom, plantNoTo]);

  useEffect(() => {
    const { result } = props.pantryReducer;
    if (result) setPantryList(result);
  }, [props.pantryReducer]);

  useEffect(() => {
    props.getProducts();
  }, []);

  useEffect(() => {
    const { result } = props.productReducer;
    if (result) setProductList(result);
  }, [props.productReducer]);

  const downloadFile = () => {
    let plantNoArray = '';
    plantNoFrom.map((plant_no) => {
      plantNoArray = plantNoArray + (plantNoArray == '' ? '' : ',') + '%27' + plant_no + '%27';
    });
    if (plantNoArray == '') plantNoArray = '%27' + 'ALL' + '%27';
    // console.log(plantNoArray);

    let pantryNoArray = '';
    pantryNoFrom.map((pantry_no) => {
      pantryNoArray = pantryNoArray + (pantryNoArray == '' ? '' : ',') + '%27' + pantry_no + '%27';
    });
    if (pantryNoArray == '') pantryNoArray = '%27' + 'ALL' + '%27';
    // console.log(pantryNoArray);

    window.location.href = `${apiUrl}/report/order/report_order.xlsx?pDateFrom=${dateFrom
      .toJSON()
      .slice(0, 10)}&pDateTo=${dateTo
      .toJSON()
      .slice(
        0,
        10
      )}&pProductFrom=${productNoFrom}&pProductTo=${productNoTo}&pPlantFrom=${plantNoArray}&pPlantTo=${plantNoTo}&pPantryFrom=${pantryNoArray}&pPantryTo=${pantryNoTo}`;
  };

  return (
    <Page title="Report Order | JConnect">
      <Container maxWidth="false">
        {/* <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}> */}
        <Grid container>
          <Grid item lg={4} md={4} xs={6}>
            <Typography variant="h4" gutterBottom>
              รายงานสั่งสินค้า
            </Typography>
          </Grid>
          <Grid item lg={4} md={4} xs={0}></Grid>
          <Grid item container lg={4} md={4} xs={6} spacing={1}>
            <Grid item lg={6} md={6} xs={12}>
              <Button
                variant="contained"
                startIcon={<Icon icon={microsoftExcel} width={24} height={24} />}
                sx={{ width: '100%' }}
                onClick={downloadFile}
              >
                Export รายงานสั่งสินค้า
              </Button>
            </Grid>
            <Grid item lg={6} md={6} xs={12}>
              <Button
                variant="contained"
                startIcon={<Icon icon={printerfill} width={24} height={24} />}
                sx={{ width: '100%' }}
                onClick={() => {
                  if (
                    dateFrom &&
                    dateTo &&
                    productNoFrom &&
                    productNoTo &&
                    plantNoFrom &&
                    plantNoTo &&
                    pantryNoFrom &&
                    pantryNoTo
                  ) {
                    let plantNoArray = '';
                    plantNoFrom.map((plant_no) => {
                      plantNoArray =
                        plantNoArray + (plantNoArray == '' ? '' : ',') + '%27' + plant_no + '%27';
                    });
                    if (plantNoArray == '') plantNoArray = '%27' + 'ALL' + '%27';
                    // console.log(plantNoArray);

                    let pantryNoArray = '';
                    pantryNoFrom.map((pantry_no) => {
                      pantryNoArray =
                        pantryNoArray +
                        (pantryNoArray == '' ? '' : ',') +
                        '%27' +
                        pantry_no +
                        '%27';
                    });
                    if (pantryNoArray == '') pantryNoArray = '%27' + 'ALL' + '%27';

                    // setShowReport(
                    const report = `${apiUrl}/report/order?pDateFrom=${dateFrom
                      .toJSON()
                      .slice(0, 10)}&pDateTo=${dateTo
                      .toJSON()
                      .slice(
                        0,
                        10
                      )}&pProductFrom=${productNoFrom}&pProductTo=${productNoTo}&pPlantFrom=${plantNoArray}&pPlantTo=${plantNoTo}&pPantryFrom=${pantryNoArray}&pPantryTo=${pantryNoTo}`;

                    // `${apiUrl}/report/billing?pMonth=${2}&pYear=${2022}&pGroup=${'PTT'}`
                    // );

                    const pdfWindow = window.open('Order');
                    const title = 'PDF';
                    const URI = 'report/Order';
                    const html = `
                    <html>
                      <head>
                        <title>${title}</title>
                      </head>
                      <body>
                        <iframe src="${report}" width="100%" height="99.70%">
                      </body>
                    </html>
                  `;
                    pdfWindow.document.write(html);
                    pdfWindow.document.close();
                    pdfWindow.history.pushState(null, null, URI);
                  }
                }}
              >
                พิมพ์รายงานสั่งสินค้า
              </Button>
            </Grid>
          </Grid>
        </Grid>
        {/* </Stack> */}

        <ExampleToolbar
          plantNoFrom={plantNoFrom}
          onPlantNoFrom={handlePlantNoFrom}
          plantNoTo={plantNoTo}
          onPlantNoTo={handlePlantNoTo}
          plantList={plantList}
          productNoFrom={productNoFrom}
          onProductNoFrom={handleProductNoFrom}
          productNoTo={productNoTo}
          onProductNoTo={handleProductNoTo}
          productList={productList}
          dateFrom={dateFrom}
          onDateFrom={handleDateFrom}
          dateTo={dateTo}
          onDateTo={handleDateTo}
          pantryNoFrom={pantryNoFrom}
          onPantryNoFrom={handlePantryNoFrom}
          pantryNoTo={pantryNoTo}
          onPantryNoTo={handlePantryNoTo}
          pantryList={pantryList}
          onClickPlantAll={onClickPlantAll}
          onClickPlantCLEAR={onClickPlantCLEAR}
          onClickPantryAll={onClickPantryAll}
          onClickPantryCLEAR={onClickPantryCLEAR}
        />

        {/* {showReport && (
          <Card sx={{ mt: 2, height: '1200px' }}>
            <iframe width="100%" height="100%" frameBorder="0" src={showReport}></iframe>
          </Card>
        )} */}
      </Container>
    </Page>
  );
}

const mapStateToProps = ({ pantryReducer, plantReducer, productReducer }) => ({
  pantryReducer,
  plantReducer,
  productReducer
});

const mapDispatchToProps = {
  ...PlantAction,
  ...PantryAction,
  ...ProductAction
};

export default connect(mapStateToProps, mapDispatchToProps)(Exanple);
