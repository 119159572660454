/* eslint-disable */
import { filter } from 'lodash';
import { Icon } from '@iconify/react';
import { useState, useEffect } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// material
import {
  Card,
  Table,
  Stack,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination
} from '@mui/material';

import { connect } from 'react-redux';
import * as actions from '../actions/quotation.action';
import * as PlantAction from '../actions/plant.action';
import * as PantryAction from '../actions/pantry.action';
// components
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import {
  QuotationListHead,
  QuotationListToolbar,
  QuotationMoreMenu
} from '../components/_dashboard/quotation';
//
// import OrderList from '../_mocks_/order';
import { fDate, fDateTime } from '../utils/formatTime';

import { useQuotation } from '../hooks/useQuotation';

import { server } from '../constants';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'plant_no', label: 'พื้นที่', alignRight: false },
  { id: 'pantry_no', label: 'ตึก', alignRight: false },
  { id: 'quotation_date', label: 'วันที่จัดทำใบเสนอราคา', alignRight: false },
  { id: 'quotation_purchase', label: 'ผู้จัดทำใบเสนอราคา', alignRight: false },
  // { id: 'status', label: 'สถานะ', alignRight: false },
  { id: '' }
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query, plant, pantry) {
  if (Array.isArray(array)) {
    let dataFilter = [...array];

    if (query || plant || pantry) {
      dataFilter = filter(
        dataFilter,
        (_quotation) =>
          _quotation.quotation_number.toLowerCase().indexOf(query.toLowerCase()) !== -1 &&
          (_quotation.plant_no.toLowerCase() == plant.toLowerCase() ||
            plant == '' ||
            plant == '%') &&
          (_quotation.pantry_no.toLowerCase() == pantry.toLowerCase() ||
            pantry == '' ||
            pantry == '%')
      );
    }

    const stabilizedThis = dataFilter.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });

    return stabilizedThis.map((el) => el[0]);
  } else return [];
}

export function Quotation(props) {
  const pageDatas = useQuotation();
  const navigate = useNavigate();
  const [plantList, setPlantList] = useState(null);
  const [pantryList, setPantryList] = useState([]);
  const [quotationList, setQuotationList] = useState([]);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [plantfilterName, setPlantFilterName] = useState('');
  const [pantryfilterName, setPantryFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(
    parseInt(localStorage.getItem(server.ROWS_PER_PAGE))
  );

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = filteredUsers.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    localStorage.setItem(server.ROWS_PER_PAGE, event.target.value);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const handleplantFilterByName = (event) => {
    setPlantFilterName(event.target.value);
    setPantryFilterName('');
  };

  const handlepantryFilterByName = (event) => {
    setPantryFilterName(event.target.value);
  };

  useEffect(() => {
    //fetchData();
    props.getPlants();
  }, []);

  // async function fetchData() {
  //   await props.getOrders();
  // }

  useEffect(() => {
    props.getPantryByPlantNo(plantfilterName);
  }, [plantfilterName]);

  useEffect(() => {
    const { result } = props.pantryReducer;
    if (result && plantfilterName != '') setPantryList(result);
  }, [props.pantryReducer]);

  useEffect(() => {
    const { result } = props.plantReducer;
    // console.log(result);
    if (result) setPlantList(result);
  }, [props.plantReducer]);

  useEffect(() => {
    //fetchData();
    props.getQuotations();
  }, []);

  useEffect(() => {
    const { result } = props.quotationReducer;

    if (result) setQuotationList(result);
  }, [props.quotationReducer]);

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - quotationList.length) : 0;

  const filteredUsers =
    quotationList != null
      ? applySortFilter(
          quotationList,
          getComparator(order, orderBy),
          filterName,
          plantfilterName,
          pantryfilterName
        )
      : [];

  const isUserNotFound = filteredUsers.length === 0;

  return (
    <Page title="Quotation | JConnect">
      <Container maxWidth="false">
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            ใบเสนอราคา
          </Typography>
          <Button
            variant="contained"
            // component={RouterLink}
            // to="/dashboard/quotationproduct"
            startIcon={<Icon icon={plusFill} />}
            onClick={() => {
              pageDatas.setIsState('A');
              navigate('/dashboard/quotationproduct', { replace: true });
            }}
          >
            เพิ่มใบเสนอราคา
          </Button>
        </Stack>

        <Card>
          {quotationList && plantList && pantryList && (
            <>
              <QuotationListToolbar
                numSelected={selected.length}
                filterName={filterName}
                onFilterName={handleFilterByName}
                plantfilterName={plantfilterName}
                plantonFilterName={handleplantFilterByName}
                pantryfilterName={pantryfilterName}
                pantryonFilterName={handlepantryFilterByName}
                plant={plantList}
                pantry={pantryList}
              />

              <Scrollbar>
                <TableContainer sx={{ minWidth: 800 }}>
                  <Table>
                    <QuotationListHead
                      order={order}
                      orderBy={orderBy}
                      headLabel={TABLE_HEAD}
                      rowCount={filteredUsers.length}
                      numSelected={selected.length}
                      onRequestSort={handleRequestSort}
                      onSelectAllClick={handleSelectAllClick}
                    />
                    <TableBody>
                      {filteredUsers
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row) => {
                          const { id, plant_no, pantry_no, quotation_date, quotation_purchase } =
                            row;
                          const isItemSelected = selected.indexOf(id) !== -1;

                          return (
                            <TableRow
                              hover
                              key={id}
                              tabIndex={-1}
                              role="checkbox"
                              selected={isItemSelected}
                              aria-checked={isItemSelected}
                            >
                              {/* <TableCell padding="checkbox">
                                <Checkbox
                                  checked={isItemSelected}
                                  onChange={(event) => handleClick(event, id)}
                                />
                              </TableCell> */}
                              <TableCell align="left">{plant_no}</TableCell>
                              <TableCell align="left">{pantry_no}</TableCell>
                              <TableCell align="left">{fDate(quotation_date)}</TableCell>
                              <TableCell align="left">{quotation_purchase}</TableCell>
                              {/* <TableCell align="left">
                                {
                                  orderStatus[orderStatus.findIndex((obj) => obj.value == status)]
                                    .label
                                }
                              </TableCell> */}

                              <TableCell align="right">
                                <QuotationMoreMenu props={props} id={id} />
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>
                    {isUserNotFound && (
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                            <SearchNotFound searchQuery={filterName} />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
              </Scrollbar>

              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={filteredUsers.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </>
          )}
        </Card>
      </Container>
    </Page>
  );
}

const mapStateToProps = ({ quotationReducer, pantryReducer, plantReducer }) => ({
  quotationReducer,
  pantryReducer,
  plantReducer
});

const mapDispatchToProps = {
  ...actions,
  ...PlantAction,
  ...PantryAction
};

export default connect(mapStateToProps, mapDispatchToProps)(Quotation);
