/* eslint-disable */
import * as React from 'react';
import { useEffect, useState } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { filter } from 'lodash';
import { Icon } from '@iconify/react';
import searchFill from '@iconify/icons-eva/search-fill';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';

import {
  Box,
  Grid,
  Card,
  Button,
  Divider,
  Container,
  Typography,
  Stack,
  OutlinedInput,
  FormControl,
  TextField
} from '@mui/material';
import TextareaAutosize from '@mui/base/TextareaAutosize';

import { connect } from 'react-redux';
import * as actions from '../actions/newpost.action';

import Page from '../components/Page';
import Swal from 'sweetalert2';
import { isObject } from 'lodash';
import { imageUrlPost } from '../constants';

// ----------------------------------------------------------------------

export function Post(props) {
  const navigate = useNavigate();
  const [imageShow, setImageShow] = useState(null);
  const [newpostimage, setNewpostimage] = React.useState('');
  const [headername, setHeadername] = React.useState('');
  const [message, setMessage] = React.useState('');

  const { type, id } = useParams();

  if (id) {
    // alert(id);

    useEffect(() => {
      props.getNewpostById(id);
    }, []);

    useEffect(() => {
      const { result } = props.newpostReducer;

      if (result) {
        setNewpostimage(result.newpost_image);
        setHeadername(result.header_name);
        setMessage(result.message);
      }
    }, [props.newpostReducer]);
  }

  useEffect(() => {
    if (newpostimage) {
      if (isObject(newpostimage)) {
        setImageShow(URL.createObjectURL(newpostimage));
      } else {
        setImageShow(`${imageUrlPost}/${newpostimage}`);
      }
    }
  }, [newpostimage]);

  const handleChangeNewpostimage = (event) => {
    setNewpostimage(event.target.value);
  };

  const handleChangeHeadername = (event) => {
    setHeadername(event.target.value);
  };

  const handleChangeMessage = (event) => {
    setMessage(event.target.value);
  };

  return (
    <Page title="Post | JConnect">
      <Container>
        <Card>
          <Grid container spacing={2} sx={{ p: 3 }}>
            <Grid item container spacing={2} lg={12} md={12} xs={12}>
              <Grid item lg={12} md={12} xs={12}>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  sx={{ mb: 1 }}
                >
                  <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                    เพิ่มข่าวสาร
                  </Typography>
                </Stack>
              </Grid>
              <Grid item lg={12} md={12} xs={12}>
                <Divider />
              </Grid>
            </Grid>

            <Grid item container spacing={2} lg={12} md={12} xs={12}>
              <Grid item lg={12} md={12} xs={12}>
                <TextField
                  id="header_name"
                  label="ชื่อเรื่อง"
                  sx={{ width: '100%' }}
                  value={headername}
                  onChange={handleChangeHeadername}
                />
              </Grid>

              <Grid item lg={12} md={12} xs={12}>
                <TextField
                  id="message"
                  label="ข้อความ"
                  multiline
                  rows={4}
                  sx={{ width: '100%' }}
                  value={message}
                  onChange={handleChangeMessage}
                />
              </Grid>

              <Grid item lg={12} md={12} xs={12}>
                {imageShow && newpostimage && (
                  <>
                    <img src={imageShow} alt={newpostimage.name} />
                  </>
                )}
                <input
                  accept="image/*"
                  type="file"
                  id="select-image"
                  style={{ display: 'none' }}
                  onChange={(e) => setNewpostimage(e.target.files[0])}
                />

                <Button
                  htmlFor="select-image"
                  variant="contained"
                  component="label"
                  startIcon={<AddAPhotoIcon />}
                  // value={productImage}
                  // onChange={handleChangeProductImage}
                >
                  เพิ่มรูปภาพ
                </Button>
              </Grid>
            </Grid>

            <Grid
              item
              container
              spacing={2}
              lg={12}
              md={12}
              xs={12}
              sx={{
                display: 'flex',
                flexDirection: 'row-reverse'
              }}
            >
              <Button
                variant="contained"
                component={RouterLink}
                to="/dashboard/newpost/edit"
                sx={{ mt: 2 }}
              >
                ยกเลิก
              </Button>
              <Button
                variant="contained"
                sx={{ mr: 2, mt: 2 }}
                onClick={() => {
                  let data = new FormData();
                  data.append('newpost_image', newpostimage);
                  data.append('header_name', headername);
                  data.append('message', message);

                  if (id) {
                    data.append('id', id);
                    props.putNewposts(navigate, data);
                  } else {
                    props.postNewposts(navigate, data);
                  }

                  // const postplant = props.postPlants(navigate, data);
                  // postplant(dispatch);

                  Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: 'Save',
                    showConfirmButton: false,
                    timer: 1500
                  });
                }}
              >
                บันทึก
              </Button>
            </Grid>
          </Grid>
        </Card>
      </Container>
    </Page>
  );
}

const mapStateToProps = ({ newpostReducer }) => ({ newpostReducer });

const mapDispatchToProps = {
  ...actions
};

export default connect(mapStateToProps, mapDispatchToProps)(Post);
