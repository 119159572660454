/* eslint-disable */
import { filter } from 'lodash';
import { Icon } from '@iconify/react';
import { useState, useEffect, useCallback } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink, Navigate } from 'react-router-dom';
// material
import {
  Card,
  Table,
  Stack,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination
} from '@mui/material';

import { connect } from 'react-redux';
import * as actions from '../actions/plant.action';

// components
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import { PlantListToolbar, PlantMoreMenu, PlantListHead } from '../components/_dashboard/plant';
//
import { orderStatus, server } from '../constants';
// import PlantList from '../_mocks_/plant';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'plant_no', label: 'พื้นที่', alignRight: false },
  { id: 'plant_details', label: 'รายละเอียด', alignRight: false },
  { id: 'status', label: 'สถานะ', alignRight: false },
  { id: '' }
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  if (Array.isArray(array)) {
    let dataFilter = [...array];

    if (query) {
      dataFilter = filter(
        dataFilter,
        (_plant) => _plant.plant_details.toLowerCase().indexOf(query.toLowerCase()) !== -1
      );
    }

    const stabilizedThis = dataFilter.map((el, index) => [el, index]);

    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });

    return stabilizedThis.map((el) => el[0]);
  } else return [];
}

export function Plant(props) {
  const [plantList, setPlantList] = useState([]);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(
    parseInt(localStorage.getItem(server.ROWS_PER_PAGE))
  );

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = filteredUsers.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    localStorage.setItem(server.ROWS_PER_PAGE, event.target.value);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  // const fetchData = useCallback(async () => {
  //   await props.getPlants();
  //   let result = await props.plantReducer;
  //   setPlantList(result);
  // }, []);

  // useEffect(() => {
  //   fetchData();
  // }, [fetchData]);

  async function fetchData() {
    await props.getPlants();
    // const { result } = await props.plantReducer; // isFetching
    // console.log('result 1 >>', result);
    // setPlantList(result);
  }

  useEffect(() => {
    //fetchData();
    props.getPlants();
  }, []);

  useEffect(() => {
    //console.log(plantList);
    //console.log('PlantList 1 >>', plantList);
    //console.log('props.plantReducer', props.plantReducer)
    const { result } = props.plantReducer;

    if (result) setPlantList(result);
  }, [props.plantReducer]);

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - plantList.length) : 0;

  const filteredUsers =
    plantList != null ? applySortFilter(plantList, getComparator(order, orderBy), filterName) : [];

  const isUserNotFound = filteredUsers.length === 0;

  return (
    <Page title="Plant | JConnect">
      <Container maxWidth="false">
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            กำหนดพื้นที่
          </Typography>
          <Button
            variant="contained"
            component={RouterLink}
            to="/dashboard/plantproduct"
            startIcon={<Icon icon={plusFill} />}
          >
            เพิ่มพื้นที่
          </Button>
        </Stack>

        <Card>
          {/* {plantList &&
            plantList.map((itm) => (
              <Button
                variant="contained"
                component={RouterLink}
                to="/dashboard/plantproduct"
                startIcon={<Icon icon={plusFill} />}
              >
                {itm.plant_details}
              </Button>
            ))} */}
          {plantList && (
            <>
              <PlantListToolbar
                numSelected={selected.length}
                filterName={filterName}
                onFilterName={handleFilterByName}
              />

              <Scrollbar>
                <TableContainer sx={{ minWidth: 450 }}>
                  <Table>
                    <PlantListHead
                      order={order}
                      orderBy={orderBy}
                      headLabel={TABLE_HEAD}
                      rowCount={filteredUsers.length}
                      numSelected={selected.length}
                      onRequestSort={handleRequestSort}
                      onSelectAllClick={handleSelectAllClick}
                    />
                    <TableBody>
                      {filteredUsers
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row) => {
                          const { id, plant_no, plant_details, status } = row;
                          const isItemSelected = selected.indexOf(id) !== -1;

                          return (
                            <TableRow
                              hover
                              key={id}
                              tabIndex={-1}
                              role="checkbox"
                              selected={isItemSelected}
                              aria-checked={isItemSelected}
                            >
                              <TableCell padding="checkbox">
                                <Checkbox
                                  checked={isItemSelected}
                                  onChange={(event) => handleClick(event, id)}
                                />
                              </TableCell>
                              <TableCell align="left">{plant_no}</TableCell>
                              <TableCell align="left">{plant_details}</TableCell>
                              <TableCell align="left">
                                {status == 'A' ? 'ใช้งาน' : 'ไม่ใช้งาน'}
                              </TableCell>

                              <TableCell align="right">
                                <PlantMoreMenu props={props} id={id} />
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>
                    {isUserNotFound && (
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                            <SearchNotFound searchQuery={filterName} />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
              </Scrollbar>

              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={filteredUsers.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </>
          )}
        </Card>
      </Container>
    </Page>
  );
}

const mapStateToProps = ({ plantReducer }) => ({ plantReducer });

const mapDispatchToProps = {
  ...actions
};

export default connect(mapStateToProps, mapDispatchToProps)(Plant);
