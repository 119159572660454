/* eslint-disable */
import {
  HTTP_NEWPOST_SUCCESS,
  HTTP_NEWPOST_FETCHING,
  HTTP_NEWPOST_FAILED,
  server,
  OK,
  YES
} from '../constants';
import { httpClient } from '../utils/HttpClient';

const setStateNewpostToSuccess = (payload) => ({
  type: HTTP_NEWPOST_SUCCESS,
  payload
});

const setStateNewpostToFetching = () => ({
  type: HTTP_NEWPOST_FETCHING
});

const setStateNewpostToFailed = () => ({
  type: HTTP_NEWPOST_FAILED
});

export const addNewpost = (navigate, formData) => {
  return async (dispatch) => {
    await httpClient.post(server.NEWPOST_URL, formData);
    navigate('/dashboard/newpost');
  };
};

export const getNewposts = () => {
  return async (dispatch) => {
    dispatch(setStateNewpostToFetching());
    await doGetNewposts(dispatch);
  };
};

const doGetNewposts = async (dispatch) => {
  try {
    let result = await httpClient.get(server.NEWPOST_URL);
    //console.log(' *** doGetPlants >> ', result);
    dispatch(setStateNewpostToSuccess(result.data));
  } catch (error) {
    alert(JSON.stringify(error));
    dispatch(setStateNewpostToFailed());
  }
};

export const deleteNewposts = (id) => {
  return async (dispatch) => {
    dispatch(setStateNewpostToFetching());
    await httpClient.delete(`${server.NEWPOST_URL}/${id}`);
    await doGetNewposts(dispatch);
  };
};

export const getNewpostById = (id) => {
  return (dispatch) => {
    // dispatch(finishInitialization(false))
    dispatch(setStateNewpostToFetching());
    httpClient
      .get(`${server.NEWPOST_URL}/${id}`)
      .then((result) => {
        dispatch(setStateNewpostToSuccess(result.data));
      })
      .catch((err) => {
        console.log(err);
        dispatch(setStateNewpostToFailed());
      });
  };
};

export const postNewposts = (navigate, data) => {
  return async (dispatch) => {
    dispatch(setStateNewpostToFetching());
    let result = await httpClient.post(server.NEWPOST_URL, data);
    //console.log(result);

    if (result.data.result == OK) {
      navigate('/dashboard/newpost/edit', { replace: true });
      dispatch(setStateNewpostToSuccess(result));
    } else {
      dispatch(setStateNewpostToFailed());
    }
  };
};

export const putNewposts = (navigate, data) => {
  return async (dispatch) => {
    dispatch(setStateNewpostToFetching());
    let result = await httpClient.put(server.NEWPOST_URL, data);
    if (result.data.result == OK) {
      navigate('/dashboard/newpost/edit', { replace: true });
      dispatch(setStateNewpostToSuccess(result));
    } else {
      dispatch(setStateNewpostToFailed());
    }
  };
};
