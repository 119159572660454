/* eslint-disable */
import {
  HTTP_PRODUCT_SUCCESS,
  HTTP_PRODUCT_FETCHING,
  HTTP_PRODUCT_FAILED,
  server,
  OK,
  YES
} from '../constants';
import { httpClient } from '../utils/HttpClient';

const setStateProductToSuccess = (payload) => ({
  type: HTTP_PRODUCT_SUCCESS,
  payload
});

const setStateProductToFetching = () => ({
  type: HTTP_PRODUCT_FETCHING
});

const setStateProductToFailed = () => ({
  type: HTTP_PRODUCT_FAILED
});

export const addProduct = (navigate, formData) => {
  return async (dispatch) => {
    await httpClient.post(server.PRODUCT_URL, formData);
    navigate('/dashboard/product');
  };
};

export const getProducts = () => {
  return async (dispatch) => {
    dispatch(setStateProductToFetching());
    await doGetProducts(dispatch);
  };
};

const doGetProducts = async (dispatch) => {
  try {
    let result = await httpClient.get(server.PRODUCT_URL);
    //console.log(' *** doGetPlants >> ', result);
    dispatch(setStateProductToSuccess(result.data));
  } catch (error) {
    alert(JSON.stringify(error));
    dispatch(setStateProductToFailed());
  }
};

export const deleteProducts = (id) => {
  return async (dispatch) => {
    dispatch(setStateProductToFetching());
    await httpClient.delete(`${server.PRODUCT_URL}/${id}`);
    await doGetProducts(dispatch);
  };
};

export const getProductById = (id) => {
  return (dispatch) => {
    // dispatch(finishInitialization(false))
    dispatch(setStateProductToFetching());
    httpClient
      .get(`${server.PRODUCT_URL}/${id}`)
      .then((result) => {
        dispatch(setStateProductToSuccess(result.data));
      })
      .catch((err) => {
        console.log(err);
        dispatch(setStateProductToFailed());
      });
  };
};

export const postProducts = (navigate, data) => {
  return async (dispatch) => {
    dispatch(setStateProductToFetching());
    let result = await httpClient.post(server.PRODUCT_URL, data);
    //console.log(result);

    if (result.data.result == OK) {
      navigate('/dashboard/product/edit', { replace: true });
      dispatch(setStateProductToSuccess(result));
    } else {
      dispatch(setStateProductToFailed());
    }
  };
};

export const putProducts = (navigate, data) => {
  return async (dispatch) => {
    dispatch(setStateProductToFetching());
    let result = await httpClient.put(server.PRODUCT_URL, data);
    if (result.data.result == OK) {
      navigate('/dashboard/product/edit', { replace: true });
      dispatch(setStateProductToSuccess(result));
    } else {
      dispatch(setStateProductToFailed());
    }
  };
};
