/* eslint-disable */
import {
  HTTP_DELIVER_FETCHING,
  HTTP_DELIVER_SUCCESS,
  HTTP_DELIVER_FAILED,
  HTTP_DELIVERDT_FETCHING,
  HTTP_DELIVERDT_SUCCESS,
  HTTP_DELIVERDT_FAILED,
  server,
  OK,
  YES
} from '../constants';
import { httpClient } from '../utils/HttpClient';

const setStateDeliverToSuccess = (payload) => ({
  type: HTTP_DELIVER_SUCCESS,
  payload
});

const setStateDeliverToFetching = () => ({
  type: HTTP_DELIVER_FETCHING
});

const setStateDeliverToFailed = () => ({
  type: HTTP_DELIVER_FAILED
});

const setStateDeliverDTToSuccess = (payload) => ({
  type: HTTP_DELIVERDT_SUCCESS,
  payload
});

const setStateDeliverDTToFetching = () => ({
  type: HTTP_DELIVERDT_FETCHING
});

const setStateDeliverDTToFailed = () => ({
  type: HTTP_DELIVERDT_FAILED
});

export const getDelivers = () => {
  return async (dispatch) => {
    dispatch(setStateDeliverToFetching());
    await doGetDelivers(dispatch);
  };
};

const doGetDelivers = async (dispatch) => {
  try {
    let result = await httpClient.get(server.DELIVER_URL);
    //console.log(' *** doGetPlants >> ', result);
    dispatch(setStateDeliverToSuccess(result.data));
  } catch (error) {
    alert(JSON.stringify(error));
    dispatch(setStateDeliverToFailed());
  }
};

export const deleteDelivers = (id) => {
  return async (dispatch) => {
    dispatch(setStateDeliverToFetching());
    await httpClient.delete(`${server.DELIVER_URL}/${id}`);
    await doGetDelivers(dispatch);
  };
};

export const getDeliverById = (id) => {
  return (dispatch) => {
    // dispatch(finishInitialization(false))
    dispatch(setStateDeliverToFetching());
    httpClient
      .get(`${server.DELIVER_URL}/${id}`)
      .then((result) => {
        dispatch(setStateDeliverToSuccess(result.data));
      })
      .catch((err) => {
        console.log(err);
        dispatch(setStateDeliverToFailed());
      });
  };
};

export const getDeliverDTById = (id) => {
  return (dispatch) => {
    // dispatch(finishInitialization(false))
    dispatch(setStateDeliverDTToFetching());
    httpClient
      .get(`${server.DELIVERDT_URL}/${id}`)
      .then((result) => {
        dispatch(setStateDeliverDTToSuccess(result.data));
      })
      .catch((err) => {
        console.log(err);
        dispatch(setStateDeliverDTToFailed());
      });
  };
};

export const postDelivers = (navigate, data, datadt) => {
  return async (dispatch) => {
    dispatch(setStateDeliverToFetching());
    let result = await httpClient.post(server.DELIVER_URL, data);
    // console.log(result);

    const id = JSON.parse(result.data.message).id;

    result = await httpClient.post(`${server.DELIVERDT_URL}/${id}`, datadt);

    if (result.data.result == OK) {
      navigate('/dashboard/deliver', { replace: true });
      dispatch(setStateDeliverToSuccess(result));
    } else {
      dispatch(setStateDeliverToFailed());
    }
  };
};

export const putDelivers = (navigate, data, datadt) => {
  return async (dispatch) => {
    dispatch(setStateDeliverToFetching());
    let result = await httpClient.put(server.DELIVER_URL, data);
    const id = data.get('id');

    // console.log(data.get('id'));

    result = await httpClient.post(`${server.DELIVERDT_URL}/${id}`, datadt);
    if (result.data.result == OK) {
      navigate('/dashboard/deliver', { replace: true });
      dispatch(setStateDeliverToSuccess(result));
    } else {
      dispatch(setStateDeliverToFailed());
    }
  };
};

export const putCheckPickups = (navigate, data, datadt) => {
  return async (dispatch) => {
    dispatch(setStateDeliverToFetching());
    let result = await httpClient.put(server.DELIVER_URL, data);

    const id = data.get('id');
    result = await httpClient.post(`${server.DELIVERDT_URL}/${id}`, datadt);
    if (result.data.result == OK) {
      navigate('/dashboard/checkproduct', { replace: true });
      dispatch(setStateDeliverToSuccess(result));
    } else {
      dispatch(setStateDeliverToFailed());
    }
  };
};
